<template>
    <div class="select-tab-wrapper">
        <div class="select-tab">
            <div class="select-tab__input-field">
                <div class="select-tab-chevron">
                    <v-btn
                        v-if="openListItem"
                        icon
                        @click="openListItem = !openListItem"
                    >
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        icon
                        @click="openListItem = !openListItem"
                    >
                        <v-icon>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                </div>
                <div
                    class="select-tab__input"
                    @click.self="openListItem = !openListItem"
                >
                    {{ dataSelectedTab.selectedTitle }}
                    <span
                        v-if="dataSelectedTab.selectedCounter !== null"
                        class="select-tab__counter form-select-tab__counter--base"
                    >
                        {{ dataSelectedTab.selectedCounter }}
                    </span>
                </div>
            </div>
            <Transition name="slide-fade">
                <ul
                    v-if="openListItem"
                    class="select-tab__list"
                >
                    <template v-if="isComponents">
                        <li
                            v-for="(item, index) in items"
                            :key="index"
                            class="select-tab__list-item"
                            :class="{'active-tab-link': value === item.component}"
                            @click="selectItemComponent(item.component, item.total, item.text)"
                        >
                            <span
                                class="select-tab__list-item-text"
                                :class="{'active-tab-link-text': value === item.component}"
                            >
                                {{ item.text }}
                            </span>
                            <span
                                v-if="item.total !== null && item.total > 0"
                                :class="{'active-tab-link-total': value === item.component}"
                                class="select-tab__counter"
                            >
                                {{ item.total }}
                            </span>
                        </li>
                    </template>
                    <template v-else>
                        <li
                            v-for="(item, index) in items"
                            :key="index"
                            class="select-tab__list-item"
                            :class="{'active-tab-link': $route.path === item.path}"
                            @click="selectItem(item.path, item.total, item.text)"
                        >
                            <span
                                class="select-tab__list-item-text"
                                :class="{'active-tab-link-text': $route.path === item.path}"
                            >
                                {{ item.text }}
                            </span>
                            <span
                                v-if="item.total !== null && item.total > 0"
                                :class="{'active-tab-link-total': $route.path === item.path}"
                                class="select-tab__counter"
                            >
                                {{ item.total }}
                            </span>
                        </li>
                    </template>
                </ul>
            </Transition>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SelectTabs',
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        items: { type: Array },
        isComponents: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openListItem: false,
            search: '',
            dataSelectedTab: {
                selectedTitle: 'Details',
                selectedCounter: null
            }
        };
    },
    computed: {
        fullPath() {
            return this.$route.fullPath.replace(/\/$/, '');
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
            } else {
                this.search = value
                this.items.forEach((item) => {
                    if (item.path === this.search) {
                        this.dataSelectedTab.selectedTitle = item.text
                        this.dataSelectedTab.selectedCounter = item.total
                    }
                })
            }
        },
        fullPath() {
            this.initCurrTab()
        }
    },
    created() {
        this.initCurrTab()
    },
    methods: {
        selectItem(value, counter, title) {
            this.dataSelectedTab = {
                selectedCounter: counter,
                selectedTitle: title
            }
            this.$router.push({
                path: value
            })
            this.$emit('input', title)
            this.openListItem = false;
            this.search = value
        },
        selectItemComponent(component, counter, title) {
            this.dataSelectedTab = {
                selectedCounter: counter,
                selectedTitle: title
            }
            this.$emit('input', component)
            this.openListItem = false;
            this.search = component
        },
        initCurrTab() {
            this.search = this.fullPath
            this.items.forEach((item) => {
                if (item.path === this.search) {
                    this.dataSelectedTab.selectedCounter = item.total
                    this.dataSelectedTab.selectedTitle = item.text
                }
            })
        }
    }
};
</script>

<style lang="scss">
.select-tab {
    outline: none;
    box-shadow: inset 0px -1px 0 0 #e7ecf4;
    border-bottom: 2px solid #EFEFEF;
    max-height: 52px!important;
    width: 100%;
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }
    &__input {
        box-sizing: border-box;
        padding-left: 12px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        max-height: 48px;
        height: 48px;
        color: #1976d2;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.0892857143em;
        justify-content: left;
        align-items: center;
        display: flex;
      ;
    }
    &__input-field {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
    }
    &__list {
        position: absolute;
        overflow: hidden;
        background-color: #fff;
        top: 50px;
        right: 0;
        left: 0;
        overscroll-behavior: contain;
        z-index: 2;
        padding-left: 0 !important;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 3px rgba(25, 50, 71, 0.25);
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        .active-tab-link{
            background: rgba(17, 97, 224, 0.1);
        }
        .active-tab-link-text {
            color: #1976d2;
        }
        .active-tab-link-total {
            color: #1976d2;
        }
    }
    &__list-title {
        height: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #111720;
        padding: 35px 35px 35px 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e7ecf4;
        position: relative;
        background-color: white;
    }
    &__counter{
        position: relative;
        font-weight: bold;
        font-style: normal;
        font-size: 14px;
        height: 28px;
        padding: 3px 10px;
        background: rgba(79, 79, 79, 0.1);
        border-radius: 4px;
        margin-left: 10px;
        &--base {
            margin-right: 15px;
            color: #1976d2;
        }
    }
    &__list-item {
        padding: 10px 15px;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        display: flex;
        &:hover {
            background-color: lightgrey;
        }
    }
    &__list-item-text {
        width: 200px;
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        justify-content: center;
        letter-spacing: 0.0892857143em;
    }
}

.select-tab-wrapper{
    display: flex;
    width: 100%;
    position: relative;
    @media screen and (max-width: 992px) {
        display: flex;
    }
}

.select-tab-chevron{
    cursor: pointer;
    border-right: 2px solid #EAEAEA;
    padding-right: 8px;
}

</style>
