<template>
    <viewer
        title="Details"
        container-width="75%"
        @closeSidePanel="closeDetails"
    >
        <template #body>
            <order-details-tabs
                v-if="order"
                class="mt-4 mb-4"
                :order="order"
                @update:counters="updateCounters"
                @uploadMainFiles="updateOrderData"
            />
        </template>
    </viewer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Viewer from '@/components/SidePanels/Viewer'
import OrderDetailsTabs from '@/components/Orders/OrderTable/OrderDetailsTabs.vue'

import {
    ACTION_GET_ORDER
} from '@/store/modules/orders/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('orders');

export default {
    components: {
        Viewer,
        OrderDetailsTabs
    },
    props: {
        order_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            order: null
        }
    },
    async created() {
        await this.orderData()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_ORDER
        }),
        async orderData() {
            this.loading = true
            const data = await this[ACTION_GET_ORDER](
                {
                    orderid: this.order_id
                }
            )
            this.loading = false
            this.order = data
        },
        closeDetails() {
            this.$emit('closeDetails')
        },
        async updateOrderData() {
            const data = await this[ACTION_GET_ORDER](
                {
                    orderid: this.order_id
                }
            )
            this.order = { ...this.order, ...data }
        },
        // Counters
        updateCounters(e) {
            switch (e.type) {
            case 'notes':
                this.order.counters = {
                    ...this.order.counters,
                    notes_count: this.order.counters.notes_count + e.counter
                }
                break;
            case 'message':
                this.order.counters = {
                    ...this.order.counters,
                    messages_count: this.order.counters.messages_count + e.counter
                }
                break;
            case 'tickets':
                this.order.counters = {
                    ...this.order.counters,
                    tickets_writer_count: this.order.counters.tickets_writer_count + e.counter
                }
                break;
            case 'tickets-client':
                this.order.counters = {
                    ...this.order.counters,
                    tickets_client_count: this.order.counters.tickets_client_count + e.counter
                }
                break;
            case 'files_main_count':
                this.order.counters = {
                    ...this.order.counters,
                    files_main_count: this.order.counters.files_main_count + e.counter
                }
                break;
            case 'files_add_count':
                this.order.counters = {
                    ...this.order.counters,
                    files_add_count: this.order.counters.files_add_count + e.counter
                }
                break;

            default:
                break;
            }
        }
    }
}
</script>
