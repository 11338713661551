<template>
    <div class="mt-n5">
        <tickets-list-component
            ref="list"
            :items="getterSupportList"
            :last-page="getterLastPage"
            :order="createTicketPrepend"
            :list-loading="getterSupportLoading"
            @update="updateList"
            @updatePerpage="onUpdatePerpage"
            @bookmark="createBookmark"
            @close="closeTicket"
            @send="onActionChat"
            @update:counters="updateCounters"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import TicketsListComponent from '@/components/shared/TicketsListComponent'

import {
    ACTION_WRITER_CLOSE_TICKET,
    ACTION_WRITER_GET_SUPPORT_LIST,
    ACTION_WRITER_SEND_MESSAGE
} from '@/store/modules/support/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import { eventBus } from '@/helpers/event-bus/'

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('support');

export default {
    components: {
        TicketsListComponent
    },
    props: {
        createTicketPrepend: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterSupportList',
            'getterLastPage',
            'getterSupportLoading'
        ])
    },
    async activated() {
        await this[ACTION_WRITER_GET_SUPPORT_LIST]({
            search_by: 'respondent_id', search_for: this.$route.params.id, page: this.page, per_page: 10
        })
    },
    methods: {
        ...mapAdminActions({
            ACTION_WRITER_GET_SUPPORT_LIST,
            ACTION_WRITER_SEND_MESSAGE,
            ACTION_WRITER_CLOSE_TICKET
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        async updateList({ page, per_page }) {
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'respondent_id', search_for: this.$route.params.id, page, per_page
            })
        },
        async onUpdatePerpage({ page, per_page }) {
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'respondent_id', search_for: this.$route.params.id, page, per_page
            })
        },
        // TICKETS LOGIC
        async createBookmark(payload) {
            try {
                await this[ACTION_CREATE_BOOKMARK](payload)
                eventBus.$emit('showSnackBar', 'The ticket(s) is successfully added to bookmarks.', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async closeTicket(payload) {
            try {
                await this[ACTION_WRITER_CLOSE_TICKET](payload)
                this.$refs.list.onCloseModal()
                eventBus.$emit('showSnackBar', 'success', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onActionChat(payload) {
            try {
                await this[ACTION_WRITER_SEND_MESSAGE](payload)
                eventBus.$emit('showSnackBar', 'Message is sent', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async updateCounters(e) {
            this.$emit('update:counters', e)
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'respondent_id', search_for: this.$route.params.id, page: this.page, per_page: 10
            })
        }
    }
}
</script>

<style>

</style>
