export const FEEDBACKS_TOOLBAR = [
    {
        name: 'edit_feedback',
        text: 'Edit',
        modal: {
            name: 'ModalFeedbackEdit',
            success_message: 'The feedback is saved'
        },
        icon: 'mdi-pencil'
    },
    {
        name: 'contact_buyer',
        text: 'Contact Buyer',
        modal: {
            name: 'ModalFeedbackCreateTicket',
            success_message: 'The support ticket is created'
        },
        icon: 'mdi-message-outline'
    },
    {
        name: 'set_status',
        text: 'Set a status',
        modal: {
            name: 'ModalFeedbackStatus',
            title: 'Set a status',
            description: 'Select a new status',
            success_message: 'The status has been edited'
        },
        icon: 'mdi-list-status'
    }
]

export const FEEDBACKS_MULTI_TOOLBAR = [
    {
        name: 'set_status',
        text: 'Set a status',
        modal: {
            name: 'ModalFeedbackStatus',
            title: 'Set s status',
            description: 'Select a new status',
            success_message: 'The status has been edited'
        },
        icon: 'mdi-list-status'
    }
]

export const FEEDBACKS_TABLE_HEADER_TOTAL = [
    {
        text: 'Total',
        value: 'total',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    }
]

export const FEEDBACKS_TABLE_HEADER = [
    {
        text: '',
        value: 'index',
        width: '30px',
        align: 'center',
        sortable: false
    },
    {
        text: 'Created',
        value: 'created_at',
        width: '120px',
        sortable: true
    },
    {
        text: 'Order ID',
        value: 'orderid',
        width: '180px',
        sortable: false
    },
    {
        text: 'Average Rate',
        value: 'rate',
        width: '90px',
        sortable: false
    },
    {
        text: 'Feedback',
        value: 'feedback',
        width: '200px',
        sortable: false
    },
    {
        text: 'Domain',
        sortable: false,
        value: 'domain',
        align: 'center',
        width: '90px'
    },
    {
        text: 'Client',
        value: 'client',
        width: '90px',
        sortable: false
    },
    {
        text: 'Writer',
        value: 'writer',
        width: '80px',
        sortable: false
    },
    {
        text: 'Status',
        value: 'status',
        width: '60px',
        sortable: true
    },
    {
        text: '',
        sortable: false,
        width: '100px'
    }
]

export const STATUSES = [
    {
        text: 'Closed',
        value: 'CLOSED'
    },
    {
        text: 'Open',
        value: 'OPEN'
    },
    {
        text: 'Active',
        value: 'ACTIVE'
    }
]
