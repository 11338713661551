<template>
    <Viewer
        :title="title"
        @closeSidePanel="close"
    >
        <template #body>
            <div
                v-if="items"
                class="mt-4"
            >
                <div
                    v-for="(item, key) in items"
                    :key="key"
                >
                    <div class="d-flex align-center mb-4">
                        <div class="bids_avatar">
                            <writer-avatar
                                :id="item.sw_id"
                                :feedbacks="item.fb_reviews"
                                :rating="item.rank"
                                :applied="item.ts_apply"
                                :status="item.trust_status"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                :order_limit="item.projects_limit"
                                :country="item.country"
                            />
                        </div>
                        <div class="ml-5">
                            <b class="text-subtitle-2 font-weight-regular">{{ item.firstname }} {{ item.lastname }}</b> <span v-if="item.nickname">| {{ item.nickname }}</span>
                            <br>
                            <span class="text-subtitle-2 font-weight-regular">ID: {{ item.sw_id }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Viewer>
</template>

<script>
import Viewer from '@/components/SidePanels/Viewer'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'

export default {
    components: {
        Viewer,
        WriterAvatar
    },
    props: {
        title: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    },
    methods: {
        close() {
            this.$emit('closeWriters')
        }
    }
}
</script>

<style lang="scss">
    .writers_modal_sidebar{
        width: 400px;
        position: fixed;
        z-index: 9;
        top: 0;
        bottom: 0;
        right: 0;
    }
    .avatar_rank{
        font-size: 10px;
        position: absolute;
        top: 0px;
        left: 30px;
        background: rgb(39, 174, 96);
        padding: 3px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
    }
</style>
