<template>
    <div class="d-flex align-start flex-wrap flex-grow-1">
        <v-col
            cols="12"
            lg="12"
            class="pt-0"
        >
            <!-- Status -->
            <div class="fb-row">
                <!-- row title -->
                <div class="fb-row_col">
                    <div class="">
                        <span class="font-weight-bold text-uppercase text-subtitle-2">
                            Status
                        </span>
                    </div>
                </div>
                <!-- Status -->
                <div class="fb-row_col">
                    <v-chip
                        v-if="feedback.status"
                        :color="statusColor.color"
                        class="ml-2"
                    >
                        <span :class="`${statusColor.text}--text`">{{ feedback.status }}</span>
                    </v-chip>
                </div>
            </div>
            <div class="extras-wrapper my-2">
                <!-- Communication -->
                <div class="fb-row">
                    <!-- row title -->
                    <div class="fb-row_col">
                        <div class="">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Communication
                            </span>
                        </div>
                    </div>
                    <!-- Communication -->
                    <div class="fb-row_col">
                        <div class="d-flex align-center">
                            <p class="mb-0 text-body-2 ml-2">
                                <marks-chip :rate="+feedback.parsed_testimonial_new.delivery" />
                            </p>
                        </div>
                    </div>
                    <!-- Communication details -->
                    <div class="fb-row_col fb-row_col--auto">
                        <div class="">
                            <p class="grey--text mb-0 text-body-2">
                                Communication details:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ feedback.parsed_testimonial_new.communication_details || '—' }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Content -->
                <div class="fb-row">
                    <!-- row title -->
                    <div class=" fb-row_col">
                        <div class="">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Content
                            </span>
                        </div>
                    </div>
                    <!-- content -->
                    <div class="fb-row_col">
                        <div class="d-flex align-center">
                            <p class="mb-0 text-body-2 ml-2">
                                <marks-chip :rate="+feedback.parsed_testimonial_new.content" />
                            </p>
                        </div>
                    </div>
                    <!-- content details -->
                    <div class="fb-row_col fb-row_col--auto">
                        <div class="">
                            <p class="grey--text mb-0 text-body-2">
                                Content details:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ feedback.parsed_testimonial_new.content_details || '—' }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Delivery -->
                <div class="fb-row">
                    <!-- row title -->
                    <div class=" fb-row_col">
                        <div class="">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Delivery
                            </span>
                        </div>
                    </div>
                    <!-- delivery -->
                    <div class="fb-row_col">
                        <div class="d-flex align-center">
                            <p class="mb-0 text-body-2 ml-2">
                                <marks-chip :rate="+feedback.parsed_testimonial_new.delivery" />
                            </p>
                        </div>
                    </div>
                    <!-- delivery details -->
                    <div class="fb-row_col fb-row_col--auto">
                        <div class="">
                            <p class="grey--text mb-0 text-body-2">
                                Delivery details:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ feedback.parsed_testimonial_new.delivery_details || '—' }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Support -->
                <div class="fb-row">
                    <!-- row title -->
                    <div class=" fb-row_col">
                        <div class="">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Support
                            </span>
                        </div>
                    </div>
                    <!-- support -->
                    <div class="fb-row_col">
                        <div class="d-flex align-center">
                            <p class="mb-0 text-body-2 ml-2">
                                <marks-chip :rate="+feedback.parsed_testimonial_new.support" />
                            </p>
                        </div>
                    </div>
                    <!-- support details -->
                    <div class="fb-row_col fb-row_col--auto">
                        <div class="">
                            <p class="grey--text mb-0 text-body-2">
                                Support details:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ feedback.parsed_testimonial_new.support_details || '—' }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Website -->
                <div class="fb-row">
                    <!-- row title -->
                    <div class=" fb-row_col">
                        <div class="">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Website
                            </span>
                        </div>
                    </div>
                    <!-- website -->
                    <div class="fb-row_col">
                        <div class="d-flex align-center">
                            <p class="mb-0 text-body-2 ml-2">
                                <marks-chip :rate="+feedback.parsed_testimonial_new.website" />
                            </p>
                        </div>
                    </div>
                    <!-- website details -->
                    <div class="fb-row_col fb-row_col--auto">
                        <div class="">
                            <p class="grey--text mb-0 text-body-2">
                                Website details:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ feedback.parsed_testimonial_new.website_details || '—' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="fb-review pa-5">
                {{ feedback.parsed_testimonial_new.details }}
            </p>
            <div class="d-flex grey--text justify-space-between">
                <div>{{ feedback.created_at && $date.humanizeDate(feedback.created_at) }}</div>
                <div>
                    Ref.:
                    <router-link :to="{name: 'order-details', params: {id: feedback.orderid} }">
                        {{ feedback.orderid }}
                    </router-link>
                </div>
            </div>
        </v-col>
    </div>
</template>
<script>
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip.vue'

export default {
    components: {
        MarksChip
    },
    props: {
        feedback: {
            type: Object,
            required: true
        },
        withActions: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            modal: {},
            currentFeedback: {},
            actionName: ''
        }
    },
    computed: {
        statusColor() {
            switch (this.feedback.status) {
            case 'OPEN': {
                return {
                    text: 'primary',
                    color: '#1976D21A'
                }
            }
            case 'ACTIVE': {
                return {
                    text: 'success',
                    color: 'rgba(39, 217, 31, 0.1)'
                }
            }
            case 'CLOSED': {
                return {
                    text: 'error',
                    color: '#FF00001A'
                }
            }
            default: {
                return {}
            }
            }
        }
    },
    methods: {
        rateBgColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'rgba(240, 125, 0, 0.1)'
            } if (rate >= 9) {
                return 'rgba(39, 217, 31, 0.1)'
            }
            return 'rgba(255, 0, 0, 0.1)'
        },
        rateFontColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'accent--text'
            } if (rate >= 9) {
                return 'success--text'
            }
            return 'error--text'
        }
    }

}
</script>
