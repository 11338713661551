<template>
    <setting-layout title="Change password">
        <template #form>
            <v-form
                class="px-5 pb-5"
                @submit.prevent="changePassword"
            >
                <div>
                    <div class="password-tooltip">
                        <password-tooltip
                            :password="password"
                            :show="passwordTooltip"
                        />
                    </div>
                    <v-text-field
                        v-model="password"
                        outlined
                        label="New password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        color="accent"
                        prepend-inner-icon="mdi-lock"
                        :error-messages="validationMessage($v.password)"
                        @click:append="showPassword = !showPassword"
                        @focus="passwordTooltip = true"
                        @input="$v.password.$touch()"
                        @blur="passwordBlur()"
                    />
                </div>

                <v-text-field
                    v-model="password_repeat"
                    outlined
                    label="Confirm password"
                    :type="showPasswordR ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    color="accent"
                    prepend-inner-icon="mdi-lock"
                    :error-messages="validationMessage($v.password_repeat)"
                    @click:append="showPasswordR = !showPasswordR"
                    @input="$v.password_repeat.$touch()"
                    @blur="$v.password_repeat.$touch()"
                />

                <v-btn
                    color="accent"
                    width="100%"
                    height="56px"
                    type="submit"
                >
                    Submit
                </v-btn>
            </v-form>
        </template>
    </setting-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { validationMessage } from 'vuelidate-messages'
import SettingLayout from '@/layout/SettingLayout'
import { validationRules } from '~/validation/change-password/Rules'
import { formMessages } from '~/validation/change-password/Messages'
import PasswordTooltip from '@/components/shared/Helpers/PasswordTooltip'
import { eventBus } from '@/helpers/event-bus';

import {
    ACTION_UPDATE_SELF
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingLayout,
        PasswordTooltip
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            password: '',
            password_repeat: '',
            admin_id: null,
            showPassword: false,
            showPasswordR: false,
            passwordTooltip: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ])
    },
    metaInfo: {
        title: 'Change password'
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapAdminActions({
            ACTION_UPDATE_SELF
        }),
        async changePassword() {
            if (!this.$v.$invalid) {
                try {
                    await this[ACTION_UPDATE_SELF]({ password: this.password })
                    eventBus.$emit('showSnackBar', 'success', 'success')
                    this.$router.push({ name: 'user_setting' })
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                }
            }
        },
        passwordBlur() {
            this.$v.password.$touch()
            this.passwordTooltip = false
        }
    }
}
</script>

<style lang="scss">
    .password-tooltip {
        position: relative;
        left: 50%;
    }
</style>
