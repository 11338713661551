<template>
    <v-expand-transition>
        <div v-show="isShow">
            <!-- Dates -->
            <feedback-new
                v-if="data.parsed_testimonial_new"
                :data="data"
            />
            <feedback-old
                v-if="data.parsed_testimonial_old"
                :data="data"
            />
        </div>
    </v-expand-transition>
</template>

<script>
import FeedbackNew from './Templates/FeedbackNew.vue';
import FeedbackOld from './Templates/FeedbackOld.vue';

export default {
    components: {
        FeedbackNew,
        FeedbackOld
    },
    props: {
        isShow: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
