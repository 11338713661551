<template>
    <v-container
        fluid
        class="white"
    >
        <v-btn
            color="primary"
            class=" mb-5"
            @click="createNoteModal"
        >
            New note
        </v-btn>
        <v-row>
            <v-col
                cols="12"
            >
                <template
                    v-for="(item, index) in getterWriterNotes"
                >
                    <div
                        :key="index"
                    >
                        <div
                            v-if="item.admin"
                            class="text-subtitle-2 text-capitalize pl-13"
                        >
                            {{ nameCapitalized(item.admin.firstname) }}
                            {{ nameCapitalized(item.admin.lastname) }},
                            <span class="text-body-1 grey--text">
                                <!-- role: {{ item.admin.role }}, -->
                                id: {{ item.admin.id }}
                            </span>
                        </div>
                        <div class="d-flex flex-grow-1">
                            <avatar-rolles
                                v-if="item.admin"
                                access-status="ADMIN"
                                :firstname="item.admin.firstname"
                                :lastname="item.admin.lastname"
                                :avatar-url="item.admin.avatar_url"
                                :avatar-fallback="item.admin.avatar_fallback"
                            />
                            <div class="d-flex flex-column ml-2 flex-grow-1">
                                <div
                                    class="notes-detail pa-5"
                                >
                                    <div>
                                        {{ item.details }}
                                    </div>
                                    <div class="action_block">
                                        <template v-for="toolbar in toolbars">
                                            <v-tooltip
                                                :key="toolbar.name"
                                                top
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        dark
                                                        tile
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="actionRow(item, {modal: toolbar.modal, actionName: toolbar.name})"
                                                    >
                                                        <v-icon>
                                                            {{ toolbar.icon }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ toolbar.text }}</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </div>
                                <p class="grey--text mt-2 mb-10">
                                    {{ $date.dateFromNow(item.created_at) }},
                                    {{ $date.humanizeDate(item.created_at) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <div
                    v-if="!getterWriterNotes.length"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No data found
                    </p>
                </div>
            </v-col>
        </v-row>
        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :input-label="modal.inputLabel"
            :detail-redact="currentItem.details"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_DELETE_WRITER_NOTE,
    ACTION_EDIT_WRITER_NOTE,
    ACTION_GET_WRITER_NOTE_LIST,
    ACTION_WRITER_POST_NOTE
} from '@/store/modules/writers/action-types';
import { WRITERS_NOTE_TOOLBARS } from '@/constants/Writers'
import { nameCapitalized } from '@/helpers/capitalized/index'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        ModalAlert,
        ModalTextarea,
        AvatarRolles
    },
    props: {
        writer_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            modal: {},
            actionName: '',
            currentItem: {}
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterWriterNotes'
        ]),
        toolbars() {
            return WRITERS_NOTE_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    async activated() {
        await this.getNotes()
    },
    methods: {
        nameCapitalized,
        ...mapAdminActions({
            ACTION_GET_WRITER_NOTE_LIST,
            ACTION_DELETE_WRITER_NOTE,
            ACTION_EDIT_WRITER_NOTE,
            ACTION_WRITER_POST_NOTE
        }),
        async getNotes() {
            const { id } = this.$route.params
            await this[ACTION_GET_WRITER_NOTE_LIST]({ writer_id: id, sort_order: 'ASC' })
        },
        actionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'delete_note':
                    await this.deleteNote(this.currentItem.id)
                    break
                case 'edit_note':
                    await this.editNote(payload)
                    break
                case 'note_create':
                    await this.createNote(payload)
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteNote(payload) {
            await this[ACTION_DELETE_WRITER_NOTE](payload)
            this.$emit('update:counters', { type: 'notes', counter: -1 })
        },
        async editNote(payload) {
            const { id } = this.currentItem
            await this[ACTION_EDIT_WRITER_NOTE]({ ...payload, id })
        },
        async createNote(payload) {
            await this[ACTION_WRITER_POST_NOTE]({ writer_id: this.writer_id, ...payload })
            this.$emit('update:counters', { type: 'notes', counter: 1 })
        },
        createNoteModal() {
            this.actionName = 'note_create'
            this.modal = {
                name: 'ModalTextarea',
                title: 'Post a note',
                description: "Post a note to writer's profile.",
                inputLabel: 'Details',
                success_message: 'The note has been posted'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notes-detail{
    position: relative;
    background: #F4F8FD;
    border: 1px solid #EFEFEF;
    .action_block{
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: initial;
        left: initial;
        transform: initial;
        opacity: 0;
        .resolved-btn{
            display: flex;
            align-items: center;
            font-size: 12px;
        }
    }
    &:hover{
        .action_block{
            opacity: 1;
        }
    }
}

</style>
