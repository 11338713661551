<template>
    <v-container fluid>
        <v-row class="white rounded">
            <v-col
                class="mr-auto align-self-center flex-grow-0"
            >
                <div class="d-flex align-center">
                    <div
                        class="avatar_load"
                        @click="loadAvatar"
                    >
                        <writer-avatar
                            :id="info.id"
                            :show-snackbar="false"
                            :feedbacks="info.fb_reviews"
                            :rating="info.rank"
                            :applied="info.ts_apply"
                            :status="info.trust_status"
                            :firstname="info.firstname"
                            :lastname="info.lastname"
                            :avatar-url="info.avatar_url"
                            :avatar-fallback="info.avatar_fallback"
                            avatar-size="70"
                            :order_limit="info.projects_limit"
                            :country="info.country"
                        />
                        <div class="avatar_load_bg">
                            <v-icon color="#fff">
                                mdi-camera
                            </v-icon>
                        </div>
                    </div>
                    <div
                        class="flex-column ml-5"
                        style="white-space: nowrap"
                    >
                        <p class="font-weight-bold mb-0">
                            {{ nameCapitalized(info.firstname) }}
                            {{ nameCapitalized(info.lastname) }}
                            <span v-if="info.nickname">
                                |
                                <span class="caption">{{ nameCapitalized(info.nickname) }}</span>
                            </span>
                        </p>
                        <p class="grey--text mb-0">
                            {{ info.id }}
                            <span :class="'ml-1 flag-icon flag-input-icon flag-icon-' + info.country.toLowerCase()" />
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col
                v-if="isWriter"
                class="ml-auto flex-grow-0"
            >
                <p class="grey--text mb-0">
                    Balance:
                </p>
                <div class="d-flex align-center">
                    <p
                        v-if="info.statistics && info.statistics.earnings_pending"
                        class="text-subtitle-1 price--text mb-0"
                    >
                        {{ info.statistics.earnings_pending| money }}
                    </p>
                    <p
                        v-else
                        class="text-subtitle-1 price--text mb-0 mt-2"
                    >
                        —
                    </p>
                </div>
            </v-col>
            <v-col
                v-if="isWriter"
                class="flex-grow-0"
            >
                <p class="grey--text mb-0">
                    Earnings:
                </p>
                <div class="d-flex align-center">
                    <p
                        v-if="info.statistics && info.statistics.earnings_all"
                        class="text-subtitle-1 price--text mb-0"
                    >
                        {{ info.statistics.earnings_all | money }}
                    </p>
                    <p
                        v-else
                        class="text-subtitle-1 price--text mb-0 mt-2"
                    >
                        —
                    </p>
                </div>
            </v-col>
            <v-col
                v-if="isWriter"
                class="flex-grow-0"
            >
                <p class="grey--text mb-2">
                    Rating:
                </p>
                <rating
                    v-if="info.statistics && info.statistics.rank_all_count"
                    :rank_all_count="info.statistics.rank_all_count"
                    :rank_all="info.statistics.rank_all"
                    :rank_thirty_days=" info.statistics.rank_thirty_days"
                />
                <p
                    v-else
                    class="text-subtitle-1 price--text mb-0"
                >
                    —
                </p>
            </v-col>
            <v-col
                class="flex-grow-0"
            >
                <p class="grey--text mb-2">
                    Access:
                </p>
                <v-chip
                    color="rgba(242, 242, 242, 1)"
                >
                    {{ info.accessstatus }}
                </v-chip>
            </v-col>
            <v-col
                class="flex-grow-0"
            >
                <p class="grey--text mb-2">
                    Status:
                </p>
                <active-status :is-active="info.is_active" />
            </v-col>
            <v-col
                v-if="isWriter"
                class="flex-grow-0"
            >
                <p class="grey--text mb-2 text--nowrap">
                    In Progress:
                </p>
                <p v-if="info.statistics && info.statistics.orders_pending">
                    {{ info.statistics.orders_pending }}
                </p>
                <p
                    v-else
                    class="text-subtitle-1 price--text mb-0"
                >
                    —
                </p>
            </v-col>
            <v-col
                v-if="isWriter"
                class="flex-grow-0"
            >
                <p class="grey--text mb-2">
                    Limit:
                </p>
                <p>{{ info.projects_limit }}</p>
            </v-col>
            <table-rows-menu
                :toolbars="toolbars"
                class="mt-2"
                @menuActivated="onMenuActivated"
            />
        </v-row>
    </v-container>
</template>

<script>
import Rating from '@/components/Writer/WriterTable/Rating'
import ActiveStatus from '@/components/Writer/WriterTable/ActiveStatus'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import { nameCapitalized } from '@/helpers/capitalized/index'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'

import filtersMixin from '@/mixins/filtersMixin'

export default {
    components: {
        ActiveStatus,
        Rating,
        WriterAvatar,
        TableRowsMenu
    },
    mixins: [
        filtersMixin
    ],
    props: {
        info: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        isWriter: {
            type: Boolean,
            required: true
        },
        toolbars: {
            type: Array,
            required: true
        }
    },
    methods: {
        nameCapitalized,
        loadAvatar() {
            this.$emit('loadAvatar')
        },
        onCloseOrders() {
            this.showOrders = false
        },
        onMenuActivated(item, payload) {
            this.$emit('toolbarActivated', payload)
        }
    }
}
</script>

<style lang="scss">
    .avatar_load{
        position: relative;
        // width: 70px;
        // height: 70px;
        border-radius: 50%;
        // overflow: hidden;
        cursor: pointer;
        &:hover{
            .avatar_load_bg {
                opacity: 1;
            }
        }
        .avatar_load_bg {
            transition: .3s all;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,.5);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
</style>
