<template>
    <div class="d-flex comment-logs">
        <chat
            class="modal-chat"
            :chat="items"
            :loading="isMessageSending"
            @sendChatMessage="create"
            @typing="typingListener"
        />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { createCommunicationLog, getCommunicationLogs } from '@/services/orderService'
import Chat from '@/components/Chat/Chat'

export default {
    name: 'OrderCommentsLog',
    components: { Chat },
    props: {
        orderid: {
            type: String,
            required: true
        },
        client: {
            type: Object
        }
    },
    data() {
        return {
            items: [],
            loading: false,
            isMessageSending: false,
            typing: false,
            typingMessage: ''
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('order:typing', {
                typing: newVal,
                orderid: this.orderid
            })
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Comm.log | Order`
        }
    },
    async activated() {
        await this.getCommunicationLogs()
    },
    async created() {
        if (this.$route.name === 'orders') {
            await this.getCommunicationLogs()
        }
        if (this.$route.name === 'hr-clients-details') {
            await this.getCommunicationLogs()
        }
        this.socketConnect()
    },
    methods: {
        getOwner(item) {
            return item.sw_id ? 'writer' : item.owner
        },
        async create({ details }) {
            this.isMessageSending = true
            try {
                await createCommunicationLog({
                    orderid: this.orderid,
                    details
                })
                this.details = ''
                await this.getCommunicationLogs()
                eventBus.$emit('showSnackBar', 'The message was sent', 'success')
                this.$emit('update:counters', { type: 'message', counter: 1 })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isMessageSending = false
            }
        },
        async getCommunicationLogs() {
            this.loading = true
            try {
                this.items = await getCommunicationLogs({ orderid: this.orderid }, false)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        typingListener(value) {
            this.typing = value
        },
        socketConnect() {
            this.$socket.emit('order:subscribe', this.orderid)
            this.$socket.on('order:newMessage', async (data) => {
                if (data.author.id === this.getterMainInfo.id) return
                this.items.push(data)
            })
            this.$socket.on('order:typing', (data) => {
                if (data.role === 'Admin') return
                this.typingMessage = data.message
            })

            this.$socket.on('disconnect', () => {
                this.socketConnect()
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .comment-logs {
        flex-direction: column;
        min-width: 100%;
        max-height: calc(100vh - 230px);
        .editor{
            display: flex;
        }
    }

    .v-skeleton-loader__text {
        height: 350px;
    }
</style>
