<template>
    <v-container fluid>
        <div>
            <div class="d-flex align-center extras-row_col extras-row_col-title">
                <div class="d-flex align-center">
                    <v-icon class="mr-4">
                        mdi-information-outline
                    </v-icon>
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Instructions
                    </span>
                </div>
            </div>
            <div class="extras-row_col">
                <div>
                    <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                        <span v-html="computedDetails" />
                    </p>
                </div>
            </div>
        </div>
        <div class="py-5">
            <v-card
                elevation="2"
                class="px-0 py-0 d-flex mb-5"
                style="overflow-x: auto;"
            >
                <!-- Dates : START -->
                <div class="extras-row flex-column">
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-calendar-week
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Dates
                            </span>
                        </div>
                    </div>
                    <!-- Ordered: -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Ordered:
                            </p>
                            <p class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular">
                                <span
                                    class="font-weight-bold"
                                    style="white-space: nowrap"
                                >
                                    {{ $date.humanizeDate(order.dates.created_at) }}
                                </span>
                                <span class="font-weight-bold">
                                    ({{ $date.dateFromNow(order.dates.created_at) }})
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Due: -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Due:
                            </p>
                            <p
                                class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular"
                                :class="`${$date.dateColorDue(order.dates.due_at)}--text`"
                            >
                                <span
                                    class="font-weight-bold"
                                    style="white-space: nowrap"
                                >
                                    {{ $date.humanizeDate(order.dates.due_at) }}
                                </span>
                                <span class="font-weight-bold">
                                    ({{ $date.dateFromNow(order.dates.due_at) }})
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="order.due_at !== order.due_at_ini"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Initial due date:
                            </p>
                            <p
                                class="mb-0 d-flex flex-column text-subtitle-2 font-weight-regular"
                                :class="`${$date.dateColorDue(order.dates.due_at_ini)}--text`"
                            >
                                <span
                                    class="font-weight-bold"
                                    style="white-space: nowrap"
                                >
                                    {{ $date.humanizeDate(order.dates.due_at_ini) }}
                                </span>
                                <span class="font-weight-bold">
                                    ({{ $date.dateFromNow(order.dates.due_at_ini) }})
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Dates : END -->
                <!-- Payment : START -->
                <div class="extras-row flex-column">
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-credit-card-outline
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Payment
                            </span>
                        </div>
                    </div>
                    <!-- Desired price:: -->
                    <div
                        v-if="order.fin.desired_price"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Desired price:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-thin blue--text">
                                    {{ order.fin.desired_price | money }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Project total:: -->
                    <div
                        v-if="order.fin.total"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Project total:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-normal">
                                    {{ order.fin.total | money }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Batch total: -->
                    <div
                        v-if="order.fin.invoice && order.fin.invoice.total"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Batch total:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.fin.invoice.total | money }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Total Writer: -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Total Writer:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span
                                    v-if="order.fin.sw_total > 0"
                                    class="font-weight-bold"
                                >
                                    {{ order.fin.sw_total | money }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Store credit: -->
                    <div
                        v-if="order.fin.invoice"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Store credit:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span
                                    v-if="order.fin.invoice.store_credit"
                                    class="font-weight-bold"
                                >
                                    {{ order.fin.invoice.store_credit | money }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Discount: -->
                    <div class="extras-row_col">
                        <div v-if="order.fin.discount">
                            <p class="grey--text mb-0 text-body-2">
                                Discount:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.fin.discount.amount | money }}
                                </span>
                            </p>
                        </div>
                        <div v-if="order.fin.discount">
                            <p class="grey--text mb-0 text-body-2">
                                Discount type / code:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.discount_code || `${order.discount_rate ? 'Loyalty' : '—'}` }}
                                </span>
                            </p>
                        </div>
                        <div v-if="order.fin.discount">
                            <p class="grey--text mb-0 text-body-2">
                                Discount rate:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.fin.discount.rate | percent }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Payment : END -->
                <!-- Summary : START -->
                <div class="extras-row flex-column">
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-table-of-contents
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Summary
                            </span>
                        </div>
                    </div>
                    <!-- Title: -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Title:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-normal">
                                    "{{ order.project_information.title }}"
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Project: -->
                    <div
                        v-if="order.project_information.type"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Project:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.type }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Level: -->
                    <div
                        v-if="order.project_information.academiclevel"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Level:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.academiclevel }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Citation Style: -->
                    <div
                        v-if="order.project_information.style"
                        class="extras-row_col"
                        :class="{'grey--text': order.project_information.style === '0'}"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Citation Style:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.style === '0' ? 'No citation style selected' : order.project_information.style }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Sources: -->
                    <div
                        v-if="order.project_information.sources"
                        :class="{'grey--text': order.project_information.sources === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Sources:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.sources }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Pages: -->
                    <div
                        :class="{'grey--text': order.project_information.pages === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Pages:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.pages }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Slides: -->
                    <div
                        :class="{'grey--text': order.project_information.slides === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Slides:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.slides }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Charts: -->
                    <div
                        :class="{'grey--text': order.project_information.charts === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Charts:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.charts }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Problems: -->
                    <div
                        :class="{'grey--text': order.project_information.problems === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Problems:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.problems }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Summary : END -->
                <!-- Extra services : START -->
                <div class="extras-row flex-column">
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-account-wrench-outline
                            </v-icon>
                            <span
                                class="font-weight-bold text-uppercase text-subtitle-2"
                                style="white-space: nowrap;"
                            >
                                Extra services
                            </span>
                        </div>
                    </div>
                    <!-- Abstract: -->
                    <div
                        :class="{'grey--text': order.project_information.chk_abstract === 0}"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Abstract:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.chk_abstract }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <!-- Outline: -->
                    <div
                        class="extras-row_col"
                        :class="{'grey--text': order.project_information.chk_outline === 0}"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Outline:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span class="font-weight-bold">
                                    {{ order.project_information.chk_outline }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Extra services : END -->
            </v-card>
        </div>
    </v-container>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'
import { eventBus } from '@/helpers/event-bus';

export default {
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showWriters: false,
            writersTitle: ''
        }
    },
    computed: {
        computedDetails() {
            const splitString = this.order.project_information.details.split('\n').join('<br>')
            return splitString
        }
    },
    methods: {
        closeWriters() {
            this.showWriters = false
        },
        copy(text) {
            navigator.clipboard.writeText(text).then(() => {
                eventBus.$emit('showSnackBar', 'Copying to clipboard was successful!', 'success')
            }, (err) => {
                eventBus.$emit('showSnackBar', err, 'error')
            });
        }
    }
}
</script>

<style lang="scss">
.activity-row{
    display: flex;
    margin: 0 0 10px;
    background: #FAFAFA;
    border-radius: 6px;
    @media all and (max-width: 1700px) {
        flex-wrap: wrap;
    }
    &_col{
        width: calc(12% - 10px);
        margin: 0 10px;
        @media all and (max-width: 1700px){
            width: calc(20% - 10px);
            margin: 10px;
        }
        &-min {
            min-width: 85px;
        }
    }
}
</style>
