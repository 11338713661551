<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="orderid"
                            v-model="orderid"
                            clearable
                            color="accent"
                            outlined
                            :items="orders"
                            :loading="orderLoading"
                            item-value="orderid"
                            :filter="(item) => item"
                            :search-input.sync="orderSearch"
                            hide-details
                            label="Order ID"
                            :rules="[v => !!v || 'Order ID is required']"
                            @click:clear="onClearOrderSearch"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ item }"
                            >
                                <div class="d-flex flex-column">
                                    <div class="">
                                        «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                    </div>
                                    <div class="">
                                        {{ $date.dateFromNow(item.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="item"
                                slot-scope="{ item }"
                            >
                                <div class="d-flex flex-column w-full">
                                    <div class="">
                                        «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                    </div>
                                    <div class="">
                                        {{ $date.dateFromNow(item.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                    </div>
                                    <v-divider class="w-full mt-2" />
                                </div>
                            </template>
                            <template v-slot:append-item>
                                <div v-intersect="endOrderid" />
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="ticketid"
                            clearable
                            color="accent"
                            outlined
                            :items="tickets"
                            :loading="ticketLoading"
                            item-value="ticketid"
                            hide-details
                            label="Ticket ID"
                            :disabled="!orderid"
                            :rules="[v => !!v || 'Ticket ID is required']"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ item }"
                            >
                                <div class="d-flex flex-column">
                                    <div class="">
                                        «{{ `${ item.subject ? item.subject.length > 55 ? `${item.subject.slice(0, 55)}...` : item.subject : 'empty subject'}` }}»
                                    </div>
                                    <div class="">
                                        {{ $date.dateFromNow(item.created_at) }}, {{ item.ticketid }}, {{ item.status_support }}
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="item"
                                slot-scope="{ item }"
                            >
                                <div class="d-flex flex-column w-full">
                                    <div class="d-flex flex-column">
                                        <div class="">
                                            «{{ `${ item.subject.length > 55 ? `${item.subject.slice(0, 55)}...` : item.subject}` }}»
                                        </div>
                                        <div class="">
                                            {{ $date.dateFromNow(item.created_at) }}, {{ item.ticketid }}, {{ item.status_support }}
                                        </div>
                                    </div>
                                    <v-divider class="w-full mt-2" />
                                </div>
                            </template>
                            <template v-slot:append-item>
                                <div v-intersect="endTickets" />
                            </template>
                        </v-select>
                        <p
                            v-if="!orderid"
                            class="d-block px-1 mt-1 mb-0"
                            :class="{'error--text' : !orderid}"
                        >
                            Select a order before selecting a ticket
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model.number="amount"
                            label="Credits"
                            outlined
                            :rules="amountRules"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            v-model="reference"
                            outlined
                            color="accent"
                            label="Reference"
                            hide-details
                            required
                            :rules="[v => !!v || 'Reference is required']"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>

import { debounce } from 'lodash'
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        writerid: {
            type: [Number, String],
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            valid: true,
            showModal: true,
            // order logic
            orderSearchBy: 'orderid',
            orderid: '',
            orders: [],
            orderSearch: '',
            orderLoading: false,
            orderPage: 1,
            ordersLastPage: Infinity,
            // ticket logic
            ticketSearchBy: 'orderid',
            ticketid: '',
            tickets: [],
            ticketSearch: '',
            ticketLoading: false,
            ticketPage: 1,
            ticketsLastPage: Infinity,
            //
            amount: 0,
            reference: '',
            amountRules: [
                (v) => !!v || 'Credits is required',
                (v) => /^-?\d+(\.\d+)?$/.test(v) || 'Only numbers'
            ]
        }
    },
    watch: {
        orderSearch: {
            handler: debounce(async function () {
                this.orderPage = 1
                await this.makeOrderSearch()
            }, 300)
        },
        async orderid(val) {
            this.ticketSearch = val
            await this.makeTicketSearch()
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.validate()
            if (this.valid) {
                const {
                    orderid, ticketid, amount, reference
                } = this
                this.$emit('confirmModal', {
                    orderid, ticketid, amount: +amount, reference
                })
            }
        },
        // Order select
        async getTickets(payload) {
            this.ticketLoading = true
            try {
                const { data } = await Api.get('/api/support/writer/ticket/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.ticketPage, mode: 'list', writer_id: this.writerid
                }), {}, false)
                const { data: totals } = await Api.get('/api/support/writer/ticket/list-by-params', removeEmptyParamsFromRequest({
                    ...payload,
                    page: this.ticketPage,
                    mode: 'count',
                    writer_id: this.writerid
                }), {}, false)
                this.tickets = [...this.tickets, ...data.data]
                this.ticketsLastPage = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.ticketLoading = false
            }
        },
        async makeTicketSearch() {
            this.tickets = []
            this.ticketsLastPage = Infinity
            this.ticketPage = 1
            await this.getTickets({ search_by: this.ticketSearchBy, search_for: this.ticketSearch })
        },
        endTickets(entries, observer, isIntersecting) {
            if (isIntersecting && this.ticketsLastPage >= this.ticketPage) {
                this.ticketPage += 1
                this.getTickets({ search_by: this.ticketSearchBy, search_for: this.ticketSearch })
            }
        },
        // Order select
        async getOrders(payload) {
            this.orderLoading = true
            try {
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.orderPage, mode: 'list', writer_id: this.writerid
                }), {}, false)
                const { data: totals } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload,
                    page: this.orderPage,
                    mode: 'count',
                    writer_id: this.writerid
                }), {}, false)

                this.orders = [...this.orders, ...data.data]
                this.ordersLastPage = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async makeOrderSearch() {
            this.orders = []
            this.order_last_page = Infinity
            if (this.orderSearch && this.orderSearch.toLowerCase().includes('s-')) {
                this.orderSearchBy = 'orderid'
            } else {
                this.orderSearchBy = 'title'
            }
            await this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
        },
        endOrderid(entries, observer, isIntersecting) {
            if (isIntersecting && this.ordersLastPage >= this.orderPage) {
                this.orderPage += 1
                this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        },
        onClearOrderSearch() {
            this.orderSearch = ''
            this.orderid = ''
        }
    }
}
</script>

<style>

</style>
