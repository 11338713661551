<template>
    <setting-layout
        title="Change Two-Factor Authentication"
        card-width="800px"
    >
        <template #form>
            <v-form
                ref="entryForm"
                class="px-5 pb-5"
            >
                <v-radio-group
                    v-model="twoFa"
                    row
                >
                    <v-radio
                        label="Enable"
                        :value="true"
                    />
                    <v-radio
                        label="Disable"
                        :value="false"
                    />
                </v-radio-group>
                <v-row v-if="twoFa">
                    <v-col cols="8">
                        <v-row>
                            <v-col cols="2">
                                Step 1
                            </v-col>
                            <v-col cols="10">
                                Open up an authentication app on your phone. If you don't already have one, we recommend
                                <a
                                    href="https://authy.com/download/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Authy by Twilio
                                </a>
                                or Google-Authenticator (
                                <a
                                    href="https://drive.google.com/uc?export=download&confirm=no_antivirus&id=1FFcePHpBCvQTA-ht5mJVynp5fVDxRBiJ"
                                    target="_blank"
                                >
                                    Windows
                                </a>,
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                    target="_blank"
                                >
                                    Android
                                </a>,
                                <a
                                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                    target="_blank"
                                >
                                    iOS
                                </a>
                                ).
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                Step 2
                            </v-col>
                            <v-col cols="10">
                                <div class="profile-twoFa__steps-inner">
                                    Using the app, scan the QR code on the right or manually enter this secret key:
                                    <b>
                                        {{ secret }}
                                    </b>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                Step 3
                            </v-col>
                            <v-col cols="10">
                                <div class="profile-twoFa__steps-inner">
                                    Enter the 6-digit code generated by your authentication app.
                                    <div class="profile-twoFa__steps-inner-form">
                                        <v-text-field
                                            v-model="code"
                                            class="mt-4"
                                            color="accent"
                                            outlined
                                            :rules="[rules.required, rules.minLengthCode, rules.maxLengthCode, rules.validCode ]"
                                            label="6-digit code"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <img
                            :src="`data:image/jpeg;base64,${png}`"
                            width="200"
                            height="200"
                            alt="qr"
                        >
                    </v-col>
                </v-row>
                <v-card-actions
                    class="py-5 px-5 justify-end"
                >
                    <v-btn
                        color="accent"
                        class="py-4 px-3"
                        height="max-content"
                        :loading="loading"
                        :disabled="!twoFa ? false : !correctOTP"
                        @click="onSave"
                    >
                        save
                    </v-btn>
                </v-card-actions>
            </v-form>
        </template>
    </setting-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import SettingLayout from '@/layout/SettingLayout'

import {
    Enroll, EnableTwoFa, DisableTwoFa
} from '@/services/2FA';
import {
    SET_TWOFA_STATUS
} from '@/store/modules/client/mutation-types';

const {
    mapMutations: mapWriterMutations
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingLayout
    },
    data() {
        return {
            secret: null,
            png: null,
            twoFa: false,
            code: '',
            sympleValid: false,
            correctOTP: false,
            rules: {
                required: (value) => !!value || 'Required.',
                minLengthCode: (value) => value.length > 5 || 'The code must be at least 6 characters.',
                maxLengthCode: (value) => value.length < 7 || 'The subject must not be greater than 6 characters.',
                validCode: () => this.correctOTP || 'Incorrect code'
            }
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterTwoFa'
        ])
    },
    metaInfo: {
        title: 'Change Two-Factor Authentication'
    },
    watch: {
        async twoFa(value) {
            if (value) {
                await this.enroll()
            }
            if (!value) {
                await DisableTwoFa()
                this[SET_TWOFA_STATUS](0)
            }
        },
        code() {
            if (!this.correctOTP) {
                this.correctOTP = true
                this.$nextTick(() => {
                    this.$v.$reset()
                })
            }
        }
    },
    created() {
        this.twoFa = Boolean(this.getterTwoFa)
        if (this.twoFa) {
            this.enroll()
        }
    },
    methods: {
        ...mapWriterMutations({
            SET_TWOFA_STATUS
        }),
        async enroll() {
            const { secret, png } = await Enroll()
            this.secret = secret
            this.png = png
        },
        async onChange(payload) {
            if (payload === 'Enable') {
                this.enroll()
            }
            if (payload === 'Disable') {
                await DisableTwoFa()
                this[SET_TWOFA_STATUS](0)
            }
            this.twoFa = payload === 'Enable'
        },
        async onSave() {
            if (!this.twoFa) {
                this.$router.push({ name: 'user_setting' })
                return
            }
            this.correctOTP = await EnableTwoFa(this.code)
            this.$refs.entryForm.validate()
            if (this.correctOTP) {
                this[SET_TWOFA_STATUS](1)
                this.$router.push({ name: 'user_setting' })
            }
        }
    }
}
</script>

<style>

</style>
