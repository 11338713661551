var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show-modal":_vm.showModal,"loading":_vm.loading},on:{"closeModal":_vm.onCloseModal,"confirmModal":_vm.onConfirmModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"orderid",attrs:{"clearable":"","color":"accent","outlined":"","items":_vm.orders,"loading":_vm.orderLoading,"item-value":"orderid","filter":function (item) { return item; },"search-input":_vm.orderSearch,"hide-details":"","label":"Order ID","rules":[function (v) { return !!v || 'Order ID is required'; }]},on:{"update:searchInput":function($event){_vm.orderSearch=$event},"update:search-input":function($event){_vm.orderSearch=$event},"click:clear":_vm.onClearOrderSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{},[_vm._v(" «"+_vm._s(("" + (item.project_information.title.length > 55 ? ((item.project_information.title.slice(0, 55)) + "...") : item.project_information.title)))+"» ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.$date.dateFromNow(item.created_at))+", "+_vm._s(item.orderid)+", "+_vm._s(item.status)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column w-full"},[_c('div',{},[_vm._v(" «"+_vm._s(("" + (item.project_information.title.length > 55 ? ((item.project_information.title.slice(0, 55)) + "...") : item.project_information.title)))+"» ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.$date.dateFromNow(item.created_at))+", "+_vm._s(item.orderid)+", "+_vm._s(item.status)+" ")]),_c('v-divider',{staticClass:"w-full mt-2"})],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endOrderid),expression:"endOrderid"}]})]},proxy:true}]),model:{value:(_vm.orderid),callback:function ($$v) {_vm.orderid=$$v},expression:"orderid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"clearable":"","color":"accent","outlined":"","items":_vm.tickets,"loading":_vm.ticketLoading,"item-value":"ticketid","hide-details":"","label":"Ticket ID","disabled":!_vm.orderid,"rules":[function (v) { return !!v || 'Ticket ID is required'; }]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{},[_vm._v(" «"+_vm._s(("" + (item.subject ? item.subject.length > 55 ? ((item.subject.slice(0, 55)) + "...") : item.subject : 'empty subject')))+"» ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.$date.dateFromNow(item.created_at))+", "+_vm._s(item.ticketid)+", "+_vm._s(item.status_support)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column w-full"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{},[_vm._v(" «"+_vm._s(("" + (item.subject.length > 55 ? ((item.subject.slice(0, 55)) + "...") : item.subject)))+"» ")]),_c('div',{},[_vm._v(" "+_vm._s(_vm.$date.dateFromNow(item.created_at))+", "+_vm._s(item.ticketid)+", "+_vm._s(item.status_support)+" ")])]),_c('v-divider',{staticClass:"w-full mt-2"})],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endTickets),expression:"endTickets"}]})]},proxy:true}]),model:{value:(_vm.ticketid),callback:function ($$v) {_vm.ticketid=$$v},expression:"ticketid"}}),(!_vm.orderid)?_c('p',{staticClass:"d-block px-1 mt-1 mb-0",class:{'error--text' : !_vm.orderid}},[_vm._v(" Select a order before selecting a ticket ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Credits","outlined":"","rules":_vm.amountRules},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","color":"accent","label":"Reference","hide-details":"","required":"","rules":[function (v) { return !!v || 'Reference is required'; }]},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }