<template>
    <viewer
        :title="'Bids'"
        container-width="80%"
        class="bids-viewer"
        @closeSidePanel="closeBids"
    >
        <template #body>
            <div v-if="bids">
                <div class="d-flex my-4 px-5">
                    <v-chip
                        outlined
                        class="mr-2"
                        :class="{'opacity-3' : bidsInfo.project_information.pages === 0}"
                    >
                        Pages: {{ bidsInfo.project_information.pages }}
                    </v-chip>
                    <v-chip
                        outlined
                        class="mr-2"
                        :class="{'opacity-3' : bidsInfo.project_information.slides === 0}"
                    >
                        Slides: {{ bidsInfo.project_information.slides }}
                    </v-chip>
                    <v-chip
                        outlined
                        class="mr-2"
                        :class="{'opacity-3' : bidsInfo.project_information.charts === 0}"
                    >
                        Charts: {{ bidsInfo.project_information.charts }}
                    </v-chip>
                    <v-chip
                        outlined
                        class="mr-2"
                        :class="{'opacity-3' : bidsInfo.project_information.problems === 0}"
                    >
                        Problems: {{ bidsInfo.project_information.problems }}
                    </v-chip>
                </div>
                <div class="d-flex justify-space-between my-4 px-5">
                    <div>
                        <div class="mb-2 font-weight-light">
                            Order due:
                        </div>
                        <div>
                            <span>{{ $date.humanizeDate(bidsInfo.orderdue) }} | </span>
                            <span class="text-caption">{{ $date.dateFromNow(bidsInfo.orderdue) }}</span>
                        </div>
                    </div>
                    <div v-if="preferred_writers">
                        <div class="mb-2 font-weight-light">
                            Preferred writers:
                        </div>
                        <div class="d-flex align-center">
                            <div
                                v-for="(item, key) in preferred_writers.slice(0, 1)"
                                :key="key"
                            >
                                <div class="d-flex align-center mb-4">
                                    <div class="bids_avatar">
                                        <writer-avatar
                                            :id="item.sw_id"
                                            :feedbacks="item.fb_reviews"
                                            :applied="item.ts_apply"
                                            :status="item.trust_status"
                                            :firstname="item.firstname"
                                            :lastname="item.lastname"
                                            :avatar-url="item.avatar_url"
                                            :avatar-fallback="item.avatar_fallback"
                                            :order_limit="item.projects_limit"
                                            :country="item.country"
                                            :show-snackbar="false"
                                        />
                                    </div>
                                    <div class="d-flex flex-column ml-5">
                                        <span class="text-subtitle-2 font-weight-regular">{{ item.firstname }} {{ item.lastname }}</span>
                                        <span class="text-subtitle-2 font-weight-regular">ID: {{ item.sw_id }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="preferred_writers.length > 1"
                                class="d-flex align-center relative bids-preferred__additional"
                            >
                                <div
                                    v-if="preferred_writers.length > 1"
                                    class="d-flex align-center justify-center primary white--text ml-2 bids-preferred__counters"
                                    @click="showPreferred = !showPreferred"
                                >
                                    + {{ preferred_writers.slice(1).length }}
                                </div>
                                <div
                                    v-if="showPreferred"
                                    class="bids-preferred__list"
                                >
                                    <div
                                        v-for="(item, index) in preferred_writers.slice(1)"
                                        :key="index"
                                    >
                                        <div class="d-flex align-center my-4">
                                            <div class="bids_avatar">
                                                <writer-avatar
                                                    :id="item.sw_id"
                                                    :feedbacks="item.fb_reviews"
                                                    :applied="item.ts_apply"
                                                    :status="item.trust_status"
                                                    :firstname="item.firstname"
                                                    :lastname="item.lastname"
                                                    :avatar-url="item.avatar_url"
                                                    :avatar-fallback="item.avatar_fallback"
                                                    :order_limit="item.projects_limit"
                                                    :country="item.country"
                                                    :show-snackbar="false"
                                                />
                                            </div>
                                            <div class="d-flex flex-column ml-5">
                                                <span class="text-subtitle-2 font-weight-regular">{{ item.firstname }} {{ item.lastname }}</span>
                                                <span class="text-subtitle-2 font-weight-regular">ID: {{ item.sw_id }}</span>
                                            </div>
                                        </div>
                                        <v-divider v-if="index !== preferred_writers.slice(1).length - 1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mb-2 font-weight-light text-subtitle-2">
                            Project total:
                        </div>
                        <span class="font-weight-bold">{{ bidsInfo.fin.total | money }}</span>
                    </div>
                </div>
                <v-data-table
                    :headers="tableHeader"
                    :items="bids"
                    item-key="id"
                    :loading="false"
                    calculate-widths
                    class="mt-10"
                    must-sort
                    :sort-by="'due_at'"
                    :sort-desc="false"
                >
                    <template v-slot:body="{ items }">
                        <table-skeleton
                            v-if="bids.length === 0"
                            :column-count="tableHeaderLength"
                        />
                        <tbody class="bids_scroll">
                            <tr
                                v-for="bid in items"
                                :key="bid.id"
                                class="hovered-row"
                                :class="getClass(bid)"
                            >
                                <!-- update -->
                                <td>
                                    <span class="mb-2">
                                        {{ $date.dateFromNow(bid.updated_at) }}
                                    </span>
                                </td>
                                <td>
                                    <div class="d-flex align-center">
                                        <div class="bids_avatar">
                                            <writer-avatar
                                                :id="+bid.owner"
                                                :firstname="bid.author.firstname"
                                                :lastname="bid.author.lastname"
                                                :avatar-url="bid.author.avatar_url"
                                                :status="bid.author.trust_status"
                                                :avatar-fallback="bid.author.avatar_fallback"
                                                :country="bid.author.country"
                                                :country-nice-name="bid.author.country_nice_name"
                                                :show-snackbar="false"
                                            />
                                        </div>
                                        <div class="ml-5">
                                            <p class="mb-0 font-weight-medium">
                                                {{ bid.author.firstname }} {{ bid.author.lastname }}
                                            </p>
                                            <p class="mb-0 font-weight-light">
                                                {{ bid.author.id }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <!-- order limit -->
                                <td class="">
                                    <p class="font-weight-bold">
                                        {{ bid.author.projects_limit || '-' }}
                                    </p>
                                </td>
                                <!-- order in progress -->
                                <td class="">
                                    <p class="font-weight-bold">
                                        {{ bid.author_statistics.orders_in_progress || '-' }}
                                    </p>
                                </td>
                                <!-- order in revision -->
                                <td class="">
                                    <p class="font-weight-bold">
                                        {{ bid.author_statistics.orders_in_revision || '-' }}
                                    </p>
                                </td>
                                <!-- feedbacks -->
                                <td class="">
                                    <p class="">
                                        <span class="font-weight-bold">{{ bid.author_statistics.reviews || '-' }}</span>
                                        <span style="font-size: 11px; margin-left: 5px">({{ bid.author_statistics.reviews_all }})</span>
                                    </p>
                                </td>
                                <!-- rate -->
                                <td class="">
                                    <p class="">
                                        <span class="font-weight-bold">{{ bid.author_statistics.rank || '-' }}</span>
                                        <span style="font-size: 11px; margin-left: 5px">({{ bid.author_statistics.rank_all }})</span>
                                    </p>
                                </td>
                                <!-- domain -->
                                <td>
                                    <div>
                                        <div
                                            class="d-flex flex-column font-weight-medium"
                                            :class="$date.diffBetween(bidsInfo.orderdue, bid.due_at) >= 0 ? 'success--text' : 'error--text'"
                                        >
                                            <span>{{ $date.humanizeDate(bid.due_at) }} |</span>
                                            <span class="grey--text text-caption">{{ $date.dateFromNow(bid.due_at) }}</span>
                                        </div>
                                    </div>
                                </td>
                                <!-- created_at -->
                                <td>
                                    <div>
                                        <p class="font-weight-medium text-right">
                                            {{ bid.amount | money }}
                                        </p>
                                    </div>
                                </td>

                                <!-- expires_at -->
                                <td>
                                    <div class="d-flex justify-end">
                                        <v-tooltip
                                            v-for="toolbar in toolbars"
                                            :key="toolbar.name"
                                            top
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="showModal(toolbar, bid.id)"
                                                >
                                                    <v-icon>
                                                        {{ toolbar.icon }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ toolbar.text }}</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <div
                    v-if="!bids.length && !isLoading"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No Bids found
                    </p>
                </div>
            </div>
        </template>
        <template #modals>
            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :bid="currentBid"
                :loading="sendLoading"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </template>
    </viewer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import filtersMixin from '@/mixins/filtersMixin'
import {
    assignBid, deleteBid, getBids, getPreferred
} from '@/services/bidsService'
import { TOOLBARS } from '@/constants/Order/bid-toolbar'
import {
    TABLE_HEADER
} from '@/constants/Bids/index.js'
import Viewer from '@/components/SidePanels/Viewer'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalBidAssign from '@/components/shared/Modals/ModalBidAssign'
import {
    ACTION_GET_WRITER_SHORT_LIST
} from '@/store/modules/writers/action-types'

const {
    mapActions: mapWritersActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        Viewer,
        WriterAvatar,
        ModalAlert,
        ModalBidAssign
    },
    mixins: [
        filtersMixin
    ],
    props: {
        bidsInfo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            bids: null,
            isLoading: true,
            showDeleteModal: false,
            preferred_writers: null,
            currId: null,
            modal: {},
            actionName: '',
            currentBid: null,
            sendLoading: false,
            showPreferred: false
        }
    },
    computed: {
        toolbars() {
            return TOOLBARS
        },
        tableHeader() {
            return TABLE_HEADER
        },
        tableHeaderLength() {
            return TABLE_HEADER.length
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    async created() {
        this.isLoading = true
        await this.getBidsList()
        await this.getPreferredWriters()
        this.isLoading = false
    },
    methods: {
        ...mapWritersActions({
            ACTION_GET_WRITER_SHORT_LIST
        }),
        async onConfirmModal(payload) {
            this.sendLoading = true
            try {
                switch (this.actionName) {
                case 'assign_bid':
                    await this.assignBid(payload)
                    break
                case 'delete_bid':
                    await this.deleteBid(payload)
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.sendLoading = false
            }
        },
        getClass(bid) {
            let className = ''
            const found = this.preferred_writers?.find((item) => item.sw_id === bid.author.id)
            if (bid.author.projects_limit <= bid.author_statistics.orders_in_progress) {
                className += 'opacity-3'
            }
            if (found) {
                className += 'green accent-1'
            }
            return className
        },
        showModal(action, id) {
            this.currId = id
            this.currentBid = this.bids.find((bid) => bid.id === id)
            this.actionName = action.name
            this.modal = action.modal
        },
        onCloseModal() {
            this.modal = {}
            this.currentBid = {}
            this.actionName = ''
        },
        async getPreferredWriters() {
            const data = await getPreferred({ writers: this.bidsInfo.preferred })
            this.preferred_writers = data
        },
        async deleteBid() {
            await deleteBid({ id: this.currId })
            this.bids = this.bids.filter((bid) => bid.id !== this.currId)
            this.$emit('deleteBid', { id: this.currId, order_id: this.bidsInfo.orderid })
        },
        async assignBid() {
            await assignBid({ id: this.currId })
            this.$emit('assignBid', this.currentBid, this.bidsInfo.orderid)
        },
        async getBidsList() {
            const data = await getBids({ orderid: this.bidsInfo.orderid })
            this.bids = data
        },
        closeBids() {
            this.$emit('closeBids')
        },
        rankBackgroundColor(val) {
            if (val >= 5 && val < 9) {
                return 'rgba(240, 125, 0, 1)'
            } if (val >= 9) {
                return 'rgba(39, 217, 31, 1)'
            }
            return 'rgba(255, 0, 0, 1)'
        }
    }
}
</script>

<style lang="scss">
    .bids {
        &_avatar{
            position: relative;
        }
        &-viewer {
            .side_panel {
                max-width: 70%;
                padding: 0 !important;
                .viewer-title {
                    padding-right: 20px;
                    padding-left: 20px;
                }
            }
            .opacity-3 {
                opacity: 0.3;
            }
        }
        &_scroll {
            overflow-y: scroll;
            padding-bottom: 20px;
        }
        &-preferred {
            &__counters {
                min-width: 40px;
                min-height: 40px;
                border-radius: 50%;
                cursor: pointer;
            }
            &__list {
                position: absolute;
                top: 50px;
                left: -100px;
                max-height: 300px;
                width: 250px;
                border-radius: 4px;
                padding: 10px 15px 0;
                background: #fff;
                box-shadow: 0 5px 20px rgb(0 0 0 / 30%);
                overflow: scroll;
                z-index: 9999999;
            }
        }
    }
    .avatar_rank{
        font-size: 10px;
        position: absolute;
        top: 0px;
        left: 30px;
        background: rgb(39, 174, 96);
        padding: 3px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
    }
</style>
