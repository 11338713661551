<template>
    <div class="table_general">
        <div class="d-flex align-center">
            <v-chip
                v-if="data.isNew"
                class="ma-2"
                color="green"
                text-color="white"
            >
                New
            </v-chip>
            <v-chip
                v-if="data.isUpdated && !data.isNew"
                class="ma-2"
                color="primary"
            >
                Updated
            </v-chip>
            <span class="mr-2">{{ index }}.</span>
            <router-link
                :to="{
                    name: 'order-details',
                    params: {
                        id: data.orderid
                    }
                }"
                class="mb-0 text-decoration-none text-subtitle-2 d-flex align-center"
            >
                <!-- standard order -->
                <span v-if="data.flow_type === 1">
                    <span class="font-weight-bold mr-2">
                        {{ data.project_information.type }}
                    </span> |
                    <span class="ml-2 font-weight-normal caption">
                        {{ data.project_information.academiclevel }}
                    </span>
                </span>
                <!-- express order -->
                <span v-if="data.flow_type === 2">
                    <span class="font-weight-bold mr-2 overline">
                        Express Order
                    </span>
                </span>
            </router-link>
        </div>
        <v-tooltip
            top
            color="black"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="d-inline-block order-title text-truncate tw-font-bold"
                    v-bind="attrs"
                    v-on="on"
                >
                    &laquo;{{ data.project_information.title }}&raquo;
                </div>
            </template>
            <div>&laquo;{{ data.project_information.title }}&raquo;</div>
        </v-tooltip>
        <div
            class="d-flex justify-space-between"
            style="height: 26px"
        >
            <!-- START: order bids -->
            <div class="d-flex">
                <span
                    v-if="data.counters.bids_count > 0"
                    class="badge"
                    style="cursor: pointer;"
                    @click="showBids"
                >
                    <b>{{ data.counters.bids_count }}</b> {{ `${data.counters.bids_count === 1 ? 'bid' : 'bids'}` }}
                </span>
                <!-- END: order bids -->
                <!-- order notes (to do) -->
                <span
                    v-if="data.counters.notes_count > 0"
                    class="badge"
                    style="cursor: pointer;"
                    @click="showNotes"
                >
                    <b>{{ data.counters.notes_count }}</b> {{ `${data.counters.notes_count === 1 ? 'note' : 'notes'}` }}
                </span>
                <!-- order notes (to do) -->
                <!-- START: order testimonias -->
                <span
                    v-if="data.counters.testimonials_count > 0"
                    class="badge"
                    style="cursor: pointer;"
                    @click="showTestimonials"
                >
                    <b>{{ data.counters.testimonials_count }}</b> {{ `${data.counters.testimonials_count === 1 ? 'testimonial' : 'testimonials'}` }}
                </span>
                <span
                    v-if="data.counters.preferred_writers_count > 0"
                    class="badge success--text success lighten-5"
                    style="cursor: pointer;"
                    @click.stop="openSidebar('preferred')"
                >
                    <v-icon
                        class="mr-2"
                        size="16"
                        color="success"
                    >
                        mdi-account-multiple-check
                    </v-icon>
                    <b>{{ data.counters.preferred_writers_count }}</b>
                </span>
                <span
                    v-if="data.counters.blocked_writers_count > 0"
                    class="badge error--text error lighten-5"
                    style="cursor: pointer;"
                    @click.stop="openSidebar('blocked')"
                >
                    <v-icon
                        class="mr-2"
                        size="16"
                        color="error"
                    >
                        mdi-account-multiple-minus
                    </v-icon>
                    <b>{{ data.counters.blocked_writers_count }}</b>
                </span>
                <v-tooltip
                    v-if="data.linked_to_order"
                    top
                    open-on-click
                    :open-on-hover="false"
                    color="primary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            class="badge primary lighten-4 ml-2"
                            style="cursor: pointer;"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon
                                size="16"
                                color="primary"
                            >
                                mdi-link
                            </v-icon>
                        </span>
                    </template>
                    <div>
                        <p class="white--text mb-0 text-body-2">
                            Link to order:
                        </p>
                        <div class="d-flex">
                            <router-link
                                :to="{
                                    name: 'order-details',
                                    params: {
                                        id: data.linked_to_order
                                    }
                                }"
                                class="mb-0 mr-2 text-decoration-none white--text text-subtitle-2 d-flex align-center"
                            >
                                {{ data.linked_to_order }}
                            </router-link>
                            <copy-to-click-board
                                :copy-string="data.linked_to_order"
                                icon-color="white"
                                icon
                            />
                        </div>
                    </div>
                </v-tooltip>
            </div>
            <div
                v-click-outside="{
                    handler: ()=> $emit('orderID', null),
                    include: include
                }"
                class="show-details-tools"
                :class="{
                    'show-details-tools--open': hoveredItem === data.orderid || openedRow.includes(data.orderid) || openTools || orderId === data.orderid
                }"
            >
                <v-tooltip
                    top
                    open-on-click
                    :open-on-hover="false"
                    color="primary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            @blur="on.blur"
                            @click="OrderId(on, data.orderid, $event)"
                        >
                            mdi-barcode
                        </v-icon>
                    </template>
                    <div>
                        <p class="white--text mb-0 text-body-2">
                            Order ID
                        </p>
                        <div class="d-flex">
                            <p class="d-flex align-center  text-subtitle-2 font-weight-regular">
                                <copy-to-click-board
                                    :copy-string="data.orderid"
                                    icon-color="white"
                                />
                            </p>
                        </div>
                        <template v-if="data.batchid">
                            <p class="white--text mb-0 text-body-2">
                                Batch ID
                            </p>
                            <div class="d-flex">
                                <p
                                    class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                                    style="cursor: pointer"
                                    @click="showBatchPanel"
                                >
                                    {{ data.batchid }}
                                </p>
                                <copy-to-click-board
                                    class="ml-2"
                                    icon
                                    :copy-string="data.batchid"
                                    icon-color="white"
                                />
                            </div>
                        </template>
                    </div>
                </v-tooltip>
                <v-icon
                    color="primary"
                    @click="showDetails"
                >
                    mdi-open-in-new
                </v-icon>
                <div class="d-flex">
                    <v-icon
                        v-if="openedRow.includes(data.orderid)"
                        color="primary"
                        @click="openFullRow(data.orderid)"
                    >
                        mdi-chevron-up
                    </v-icon>
                    <v-icon
                        v-else
                        icon
                        color="primary"
                        @click="openFullRow(data.orderid)"
                    >
                        mdi-chevron-down
                    </v-icon>
                </div>
                <router-link
                    class="white--text tw-rounded tw-px-2 tw-py-1 hover:tw-bg-blue-700"
                    style="background-color: #1976d2; font-size: 12px"
                    :to="{
                        name: 'order-details',
                        params: { id: data.orderid },
                    }"
                >
                    view
                </router-link>
                <!-- END: order testimonias -->
                <table-rows-menu
                    :toolbars="getItemToolbars(data)"
                    :item="data"
                    list-type="orders"
                    :color="'primary'"
                    @menuActivated="menuActivated"
                    @hoverRow="onHoverRow"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu.vue';
import { ORDER_TOOLBARS } from '@/constants/Order';

export default {
    components: {
        TableRowsMenu,
        CopyToClickBoard
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        openedRow: {
            type: Array
        },
        index: {
            type: Number,
            required: false,
            default: 1
        },
        hoveredItem: {
            type: String,
            default: null
        },
        orderId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showID: false,
            openTools: false
        }
    },
    computed: {
        toolbars() {
            return ORDER_TOOLBARS
        }
    },
    methods: {
        showNotes() {
            this.$emit('showNotes', this.data.orderid)
        },
        showBatchPanel() {
            this.$emit('showBatches', this.data.batchid)
        },
        showTestimonials() {
            this.$emit('showTestimonials', this.data.orderid)
        },
        showBids() {
            this.$emit('showBids', this.data.orderid)
        },
        openSidebar(type) {
            if (type === 'preferred' && this.data.writer_preferrences.preferred?.length > 0) {
                this.$emit('openPreferredWriters')
            }
            if (type === 'blocked' && this.data.writer_preferrences.blocked?.length > 0) {
                this.$emit('openBlockedWriters')
            }
        },
        openFullRow(orderId) {
            this.$emit('openFullRow', orderId)
        },
        getItemToolbars(item) {
            // if order has no writer, remove items from the toolbar
            if (!item.writer) {
                return ORDER_TOOLBARS.filter((item) => item.name !== 'message' && item.name !== 'drop')
            }
            return ORDER_TOOLBARS
        },
        menuActivated(item, modal) {
            this.$emit('menuActivated', item, modal)
        },
        showDetails() {
            this.$emit('showDetails', this.data.orderid)
        },
        onHoverRow(val) {
            this.openTools = val
        },
        OrderId(on, id, e) {
            on.click(e)
            this.$emit('orderID', id)
        },
        include() {
            return [...document.querySelectorAll('.show-details-tools')]
        }
    }
}
</script>

<style lang="scss">
    .table_general{
        min-width: 300px;
        max-width: 320px !important;
    }
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .badge{
        white-space: nowrap;
        font-size: 12px;
        padding: 4px 8px;
        background: #D3D9E2;
        border-radius: 4px;
        &+& {
            margin-left: 12px;
        }
    }
    .order-title{
        max-width: 100%;
    }
    .show-details-tools{
        display: none;
    }
    .show-details-tools--open {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #e5f4ff;
        border-radius: 4px;
        padding: 2px;
    }
</style>
