<template>
    <v-container fluid>
        <v-row>
            <template>
                <select-tabs
                    v-model="activeTab"
                    :items="tabs"
                    :is-components="true"
                />
                <component
                    :is="activeTab"
                    :files-type="activeTab"
                    :orderid="order.orderid"
                    :order="order"
                    :client="{
                        id:order.clientid,
                        ordersCount:order.client_orders_count,
                        clientCountry:order.client_country,
                        firstname:order.client_firstname,
                        lastname:order.client_lastname,
                        avatar:order.client_avatar
                    }"
                    @createTicket="onCreateTicket"
                    @update:counters="updateCounters"
                    @uploadMainFiles="uploadMainFiles"
                />
                <!-- <v-tabs-items
                    v-model="activeTab"
                >
                </v-tabs-items> -->
                <!-- <component
                    :is="Details"
                /> -->
            </template>
        </v-row>
    </v-container>
</template>

<script>
import Details from '~/views/Order/Tabs/Details.vue'
import Notes from '~/views/Order/Tabs/Notes.vue'
import CommentsLog from '~/views/Order/Tabs/CommentsLog.vue'
import SupportClient from '~/views/Order/Tabs/SupportClient.vue'
import SupportWriter from '~/views/Order/Tabs/SupportWriter.vue'
import FilesMain from '~/views/Order/Tabs/FilesMain.vue'
import FilesAdd from '~/views/Order/Tabs/FilesAdd.vue'
import Feedback from '~/views/Order/Tabs/Feedback.vue'
import Activity from '~/views/Order/Tabs/Activity.vue'
import selectTabs from '@/components/shared/SelectTabs.vue';

export default {
    name: 'OrderDetailsTabs',
    components: {
        selectTabs,
        Details,
        Notes,
        CommentsLog,
        SupportClient,
        SupportWriter,
        FilesMain,
        FilesAdd,
        Feedback,
        Activity
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeTab: 'Details'
        }
    },
    computed: {
        tabs() {
            return [
                {
                    text: 'Details',
                    key: 'details',
                    // path: `/orders/${this.orderId}`,
                    total: null,
                    component: 'Details'
                },
                {
                    text: 'Notes',
                    key: 'notes',
                    // path: `/orders/${this.orderId}/notes`,
                    total: this.order.counters.notes_count,
                    component: 'Notes'
                },
                {
                    text: 'Messages',
                    key: 'comm_log',
                    // path: `/orders/${this.orderId}/comment-log`,
                    total: this.order.counters.messages_count,
                    component: 'CommentsLog'
                },
                {
                    text: 'Client Tickets',
                    key: 'client',
                    // path: `/orders/${this.orderId}/client-chat`,
                    total: this.order.counters.tickets_client_count,
                    component: 'SupportClient'
                },
                {
                    text: 'Writer Tickets',
                    key: 'writer',
                    // path: `/orders/${this.orderId}/writer-chat`,
                    total: this.order.counters.tickets_writer_count,
                    hidden: this.order.sw_id === null,
                    component: 'SupportWriter'
                },
                // {
                //     text: 'Log',
                //     key: 'logs',
                //     path: `/orders/${this.orderId}/logs`,
                //     total: this.order.activity_log_count
                // },
                {
                    text: 'Files',
                    key: 'files',
                    // path: `/orders/${this.orderId}/files`,
                    total: this.order.counters.files_main_count,
                    component: 'FilesMain'
                },
                {
                    text: 'Additional files',
                    key: 'files_add',
                    // path: `/orders/${this.orderId}/files-add`,
                    total: this.order.counters.files_add_count,
                    component: 'FilesAdd'
                },
                {
                    text: 'Testimonials',
                    key: 'feedback',
                    // path: `/orders/${this.orderId}/feedback`,
                    total: this.order.counters.testimonials_count,
                    component: 'Feedback'
                },
                // {
                //     text: 'Gateway',
                //     key: 'gateway',
                //     path: `/orders/${this.orderId}/gateway`
                // }
                {
                    text: 'Activity',
                    key: 'activity',
                    // path: `/orders/${this.orderId}/activity`,
                    total: this.order.activity.length || null,
                    component: 'Activity'
                }
            ]
        }
    },
    methods: {
        onCreateTicket(payload) {
            this.$emit('createTicket', payload)
        },
        updateCounters(e) {
            this.$emit('update:counters', e)
        },
        uploadMainFiles() {
            this.$emit('uploadMainFiles')
        }
    }
}
</script>

<style lang="scss">
    .v-tabs-items{
        width: 100%;
    }
    .tab-total {
        padding: 4px 10px;
        background: rgba(79, 79, 79, 0.1);
        border-radius: 4px;
        margin-left: 10px;
    }

    .v-tab {
        min-width: 180px;
    }
</style>
