<template>
    <v-data-table
        :headers="tableHeader"
        item-key="id"
        :items="getterBatchList"
        :single-select="true"
        :server-items-length="getterTotal"
        :loading="loading"
        hide-default-footer
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        must-sort
        @update:options="$emit('sortTable', $event)"
    >
        <template #header="{ }">
            <thead>
                <tr v-if="!mobile">
                    <th
                        v-for="h in tableHeader"
                        :key="h.value"
                    >
                        <template v-if="h.value === 'batchid'">
                            <div class="d-flex align-center">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Batches:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.total | number }}</b>
                                    </p>
                                </div>
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Pages:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.pages | number }}</b>
                                    </p>
                                </div>
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Total:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.totalFromCount | money }}</b>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </th>
                </tr>
                <tr v-else>
                    <th>
                        <div class="d-flex align-center">
                            <div class="mr-4">
                                <p class="mb-0 grey--text">
                                    Batches:
                                </p>
                                <p class="mb-0">
                                    <b>{{ getterSummary.total | number }}</b>
                                </p>
                            </div>
                            <div class="mr-4">
                                <p class="mb-0 grey--text">
                                    Pages:
                                </p>
                                <p class="mb-0">
                                    <b>{{ getterSummary.pages | number }}</b>
                                </p>
                            </div>
                            <div class="">
                                <p class="mb-0 grey--text">
                                    Total:
                                </p>
                                <p class="mb-0">
                                    <b>{{ getterSummary.totalFromCount | money }}</b>
                                </p>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
        </template>
        <template v-slot:body="{ items }">
            <table-skeleton
                v-if="loading && (items && items.length === 0)"
                :per-page="searchOptions.per_page"
                :column-count="tableHeaderLength"
            />
            <table-loader
                v-if="loading"
            />
            <tbody>
                <template
                    v-for="(item, index) in items"
                >
                    <tr
                        :key="item.batchid"
                        class="hovered-row"
                    >
                        <td>
                            <div class="">
                                <p>{{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}.</p>
                            </div>
                        </td>
                        <!-- created -->
                        <td class="td_dates">
                            <div class="d-flex align-center">
                                <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.shortText(item.created_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- GENERAL -->
                        <td>
                            <div
                                class="d-flex"
                                style="width: 150px"
                            >
                                <span class="mr-2">{{ item.batchid }}</span>
                                <copy-to-click-board
                                    :copy-string="item.batchid"
                                    icon
                                    @click.stop
                                />
                            </div>
                        </td>
                        <!-- domain -->
                        <td class="domain-batch">
                            <domain-favicon
                                :domain="item.client_domain"
                            />
                        </td>
                        <!-- Client -->
                        <td class="td_user">
                            <div class="d-flex align-end">
                                <div>
                                    <client-avatar
                                        v-if="item.client && item.client.id"
                                        :id="item.client.id"
                                        :is-client="true"
                                        :orders-count="item.client.orders_count"
                                        :client-country="item.client.country"
                                        :firstname="item.client.firstname"
                                        :lastname="item.client.lastname"
                                    />
                                    <p
                                        v-else
                                        class="text-center"
                                    >
                                        —
                                    </p>
                                </div>
                                <div class="d-flex align-center ml-4">
                                    <span v-if="item.client.firstname">{{ item.client.firstname }} {{ item.client.lastname }}</span>
                                    <span v-else>—</span>
                                </div>
                            </div>
                        </td>
                        <!-- status -->
                        <td>
                            <div>
                                <status-colored
                                    v-if="item.status"
                                    :type="item.status"
                                />
                            </div>
                        </td>
                        <!-- Order count -->
                        <td>
                            <div class="order_table--max-width text-center">
                                <v-avatar
                                    color="primary"
                                    size="30"
                                >
                                    <b class="white--text">{{ item.counters.orders_count }}</b>
                                </v-avatar>
                            </div>
                        </td>
                        <!-- Payment method -->
                        <td class="extras-row_col">
                            <p class="mb-0 text-body-2">
                                {{ getPaymethod(item) || '—' }}
                            </p>
                        </td>
                        <td class="extras-row_col">
                            <div
                                v-if="item.paymentid"
                                class="d-flex"
                                style="width: 125px"
                            >
                                <v-tooltip
                                    top
                                    style="margin-right: 5px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            class="d-inline-block tw-truncate"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ item.paymentid }};
                                        </div>
                                    </template>
                                    <div>{{ item.paymentid }}</div>
                                </v-tooltip>
                                <copy-to-click-board
                                    :copy-string="item.paymentid"
                                    icon
                                    style="margin-left: 5px"
                                    @click.stop
                                />
                            </div>
                            <span
                                v-else
                                class="mr-2"
                            >
                                —
                            </span>
                        </td>
                        <!-- Store credit -->
                        <td>
                            <div
                                v-if="item.store_credit !== '0.00'"
                                class="order_table--max-width"
                            >
                                <b> {{ item.store_credit | money }}  </b>
                            </div>
                            <div
                                v-else
                            >
                                -
                            </div>
                        </td>
                        <!-- Total -->
                        <td>
                            <div class="d-flex justify-end">
                                <b> {{ item.total | money }} </b>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-center">
                                <v-icon
                                    v-if="open === index"
                                    @click="openTable(index)"
                                >
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon
                                    v-else
                                    @click="openTable(index)"
                                >
                                    mdi-chevron-down
                                </v-icon>
                                <router-link
                                    :to="{
                                        name: 'batch-details',
                                        params: { id: item.batchid },
                                    }"
                                >
                                    <v-btn
                                        color="grey-lighten-3 ml-4"
                                    >
                                        details
                                    </v-btn>
                                </router-link>
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-show="open === index"
                        :key="item.index"
                    >
                        <td
                            :colspan="tableHeaderLength - 1"
                            class="batch-orders"
                        >
                            <template v-for="(order, orderIndex) in item.orders">
                                <batch-order
                                    :key="order.orderid"
                                    class="my-2"
                                    :class="{'mb-0': orderIndex === item.orders.length - 1}"
                                    :order="order"
                                    :index="orderIndex"
                                />
                            </template>
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/mixins/filtersMixin'
import BatchOrder from '@/components/Batch/BatchOrder'

import StatusColored from '@/components/shared/Helpers/StatusColored'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import InformationTooltip from '@/components/shared/InformationTooltip.vue';

import {
    BATCH_TABLE_HEADER
} from '@/constants/Batch'

export default {
    name: 'Batch',
    components: {
        InformationTooltip,
        StatusColored,
        DomainFavicon,
        ClientAvatar,
        CopyToClickBoard,
        TableSkeleton,
        BatchOrder,
        TableLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        searchOptions: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        sortBy: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            open: false,
            mobile: false
        }
    },
    computed: {
        ...mapGetters('batch', [
            'getterBatchList',
            'getterTotal',
            'getterSummary'
        ]),
        tableHeader() {
            return BATCH_TABLE_HEADER
        },
        tableHeaderLength() {
            return BATCH_TABLE_HEADER.length + 1
        }
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        openTable(index) {
            // this.getBatch(id)
            this.open === index
                ? this.open = false
                : this.open = index
        },
        getPaymethod(batch) {
            if (batch.paymethod === 'paypal') {
                return 'Paypal';
            } if (batch.paymethod === 'cc') {
                return 'Credit card';
            } if (batch.paymethod === 'stripe') {
                return 'Stripe';
            } if (batch.paymethod === null && batch.paymentid === 'store_credit') {
                return 'Store credit';
            }
            return batch.paymethod
        }
    }
}
</script>

<style lang="scss">
.payroll-page .v-data-table__wrapper {
    overflow: visible!important;
}

.domain-batch .domain {
  justify-content: normal;
}

.batch-orders{
    background-color: #E3F2FD;
    border-color: #E3F2FD;
}

</style>
