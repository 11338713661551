<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Edit feedback</span>
        </template>
        <template #body>
            <v-form>
                <v-row>
                    <template
                        v-for="(value, field) in formdata"
                    >
                        <v-col
                            v-if="field === 'details'"
                            :key="field"
                            cols="12"
                            class="order-last"
                        >
                            <v-textarea
                                :id="field"
                                v-model="formdata[field]"
                                outlined
                                hide-details
                                :name="field"
                                :label="field"
                            />
                        </v-col>
                        <v-col
                            v-else
                            :key="field"
                            cols="12"
                        >
                            <v-select
                                v-if="field === 'status'"
                                :id="field"
                                v-model="formdata[field]"
                                :items="feedbackStatuses"
                                outlined
                                hide-details
                                :name="field"
                                :label="field"
                            />
                            <v-select
                                v-else-if="field === 'communication' || field === 'content' || field === 'delivery' || field === 'support' || field === 'website'"
                                :id="field"
                                v-model="formdata[field]"
                                :items="fiveDigitScale"
                                outlined
                                hide-details
                                :name="field"
                                :label="field"
                            />
                            <v-select
                                v-else-if="field === 'rate_company' || field === 'rate_writer'"
                                :id="field"
                                v-model="formdata[field]"
                                :items="tenDigitScale"
                                outlined
                                hide-details
                                :name="field"
                                :label="field"
                            />
                            <v-text-field
                                v-else
                                :id="field"
                                v-model="formdata[field]"
                                outlined
                                hide-details
                                :name="field"
                                :label="field"
                            />
                        </v-col>
                    </template>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>

import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'ModalFeedbackEdit',
    components: {
        Modal
    },
    props: {
        feedback: {
            type: Object,
            required: true
        },
        feedbackStatuses: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            formdata: {
                status: '',
                communication: '',
                communication_details: '',
                content: '',
                content_details: '',
                delivery: '',
                delivery_details: '',
                details: '',
                rate_company: '',
                recommend_company: '',
                // rate_competition: '',
                rate_writer: '',
                recommend_writer: '',
                support: '',
                support_details: '',
                website: '',
                website_details: ''
            }
        }
    },
    computed: {
        fiveDigitScale() {
            return [
                {
                    text: '5',
                    value: '5.00'
                },
                {
                    text: '4',
                    value: '4.00'
                },
                {
                    text: '3',
                    value: '3.00'
                },
                {
                    text: '2',
                    value: '2.00'
                },
                {
                    text: '1',
                    value: '1.00'
                }
            ]
        },
        tenDigitScale() {
            return [
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '9',
                    value: 9
                },
                {
                    text: '8',
                    value: 8
                },
                {
                    text: '7',
                    value: 7
                },
                {
                    text: '6',
                    value: 6
                },
                {
                    text: '5',
                    value: 5
                },
                {
                    text: '4',
                    value: 4
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '1',
                    value: 1
                }
            ]
        }
    },
    created() {
        this.formdata = this.merge_options(this.formdata, {
            ...this.feedback, ...this.feedback.parsed_testimonial_new, ...this.feedback.parsed_client, ...this.feedback.parsed_writer
        })
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', { ...this.formdata })
        },
        merge_options(obj1, obj2) {
            const copy = { ...obj1 }
            return Object.entries(copy).reduce((acc, curr) => {
                if (typeof curr[1] === 'boolean') {
                    acc[curr[0]] = Boolean(obj2[curr[0]])
                    return acc
                }
                acc[curr[0]] = obj2[curr[0]] || ''
                return acc
            }, {})
        }
    }
}
</script>

<style>

</style>
