<template>
    <v-container fluid>
        <div class="py-5">
            <!-- Activity: START -->
            <template v-for="(item, index) in order.activity">
                <div
                    :key="index"
                    class="activity-row lighten-5"
                    :class="item.description === 'drop' ? 'red' : ''"
                >
                    <!-- row title -->
                    <div class="d-flex align-center mr-4 ml-4">
                        <div class="d-flex align-center">
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                {{ index + 1 }}
                            </span>
                        </div>
                    </div>
                    <!-- Description -->
                    <div class="activity-row_col-min d-flex align-center mr-4">
                        <div>
                            <v-chip
                                :color="item.description === 'bid_deleted' ? '#FF00001A' : 'rgba(39, 217, 31, 0.1)'"
                                :text-color="item.description === 'bid_deleted' ? 'error' : 'success'"
                                class="text-uppercase"
                            >
                                {{ item.description.replace('_', ' ') }}
                            </v-chip>
                        </div>
                    </div>
                    <!-- Author -->
                    <div class="extras-row_col d-flex align-center">
                        <div>
                            <avatar-rolles
                                :access-status="item.causer_type"
                                :firstname="item.causer.firstname"
                                :lastname="item.causer.lastname"
                                :avatar-url="item.causer.avatar_url"
                            />
                        </div>
                        <div class="ml-2">
                            <div>
                                <div>{{ item.causer.firstname }} {{ item.causer.lastname }}</div>
                                <div>{{ item.causer_type }} ID: {{ item.causer_id }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- Created date -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Created at:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ item.created_at && $date.humanizeDate(item.created_at) }}
                            </p>
                        </div>
                    </div>
                    <!-- Order ID-->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Order ID:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ item.properties.orderid }}
                            </p>
                        </div>
                    </div>
                    <!-- Total -->
                    <div
                        v-if="item.properties.total"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Total:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ item.properties.total | money }}
                            </p>
                        </div>
                    </div>
                    <!-- Due -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Due:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ $date.humanizeDate(item.properties.due_at) }}
                            </p>
                        </div>
                    </div>
                    <!-- Bid ID -->
                    <div
                        v-if="item.properties.bid_id"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Bid ID:
                            </p>
                            <p class="mb-0 text-body-2">
                                {{ item.properties.bid_id }}
                            </p>
                        </div>
                    </div>
                    <!-- <div
                        v-for="(value, key) in item.properties"
                        :key="key"
                        class="extras-row_col"
                    >
                        <div class="flex">
                            <span class="grey--text">
                                {{ key }}:
                            </span>
                            <p class="mb-0 text-body-2">
                                {{ value }}
                            </p>
                        </div>
                    </div> -->
                    <!-- Drop reason -->
                    <div
                        v-if="item.description === 'drop'"
                        class="extras-row_col"
                    >
                        <div class="flex">
                            <span class="grey--text">
                                Drop reason:
                            </span>
                            <p class="mb-0 text-body-2">
                                {{ item.properties.details }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <!-- Activity: END -->
        </div>
        <div
            v-if="!order.activity.length"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No activity found
            </p>
        </div>
    </v-container>
</template>

<script>

import filtersMixin from '@/mixins/filtersMixin'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    name: 'Activity',
    components: {
        AvatarRolles
    },
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
.activity-row{
    display: flex;
    margin: 0 0 10px;
    background: #FAFAFA;
    border-radius: 6px;
    @media all and (max-width: 1700px) {
        flex-wrap: wrap;
    }
    &_col{
        width: calc(12% - 10px);
        margin: 0 10px;
        @media all and (max-width: 1700px){
            width: calc(20% - 10px);
            margin: 10px;
        }
        &-min {
            min-width: 85px;
        }
    }
}
</style>
