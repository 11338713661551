<template>
    <div class="extras-wrapper">
        <!-- Communication -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="d-flex align-center">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Communication
                    </span>
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.delivery" />
                    </p>
                </div>
            </div>
            <!-- Communication -->
            <!-- <div class="extras-row_col">
                <div class="">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.delivery" />
                    </p>
                </div>
            </div> -->
            <!-- Communication details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="grey--text mb-0 text-body-2">
                        Communication details:
                    </p>
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.communication_details || '—' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Content -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="d-flex align-center">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Content
                    </span>
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.content" />
                    </p>
                </div>
            </div>
            <!-- content -->
            <!-- <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.content" />
                    </p>
                </div>
            </div> -->
            <!-- content details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="grey--text mb-0 text-body-2">
                        Content details:
                    </p>
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.content_details || '—' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Delivery -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="d-flex align-center">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Delivery
                    </span>
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.delivery" />
                    </p>
                </div>
            </div>
            <!-- delivery -->
            <!-- <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.delivery" />
                    </p>
                </div>
            </div> -->
            <!-- delivery details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="grey--text mb-0 text-body-2">
                        Delivery details:
                    </p>
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.delivery_details || '—' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Support -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="d-flex align-center">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Support
                    </span>
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.support" />
                    </p>
                </div>
            </div>
            <!-- support -->
            <!-- <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.support" />
                    </p>
                </div>
            </div> -->
            <!-- support details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="grey--text mb-0 text-body-2">
                        Support details:
                    </p>
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.support_details || '—' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Website -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="d-flex align-center">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Website
                    </span>
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.website" />
                    </p>
                </div>
            </div>
            <!-- website -->
            <!-- <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_new.website" />
                    </p>
                </div>
            </div> -->
            <!-- website details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="grey--text mb-0 text-body-2">
                        Website details:
                    </p>
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.website_details || '—' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Details -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Details
                    </span>
                </div>
            </div>
            <!-- details details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_new.details }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip'

export default {
    components: {
        MarksChip
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>
