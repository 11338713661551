<template>
    <setting-layout title="Change email">
        <template #form>
            <v-form
                class="px-5 pb-5"
                @submit.prevent="changeEmail"
            >
                <v-text-field
                    id="firstname"
                    v-model="email"
                    outlined
                    label="Enter new email"
                />
                <v-btn
                    color="accent"
                    width="100%"
                    height="56px"
                    type="submit"
                >
                    Submit
                </v-btn>
            </v-form>
        </template>
    </setting-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import SettingLayout from '@/layout/SettingLayout'

import { eventBus } from '@/helpers/event-bus';

import {
    ACTION_UPDATE_SELF
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingLayout
    },
    data() {
        return {
            email: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ])
    },
    metaInfo: {
        title: 'Change email'
    },
    created() {
        this.email = this.getterMainInfo.email
    },
    methods: {
        ...mapAdminActions({
            ACTION_UPDATE_SELF
        }),
        async changeEmail() {
            try {
                await this[ACTION_UPDATE_SELF]({ email: this.email })
                eventBus.$emit('showSnackBar', 'success', 'success')
                this.$router.push({ name: 'user_setting' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style>

</style>
