<template>
    <div>
        <v-data-table
            dense
            :headers="creditsHeader"
            :items="items"
            sortable="false "
            item-key="id"
            :server-items-length="total"
            must-sort
            class="elevation-1"
            hide-default-footer
            :sort-by="searchOptions.sort_by"
            :sort-desc="sortDesc"
            @update:options="$emit('updateOptions', $event)"
        >
            <template #header="{ }">
                <thead
                    v-if="!mobile"
                    class="v-data-table-header"
                >
                    <tr>
                        <th
                            v-for="h in creditsHeaderTotal"
                            :key="h.value"
                        >
                            <template v-if="h.value === 'credit'">
                                <div class="d-flex align-center justify-end py-2">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Credit:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="totalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ totalCredits | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template v-if="h.value === 'debit'">
                                <div class="d-flex align-center justify-end">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Debit:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="totalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ totalDebits | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </th>
                    </tr>
                </thead>
                <thead v-else>
                    <tr>
                        <th class="d-flex">
                            <div class="d-flex align-center justify-end py-2">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Credit:
                                    </p>
                                    <p class="mb-0">
                                        <v-progress-circular
                                            v-if="totalsLoading"
                                            ref="loader"
                                            :size="14"
                                            color="primary"
                                            indeterminate
                                        />
                                        <b v-else>{{ totalCredits | money }}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-center justify-end">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Debit:
                                    </p>
                                    <p class="mb-0">
                                        <v-progress-circular
                                            v-if="totalsLoading"
                                            ref="loader"
                                            :size="14"
                                            color="primary"
                                            indeterminate
                                        />
                                        <b v-else>{{ totalDebits | money }}</b>
                                    </p>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ }">
                <table-skeleton
                    v-if="listLoading && items.length === 0"
                    :per-page="searchOptions.per_page"
                    :column-count="creditsHeader.length"
                />
                <table-loader
                    v-if="listLoading"
                />
                <tbody>
                    <template v-for="(item, index) in items">
                        <tr
                            :key="index"
                            :class="rowClasses(item)"
                        >
                            <td>{{ $date.humanizeDate(item.created_at) }}</td>
                            <td>
                                <div
                                    v-if="item.owner"
                                    class="d-flex align-center"
                                >
                                    <div>
                                        <avatar-rolles
                                            access-status="admin"
                                            :firstname="item.owner.firstname"
                                            :lastname="item.owner.lastname"
                                            :avatar-url="item.owner.avatar_url"
                                        />
                                    </div>
                                    <div class="ml-2">
                                        <div>
                                            <div>{{ item.owner.firstname }} {{ item.owner.lastname }}</div>
                                            <div>Admin ID: {{ item.owner.id }}</div>
                                        </div>
                                    </div>
                                </div>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td>
                                <writer-avatar
                                    v-if="item.writer"
                                    :id="item.writer.sw_id"
                                    :feedbacks="item.writer.statistics.rank_all_count"
                                    :feedbacks_thirty_days_count="item.writer.statistics.rank_thirty_days_count"
                                    :rating="item.writer.statistics.rank_all"
                                    :rating_thirty_days="item.writer.statistics.rank_thirty_days"
                                    :status="item.writer.trust_status"
                                    :country="item.writer.country"
                                    :firstname="item.writer.firstname"
                                    :lastname="item.writer.lastname"
                                    :avatar-fallback="item.writer.avatar_fallback"
                                    :avatar-url="item.writer.avatar_url"
                                />
                                <client-avatar
                                    v-if="item.client"
                                    :id="item.client.id"
                                    :firstname="item.client.firstname"
                                    :lastname="item.client.lastname"
                                    :orders-count="item.client.orders_total"
                                    :client-country="item.client.country"
                                />
                            </td>
                            <td>
                                <!-- reference -->
                                <div class="">
                                    <div style="width: 300px">
                                        {{ item.reference }}
                                        <!-- order id and ticket id -->
                                        <v-tooltip
                                            top
                                            open-on-click
                                            :open-on-hover="false"
                                            color="primary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    color="primary"
                                                    class="ml-2"
                                                    v-bind="attrs"
                                                    @click="on.click"
                                                    @blur="on.blur"
                                                >
                                                    mdi-barcode
                                                </v-icon>
                                            </template>
                                            <div>
                                                <template v-if="item.orderid">
                                                    <p class="white--text mb-0 text-body-2">
                                                        Order ID
                                                    </p>
                                                    <div class="d-flex">
                                                        <p class="mb-2 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                            <copy-to-click-board
                                                                :copy-string="item.orderid"
                                                                icon-color="white"
                                                            />
                                                        </p>
                                                    </div>
                                                </template>
                                                <template v-if="item.ticketid">
                                                    <br>
                                                    <p class="white--text mb-0 text-body-2">
                                                        Ticket ID
                                                    </p>
                                                    <div class="d-flex">
                                                        <p
                                                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                                                            style="cursor: pointer"
                                                        >
                                                            {{ item.ticketid }}
                                                        </p>
                                                        <copy-to-click-board
                                                            class="ml-2"
                                                            icon
                                                            :copy-string="item.ticketid"
                                                            icon-color="white"
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </td>
                            <td v-if="!isWriter">
                                <div class="flex flex-column">
                                    <div
                                        class="text-decoration-none black--text pa-1 tw-cursor-pointer"
                                        @click="goToPage(item.orderid)"
                                    >
                                        {{ item.orderid }}
                                    </div>
                                    <div
                                        class="text-decoration-none black--text pa-1 tw-cursor-pointer"
                                        @click="goToPage(item.ticketid )"
                                    >
                                        {{ item.ticketid }}
                                    </div>
                                </div>
                            </td>
                            <td v-if="isWriter">
                                {{ item.payout_id || '-' }}
                            </td>
                            <td class="debit text-right font-weight-bold">
                                <span v-if="item.debit > 0">
                                    {{ item.debit | money }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td class="credit text-right font-weight-bold">
                                <span v-if="item.credit > 0">
                                    {{ item.credit | money }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td
                                v-if="isDetails"
                                class="balance text-right font-weight-bold"
                            >
                                <span v-if="item.balance > 0">
                                    {{ item.balance | money }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!items.length && !listLoading"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No data found
            </p>
        </div>
        <pagination-with-perpage
            :list-lenght="items.length"
            :last-page="lastPage"
            :search-options="searchOptions"
            :total-visible="7"
            @updatePage="$emit('updatePage', $event)"
            @updatePerpage="$emit('updatePerpage', $event)"
        />
    </div>
</template>
<script>
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import filtersMixin from '@/mixins/filtersMixin';

import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'

export default {
    components: {
        PaginationWithPerpage,
        CopyToClickBoard,
        TableSkeleton,
        TableLoader,
        AvatarRolles,
        WriterAvatar,
        ClientAvatar
    },
    mixins: [filtersMixin],
    props: {
        items: {
            type: Array,
            required: true
        },
        lastPage: {
            type: [Number, String],
            required: true
        },
        total: {
            type: [Number, String],
            required: true
        },
        totalCredits: {
            type: [Number, String],
            required: false
        },
        totalDebits: {
            type: [Number, String],
            required: false
        },
        searchOptions: {
            type: Object,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        listLoading: {
            type: Boolean,
            required: false
        },
        totalsLoading: {
            type: Boolean,
            required: false
        },
        isDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        isWriter: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            mobile: false
        }
    },
    computed: {
        creditsHeaderTotal() {
            return [
                {
                    text: ''
                },
                {
                    text: ''
                },
                {
                    text: ''
                },
                {
                    text: ''
                },
                {
                    text: 'Debit',
                    sortable: false,
                    value: 'debit',
                    width: '100px',
                    align: 'center'
                },
                {
                    text: 'Credit',
                    sortable: false,
                    value: 'credit',
                    align: 'center'
                }
            ]
        },
        creditsHeader() {
            return [
                {
                    text: 'Date',
                    value: 'created_at',
                    sortable: true
                },
                {
                    text: 'Owner',
                    value: 'owner',
                    sortable: false
                },
                {
                    text: this.isWriter ? 'Writer' : 'Client',
                    value: 'owner',
                    sortable: false
                },
                {
                    text: 'Reference',
                    value: 'reference',
                    sortable: false
                },
                {
                    text: 'Debit',
                    value: 'debit',
                    sortable: true,
                    width: '100px',
                    align: 'end'
                },
                {
                    text: 'Credit',
                    value: 'credit',
                    sortable: true,
                    align: 'end'
                }
            ]
        }
    },
    created() {
        if (this.isDetails) {
            this.creditsHeader.push({
                text: 'Balance',
                value: 'balance',
                sortable: false,
                align: 'end'
            })
            this.creditsHeaderTotal.push({
                text: ''
            })
        }
        if (this.isWriter) {
            this.creditsHeader.splice(4, 0, {
                text: 'Payout ID',
                value: 'payout_id',
                sortable: false,
                align: 'start'
            })
            this.creditsHeaderTotal.splice(4, 0, {
                text: ''
            })
        }
        if (!this.isWriter) {
            this.creditsHeader.splice(4, 0, {
                text: 'Order | Ticket',
                value: '',
                sortable: false
            })
            this.creditsHeaderTotal.splice(4, 0, {
                text: ''
            })
        }
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        rowClasses(item) {
            return {
                'table-row--inactive': !!item.payout_id
            }
        },
        goToPage(id) {
            const isOrderId = id.includes('S-')
            const isBatch = id.includes('BA-')
            const isClientTicket = id.includes('TC-')
            if (isOrderId) {
                this.$router.push({
                    name: 'order-details',
                    params: {
                        id
                    }
                })
            }
            if (isBatch) {
                this.$router.push({
                    name: 'batch-details',
                    params: {
                        id
                    }
                })
            }
            if (isClientTicket) {
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id,
                        type: 'client'
                    }
                })
            }
        }
    }
}
</script>

    <style lang="scss" scoped>
        .credit {
            color: green;
        }
        .debit {
            color: red;
        }
        .balance {
            font-weight: 700;
        }
    </style>
