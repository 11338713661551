export const TABLE_HEADER = [
    {
        text: 'Bid updated',
        value: 'updated_at',
        sortable: true,
        width: '130px'
    },
    {
        text: 'Writer',
        value: 'author.firstname',
        sortable: true,
        width: '200px'
    },
    {
        text: 'Limit',
        value: 'author.projects_limit',
        sortable: true,
        align: 'left'
    },
    {
        text: 'In Progress',
        value: 'author_statistics.orders_in_progress',
        sortable: true,
        align: 'left'
    },
    {
        text: 'In Revision',
        value: 'author_statistics.orders_in_revision',
        sortable: true,
        align: 'left'
    },
    {
        text: 'Reviews',
        value: 'author_statistics.reviews',
        sortable: true,
        width: '100px',
        align: 'left'
    },
    {
        text: 'Rating',
        value: 'author_statistics.rank',
        sortable: true
    },
    {
        text: 'Promised date',
        value: 'due_at',
        sortable: true,
        width: '140px'
    },
    {
        text: 'Bid total',
        value: 'amount',
        sortable: true,
        align: 'right'
    },
    {
        text: '',
        value: '',
        sortable: false
    }
]
