<template>
    <v-chip
        v-if="status"
        :color="statusColor.color"
    >
        <span :class="`${statusColor.text}--text`">{{ status }}</span>
    </v-chip>
</template>
<script>
export default {
    name: 'StatusChip',
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        statusColor() {
            switch (this.status) {
            case 'OPEN': {
                return {
                    text: 'primary',
                    color: '#1976D21A'
                }
            }
            case 'ACTIVE': {
                return {
                    text: 'success',
                    color: 'rgba(39, 217, 31, 0.1)'
                }
            }
            case 'CLOSED': {
                return {
                    text: 'error',
                    color: '#FF00001A'
                }
            }
            default: {
                return {}
            }
            }
        }
    }
}
</script>
