<template>
    <v-main class="fill-height">
        <v-container class="fill-height">
            <v-row justify="center">
                <v-card
                    outlined
                    elevation="2"
                    :width="cardWidth"
                >
                    <div class="d-flex flex-column align-center py-5">
                        <div
                            class="col-12 text-subtitle-1 pb-4 pt-0 d-flex align-center"
                        >
                            <v-btn
                                icon
                                link
                                :to="{name: 'user_setting'}"
                            >
                                <v-icon>
                                    mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <div class="mr-auto ml-auto">
                                {{ title }}
                            </div>
                        </div>
                        <v-divider width="100%" />
                    </div>

                    <slot name="form" />
                </v-card>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Setting'
        },
        cardWidth: {
            type: String,
            default: '420px'
        }
    }
}
</script>

<style>

</style>
