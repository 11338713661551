<template>
    <div>
        <div class="d-flex flex-column mb-3">
            <div class="d-flex align-center text-subtitle-2 text-capitalize mb-1">
                <span class="grey--text pr-1">{{ index + 1 }}.</span>
                <span class="font-weight-bold">
                    {{ order.type }}
                </span>
            </div>
            <div class="">
                «{{ order.title }}»
            </div>
        </div>
        <div
            class="d-flex align-center pb-3"
        >
            <div class="mr-15 order-info">
                <p class="grey--text mb-0">
                    Order ID:
                </p>
                <router-link
                    :to="{path: `/orders/${order.orderid}`}"
                    class="mb-0 d-flex align-center text-body-2 black--text text-decoration-none"
                >
                    <v-icon
                        color="primary"
                        class="mr-1"
                    >
                        mdi-barcode
                    </v-icon>
                    {{ order.orderid }}
                </router-link>
            </div>
            <div class="mr-15 order-info">
                <p class="grey--text mb-0">
                    Discount rate:
                </p>
                <p class="mb-0 d-flex align-center text-body-2">
                    <v-icon
                        class="mr-2"
                        color="primary"
                    >
                        mdi-percent-outline
                    </v-icon>
                    {{ order.discount_rate | percent }}
                </p>
            </div>
            <div class="mr-15 order-info">
                <p class="grey--text mb-0">
                    Total:
                </p>
                <p class="mb-0 d-flex align-center text-body-2">
                    <v-icon
                        class="mr-2"
                        color="primary"
                    >
                        mdi-credit-card-outline
                    </v-icon>
                    ${{ order.total }}
                </p>
            </div>
        </div>
        <v-divider />
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'
import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'

export default {
    name: 'BatchOrders',
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor
    }
}
</script>

<style lang="scss">
    .batch-orders{
        & > div {
            &:last-child {
                .v-divider {
                    display: none;
                }
            }
        }
    }
</style>
