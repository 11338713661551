import Api from '@/helpers/api/index.js'

export const getBids = async (payload) => {
    const { data } = await Api.get('/api/order-bid/list', payload)
    return data
}

export const getPreferred = async (payload) => {
    if (payload?.writers?.length) {
        const { data } = await Api.get('/api/writer/short-list-by-ids', payload)
        return data
    }
    return null
}

export const deleteBid = async (payload) => {
    const { data } = await Api.delete('/api/order-bid/delete', payload)
    return data
}

export const assignBid = async (payload) => {
    const { data } = await Api.put('/api/order-bid/assign', payload)
    return data
}
