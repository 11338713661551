<template>
    <div>
        <v-expand-transition>
            <div v-show="isShow">
                <!-- Finance -->
                <div class="extras-row green lighten-5">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-contacts-outline
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Contacts
                            </span>
                        </div>
                    </div>
                    <!-- Payment method -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Phone:
                            </p>
                            <p
                                v-if="data.phone_code"
                                class="mb-0 text-body-2"
                            >
                                {{ data.phone_code }} {{ data.phone }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>

                    <!-- ordered services -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Email:
                            </span>
                            <p
                                v-if="data.email"
                                class="mb-0 text-body-2"
                            >
                                {{ data.email }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Orders count -->
                <div
                    v-if="getOrderCount"
                    class="extras-row orange lighten-5"
                >
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-cart-outline
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Orders
                            </span>
                        </div>
                    </div>
                    <!-- - -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                All:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.all_count }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Completed:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.completed_count }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                In progress:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.in_progress_count }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Overdue:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.overdue_count }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Refunded:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.refunded_count }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Revision:
                            </p>
                            <p
                                class="mb-0 text-body-2"
                            >
                                {{ getOrderCount.revision_count }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: false,
            default: 1
        },
        isShow: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        getOrderCount() {
            return this.data.statistics_parsed?.orders
        }
    }
};
</script>

<style lang="scss">
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
