<template>
    <v-container
        fluid
        class="white px-5"
    >
        <v-btn
            color="primary"
            class=" mb-5"
            @click="createNoteModal"
        >
            New note
        </v-btn>
        <v-row>
            <v-col
                cols="12"
            >
                <div
                    v-for="(item, index) in getterNotes"
                    :key="index"
                >
                    <div
                        v-if="item.admin"
                        class="text-subtitle-2 text-capitalize pl-13"
                    >
                        {{ item.admin.firstname }}
                        {{ item.admin.lastname }},
                        <span class="text-body-1 grey--text">
                            <!-- role: {{ item.admin.role }}, -->
                            id: {{ item.admin.id }}
                        </span>
                    </div>
                    <div class="d-flex flex-grow-1">
                        <avatar-rolles
                            v-if="item.admin"
                            access-status="ADMIN"
                            :firstname="item.admin.firstname"
                            :lastname="item.admin.lastname"
                            :avatar-url="item.admin.avatar_url"
                            :avatar-fallback="item.admin.avatar_fallback"
                        />
                        <div class="d-flex flex-column ml-2 flex-grow-1">
                            <div
                                class="notes-detail pa-5"
                            >
                                <div>
                                    {{ item.details }}
                                </div>
                                <div class="action_block">
                                    <v-tooltip
                                        v-for="toolbar in toolbars"
                                        :key="toolbar.name"
                                        top
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                dark
                                                tile
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="actionRow(item, {modal: toolbar.modal, actionName: toolbar.name})"
                                            >
                                                <v-icon>
                                                    {{ toolbar.icon }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ toolbar.text }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <p class="grey--text mt-2 mb-10">
                                {{ $date.dateFromNow(item.ts_issued) }},
                                {{ $date.humanizeDate(item.ts_issued) }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    v-if="getterNotes && !getterNotes.length"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No data found
                    </p>
                </div>
            </v-col>
        </v-row>
        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :loading="loadingInModal"
            :input-label="modal.inputLabel"
            :detail-redact="currentItem.details"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_DELETE_NOTE,
    ACTION_EDIT_NOTE,
    ACTION_GET_NOTES,
    ACTION_CREATE_NOTE
} from '@/store/modules/orders/action-types';

import { WRITERS_NOTE_TOOLBARS } from '@/constants/Writers'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('orders');

export default {
    components: {
        ModalAlert,
        ModalTextarea,
        AvatarRolles
    },
    props: {
        orderid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modal: {},
            actionName: '',
            currentItem: {},
            loadingInModal: false
        }
    },
    computed: {
        ...mapGetters('orders', [
            'getterNotes'
        ]),
        toolbars() {
            return WRITERS_NOTE_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Notes | Order`
        }
    },
    async activated() {
        await this.getNotes()
    },
    async created() {
        if (this.$route.name === 'orders') {
            await this.getNotes()
        }
        if (this.$route.name === 'hr-clients-details') {
            await this.getNotes()
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_NOTES,
            ACTION_DELETE_NOTE,
            ACTION_EDIT_NOTE,
            ACTION_CREATE_NOTE
        }),
        async getNotes() {
            await this[ACTION_GET_NOTES]({ orderid: this.orderid })
        },
        actionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'delete_note':
                    await this.deleteNote(this.currentItem.id)
                    break
                case 'edit_note':
                    await this.editNote(payload)
                    break
                case 'note_create':
                    await this.createNote(payload)
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        async createNote(payload) {
            await this[ACTION_CREATE_NOTE]({ ...payload, orderid: this.orderid })
            this.$emit('update:counters', { type: 'notes', counter: 1 })
        },
        async deleteNote(payload) {
            await this[ACTION_DELETE_NOTE](payload)
            this.$emit('update:counters', { type: 'notes', counter: -1 })
        },
        async editNote(payload) {
            const { id } = this.currentItem
            await this[ACTION_EDIT_NOTE]({ ...payload, id })
        },
        createNoteModal() {
            this.actionName = 'note_create'
            this.modal = {
                name: 'ModalTextarea',
                title: 'Post a note',
                description: 'Post a note for the order',
                inputLabel: 'Details',
                success_message: 'The note has been posted'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notes-detail{
    position: relative;
    background: #F4F8FD;
    border: 1px solid #EFEFEF;
    .action_block{
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: initial;
        left: initial;
        transform: initial;
        opacity: 0;
        .resolved-btn{
            display: flex;
            align-items: center;
            font-size: 12px;
        }
    }
    &:hover{
        .action_block{
            opacity: 1;
        }
    }
}

</style>
