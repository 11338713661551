<template>
    <v-container
        fluid
        class="white px-10 py-10"
    >
        <v-row>
            <v-col
                cols="12"
            >
                <template
                    v-for="(feedback, index) in getterFeedbacksList"
                >
                    <feedback-card
                        :ref="`card-${feedback.id}`"
                        :key="index"
                        :feedback="feedback"
                        @confirmModal="onConfirmModal"
                    />
                </template>

                <pagination-with-perpage
                    :list-lenght="getterFeedbacksList.length"
                    :last-page="getterLastPage"
                    :search-options="searchOptions"
                    :total-visible="7"
                    @updatePage="paginationPageEvent"
                    @updatePerpage="onUpdatePerpage"
                />
                <div
                    v-if="!getterFeedbacksList.length"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No data found
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST,
    ACTION_SET_STATUS_FEEDBACK,
    ACTION_GET_FEEDBACKS_TOTALS
} from '@/store/modules/feedbacks/action-types';
import FeedbackCard from '@/components/shared/Cards/FeedbackCard';
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('feedbacks');

export default {
    name: 'OrderFeedback',
    components: {
        FeedbackCard,
        PaginationWithPerpage
    },
    props: {
        orderid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            searchOptions: {
                search_by: 'orderid',
                search_for: this.orderid,
                page: 1,
                per_page: 10
            }
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Feedback | Order`
        }
    },
    computed: {
        ...mapGetters('feedbacks', [
            'getterFeedbacksList',
            'getterLastPage'
        ])
    },
    async activated() {
        await this.getOrderFeedbacks()
    },
    async created() {
        if (this.$route.name === 'orders') {
            await this.getOrderFeedbacks()
        }
        if (this.$route.name === 'hr-clients-details') {
            await this.getOrderFeedbacks()
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_FEEDBACKS_LIST,
            ACTION_EDIT_FEEDBACK,
            ACTION_CREATE_TICKET_FEEDBACK,
            ACTION_SET_STATUS_FEEDBACK,
            ACTION_GET_FEEDBACKS_TOTALS
        }),
        async paginationPageEvent(payload) {
            this.searchOptions.page = payload
            this.getOrderFeedbacks(false)
        },
        onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            this.getOrderFeedbacks()
        },
        async getOrderFeedbacks(with_total = true) {
            if (!this.orderid) { return }
            try {
                const requestArr = [this[ACTION_GET_FEEDBACKS_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_FEEDBACKS_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoading = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onConfirmModal({
            actionName, modal, payload, id
        }) {
            try {
                switch (actionName) {
                case 'edit_feedback':
                    await this.editFeedback({ payload, id })
                    break;
                case 'set_status':
                    await this.setStatus({ payload, id })
                    break;
                case 'contact_buyer':
                    await this.contactBuyer(payload)
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', modal.success_message, 'success')
                this.$refs[`card-${id}`][0].onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async editFeedback({ payload, id }) {
            await this[ACTION_EDIT_FEEDBACK]({ payload, id })
        },
        async setStatus({ payload, id }) {
            await this[ACTION_SET_STATUS_FEEDBACK]({ payload, id })
        },
        async contactBuyer(payload) {
            await this[ACTION_CREATE_TICKET_FEEDBACK](payload)
        }
    }
}
</script>

<style>

</style>
