<template>
    <div>
        <v-expand-transition>
            <div v-show="isShow">
                <template v-for="(item, index) in details">
                    <div
                        :key="index"
                        class="extras-row lighten-5"
                        :class="item.is_correct > 0 ? 'green' : 'red'"
                    >
                        <!-- row title -->
                        <div class="d-flex align-center mr-4 ml-4">
                            <div class="d-flex align-center">
                                <span class="font-weight-bold text-uppercase text-subtitle-2">
                                    {{ index }}
                                </span>
                            </div>
                        </div>
                        <!-- Question -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Question, id: {{ item.question_id }}:
                                </p>
                                <p
                                    v-if="item.question"
                                    class="mb-0 text-body-2"
                                >
                                    {{ item.question }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                        </div>
                        <!-- Answer -->
                        <div class="extras-row_col">
                            <div>
                                <template v-if="item.is_correct === 'Not answered'">
                                    <p class="grey--text mb-0 text-body-2">
                                        Answer:
                                    </p>
                                    <p>
                                        Not answered
                                    </p>
                                </template>
                                <template v-else>
                                    <p class="grey--text mb-0 text-body-2">
                                        Answer, id: {{ item.answer_id }}:
                                    </p>
                                    <p
                                        v-if="item.answer"
                                        class="mb-0 text-body-2"
                                    >
                                        {{ item.answer }}
                                    </p>
                                    <p v-else>
                                        -
                                    </p>
                                </template>
                            </div>
                        </div>
                        <!-- Time -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Used time, seconds:
                                </p>
                                <p
                                    v-if="item.time_used_in_seconds"
                                    class="mb-0 text-body-2"
                                >
                                    {{ item.time_used_in_seconds }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                        </div>
                        <!-- Is correct -->
                        <div class="extras-row_col">
                            <div>
                                <p class="grey--text mb-0 text-body-2">
                                    Is correct answer:
                                </p>
                                <v-icon
                                    v-if="item.is_correct > 0"
                                    color="success"
                                >
                                    mdi-check-circle-outline
                                </v-icon>
                                <v-icon
                                    v-else
                                    color="error"
                                >
                                    mdi-close-circle-outline
                                </v-icon>
                            </div>
                        </div>

                        <!-- ordered services -->
                        <!-- <div class="extras-row_col">
                            <div class="flex">
                                <span class="grey--text">
                                    Email:
                                </span>
                                <p
                                    v-if="data.email"
                                    class="mb-0 text-body-2"
                                >
                                    {{ data.email }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                        </div> -->
                    </div>
                </template>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    props: {
        details: {
            type: Array,
            required: true
        },
        isShow: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss">
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
