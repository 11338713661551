<template>
    <v-container
        fluid
    >
        <v-row class="pa-0">
            <v-col cols="12">
                <FileInput
                    ref="inputComponent"
                    :upload-progress="uploadProgress"
                    :start-upload="startUpload"
                    @uploadFiles="onUploadFiles"
                />
            </v-col>
        </v-row>

        <v-row class="pa-0">
            <v-col cols="12">
                <the-files-table
                    :files="orderFiles"
                    :headers="tableHeaders"
                    :client="client"
                    @deleteFile="alertDelete"
                />
            </v-col>
        </v-row>

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </v-container>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import TheFilesTable from '@/components/shared/Tables/TheFilesTable'
import FileInput from '@/components/shared/FileInput'

import { ORDER_FILES_TABLE_HEAD } from '@/constants/Order'

import {
    getOrderAdditionalFiles,
    getOrderFiles,
    uploadOrderAdditionalFiles,
    uploadOrderMainFiles,
    deleteOrderAdditionalFiles,
    deleteOrderMainFiles
} from '@/services/orderService'

export default {
    name: 'Files',
    components: {
        FileInput,
        TheFilesTable,
        ModalAlert
    },
    props: {
        orderid: {
            type: String,
            required: true
        },
        client: {
            type: Object,
            required: false
        },
        filesType: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            modal: {},
            file_id: '',
            sort_options: {
                sort_by: 'created_at',
                sort_order: 'desc'
            },
            orderFiles: [],
            uploadProgress: 0,
            startUpload: false
        }
    },
    computed: {
        tableHeaders() {
            return ORDER_FILES_TABLE_HEAD
        },
        modalComponent() {
            return this.modal.name || ''
        },
        isMainFiles() {
            return this.$route.name === 'order-details-files' || this.filesType === 'FilesMain'
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Files | Order`
        }
    },
    async activated() {
        this.isMainFiles ? await this.getFiles() : await this.getAdditionalFiles()
    },
    async created() {
        if (this.$route.name === 'orders') {
            this.isMainFiles ? await this.getFiles() : await this.getAdditionalFiles()
        }
        if (this.$route.name === 'hr-clients-details') {
            this.isMainFiles ? await this.getFiles() : await this.getAdditionalFiles()
        }
    },
    methods: {
        onCloseModal() {
            this.modal = {}
            this.file_id = ''
        },
        alertDelete(id) {
            this.modal = {
                name: 'ModalAlert',
                title: 'Delete file',
                description: 'Do you want to delete this file?'
            }
            this.file_id = id
        },
        async onConfirmModal() {
            await this.onDeleteFile(this.file_id)
        },
        async getFiles() {
            this.orderFiles = await getOrderFiles({ orderid: this.orderid, ...this.sort_options }, {}, { cache: false })
        },
        async getAdditionalFiles() {
            this.orderFiles = await getOrderAdditionalFiles({ orderid: this.orderid, ...this.sort_options }, {}, { cache: false })
        },
        async onDeleteFile(payload) {
            try {
                if (this.isMainFiles) {
                    await deleteOrderMainFiles({ id: payload })
                    this.$emit('update:counters', { type: 'files_main_count', counter: -1 })
                } else {
                    await deleteOrderAdditionalFiles({ id: payload })
                    this.$emit('update:counters', { type: 'files_add_count', counter: -1 })
                }
                eventBus.$emit('showSnackBar', 'File deleted', 'success')
                this.onCloseModal()
                this.deleteFile(payload)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        deleteFile(fileId) {
            this.orderFiles = this.orderFiles.filter(({ file_id }) => file_id !== fileId)
        },
        async onUploadFiles(payload) {
            try {
                this.startUpload = true
                let resp = null
                const formData = new FormData()
                formData.append('uploaded[]', payload, payload.name)
                formData.append('orderid', this.orderid)
                if (this.isMainFiles) {
                    resp = await uploadOrderMainFiles(formData, (e) => { this.uploadProgress = e.loaded })
                    this.$emit('update:counters', { type: 'files_main_count', counter: 1 })
                    this.$emit('uploadMainFiles')
                } else {
                    resp = await uploadOrderAdditionalFiles(formData, (e) => { this.uploadProgress = e.loaded })
                    this.$emit('update:counters', { type: 'files_add_count', counter: 1 })
                }
                if (resp.file) {
                    this.orderFiles.unshift(resp.file[0])
                    return
                }
                this.orderFiles.unshift(resp[0])
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.startUpload = false
            }
        }
    }
}
</script>

<style>

</style>
