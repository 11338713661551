<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form>
                <v-row>
                    <v-col
                        v-for="(value, field) in formdata"
                        :key="field"
                        cols="6"
                    >
                        <v-text-field
                            v-if="field === 'field_id' || field === 'sw_id'"
                            :id="field"
                            v-model="formdata[field]"
                            outlined
                            hide-details
                            disabled
                            :name="field"
                            :label="field"
                        />
                        <v-select
                            v-else-if="field === 'rank' || field === 'rank_rc'"
                            :id="field"
                            v-model="formdata[field]"
                            :items="tenDigitScale"
                            outlined
                            hide-details
                            :name="field"
                            :label="field"
                        />
                        <v-select
                            v-else-if="field === 'trust_status'"
                            :id="field"
                            v-model="formdata[field]"
                            :items="trustStatuses"
                            outlined
                            hide-details
                            label="Trust status"
                        />
                        <v-select
                            v-else-if="field === 'status'"
                            :id="field"
                            v-model="formdata[field]"
                            :items="activeStatuses"
                            outlined
                            hide-details
                            :label="field"
                        />
                        <v-text-field
                            v-else-if="typeof value === 'string' || typeof value === 'number'"
                            :id="field"
                            v-model="formdata[field]"
                            outlined
                            hide-details
                            :name="field"
                            :label="field"
                        />
                        <v-checkbox
                            v-else
                            v-model="formdata[field].value"
                            hide-details
                            :label="formdata[field].text"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Api from '@/helpers/api/index.js'

import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'ModalWriterEdit',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        writer: {
            type: Object,
            required: true
        },
        trustStatuses: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            formdata: {
                field_id: '',
                sw_id: '',
                // ip: '',
                // ip_country: '',
                // ip_city: '',
                account_name: '',
                nickname: '',
                firstname: '',
                lastname: '',
                email: '',
                email2: '',
                address: '',
                zip: '',
                city: '',
                state: '',
                country: '',
                phone: '',
                phone_mobile: '',
                password: '',
                status: '',
                // accessstatus: '',
                trust_status: '',
                // trust_level: '',
                bonus_level: '',
                workload: '',
                schedule: '',
                deadlines: '',
                resume: '',
                major: '',
                citation_styles: '',
                personal_statement: '',
                why_hire: '',
                other_firms: '',
                plagiarism_statement: '',
                c_log: '',
                // applicants_files: '',
                pay_method: '',
                paypal_email: '',
                projects_limit: '',
                // feedbacks: '',
                rank: '',
                rank_rc: '',
                // writerFiles: '',
                // tickets: '',
                // exam: '',
                // avatar: '',
                // notes: '',
                step_wizard: '',
                chk_agree: {
                    text: 'Check Agree',
                    value: false
                },
                chk_calls: {
                    text: 'Check Calls',
                    value: false
                },
                chk_email_notifications: {
                    text: 'Check Email Notifications',
                    value: false
                },
                chk_email_marketing: {
                    text: 'Check Email Marketing',
                    value: false
                }
                // verified: {
                //     text: 'Check Verified',
                //     value: false
                // }
            }
        }
    },
    computed: {
        tenDigitScale() {
            return [
                {
                    text: '10',
                    value: '10.00'
                },
                {
                    text: '9',
                    value: '9.00'
                },
                {
                    text: '8',
                    value: '8.00'
                },
                {
                    text: '7',
                    value: '7.00'
                },
                {
                    text: '6',
                    value: '6.00'
                },
                {
                    text: '5',
                    value: '5.00'
                },
                {
                    text: '4',
                    value: '4.00'
                },
                {
                    text: '3',
                    value: '3.00'
                },
                {
                    text: '2',
                    value: '2.00'
                },
                {
                    text: '1',
                    value: '1.00'
                }
            ]
        }
    },
    async created() {
        await this.getWriterData()
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        async onConfirmModal() {
            this.$emit('confirmModal', { ...this.formdata })
        },
        async getWriterData() {
            const { data } = await Api.get('/api/writer/fetch-writer', { sw_id: this.writer.sw_id })
            this.formdata = this.merge_options(this.formdata, data);
        },
        merge_options(obj1, obj2) {
            const copy = { ...obj1 }
            return Object.entries(copy).reduce((acc, curr) => {
                if (typeof curr[1] === 'object') {
                    acc[curr[0]] = {
                        text: curr[1].text,
                        value: Boolean(obj2[curr[0]])
                    }
                    return acc
                }
                acc[curr[0]] = obj2[curr[0]] || ''
                return acc
            }, {})
        }
    }
}
</script>

<style>

</style>
