<template>
    <v-chip
        :color="colors.background"
        :text-color="colors.text"
    >
        {{ isActive ? 'ACTIVE' : 'INACTIVE' }}
    </v-chip>
</template>

<script>
export default {
    props: {
        isActive: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        colors() {
            switch (+this.isActive) {
            case 1:
                return {
                    background: 'rgba(255, 84, 4, 0.1)',
                    text: '#284067'
                }
            // case 'OPEN':
            //     return {
            //         background: '#1976D21A',
            //         text: '#1976D2'
            //     }
            default:
                return {
                    background: 'rgba(255, 0, 0, 0.1)',
                    text: '#FF0000'
                }
            }
        }
    }

}
</script>
