<template>
    <v-col
        cols="12"
        class="pt-0"
    >
        <!-- Status -->
        <div class="fb-row">
            <!-- row title -->
            <div class="fb-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Status
                    </span>
                </div>
            </div>
            <!-- Status -->
            <div class="fb-row_col">
                <v-chip
                    v-if="feedback.status"
                    :color="statusColor.color"
                    class="ml-2"
                >
                    <span :class="`${statusColor.text}--text`">{{ feedback.status }}</span>
                </v-chip>
            </div>
        </div>
        <!-- Rate writer: -->
        <div class="fb-row">
            <!-- row title -->
            <div class="fb-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Rate writer:
                    </span>
                </div>
            </div>
            <!-- Rate writer: -->
            <div class="fb-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <v-avatar
                            :color="rateBgColor(feedback.rate_writer)"
                            size="43"
                        >
                            <div :class="`font-weight-bold text-body-2 ${rateFontColor(feedback.rate_writer)}`">
                                {{ feedback.rate_writer || '0.00' }}
                            </div>
                        </v-avatar>
                    </p>
                </div>
            </div>
        </div>
        <!-- Rate company: -->
        <div class="fb-row">
            <!-- row title -->
            <div class=" fb-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Rate company:
                    </span>
                </div>
            </div>
            <!-- Rate company: -->
            <div class="fb-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <v-avatar
                            :color="rateBgColor(feedback.rate_company)"
                            size="43"
                        >
                            <div :class="`font-weight-bold text-body-2 ${rateFontColor(feedback.rate_company)}`">
                                {{ feedback.rate_company || '0.00' }}
                            </div>
                        </v-avatar>
                    </p>
                </div>
            </div>
        </div>
        <p class="fb-review pa-5">
            {{ feedback.parsed_testimonial_old.message }}
        </p>
        <div class="d-flex grey--text justify-space-between">
            <div>{{ feedback.created_at && $date.humanizeDate(feedback.created_at) }}</div>
            <div>
                Ref.:
                <router-link :to="{name: 'order-details', params: {id: feedback.orderid} }">
                    {{ feedback.orderid }}
                </router-link>
            </div>
        </div>
    </v-col>
</template>
<script>

export default {
    props: {
        feedback: {
            type: Object,
            required: true
        },
        withActions: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            modal: {},
            currentFeedback: {},
            actionName: ''
        }
    },
    computed: {
        statusColor() {
            switch (this.feedback.status) {
            case 'OPEN': {
                return {
                    text: 'primary',
                    color: '#1976D21A'
                }
            }
            case 'ACTIVE': {
                return {
                    text: 'success',
                    color: 'rgba(39, 217, 31, 0.1)'
                }
            }
            case 'CLOSED': {
                return {
                    text: 'error',
                    color: '#FF00001A'
                }
            }
            default: {
                return {}
            }
            }
        }
    },
    methods: {
        rateBgColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'rgba(240, 125, 0, 0.1)'
            } if (rate >= 9) {
                return 'rgba(39, 217, 31, 0.1)'
            }
            return 'rgba(255, 0, 0, 0.1)'
        },
        rateFontColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'accent--text'
            } if (rate >= 9) {
                return 'success--text'
            }
            return 'error--text'
        }
    }

}
</script>
