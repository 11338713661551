<template>
    <v-main class="fill-height">
        <v-container class="fill-height">
            <v-row justify="center">
                <v-card
                    outlined
                    elevation="2"
                    width="420px"
                >
                    <div class="d-flex flex-column align-center py-5">
                        <span class="text-subtitle-1 pb-4">Your information</span>
                        <v-divider width="100%" />
                    </div>

                    <div class="list px-5 pb-5">
                        <avatar-upload
                            :avatar="getterMainInfo.avatar_url"
                            @uploadFiles="onUploadFiles"
                        />
                        <div class="d-flex align-center">
                            <span class="mr-4 grey--text">
                                Account name:
                            </span>
                            <span class="mr-auto font-weight-medium">
                                {{ getterMainInfo.firstname }} {{ getterMainInfo.lastname }}
                            </span>
                            <span>
                                <v-btn
                                    icon
                                    link
                                    :to="{name: 'user_setting_change-name'}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <div class="d-flex align-center">
                            <span class="mr-4 grey--text">
                                Email:
                            </span>
                            <span class="mr-auto font-weight-medium">
                                {{ getterMainInfo.email }}
                            </span>
                            <span>
                                <v-btn
                                    icon
                                    link
                                    :to="{name: 'user_setting_change-email'}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <div class="d-flex align-center">
                            <span class="mr-4 grey--text">
                                Password:
                            </span>
                            <span class="mr-auto font-weight-medium">
                                *************
                            </span>
                            <span>
                                <v-btn
                                    icon
                                    link
                                    :to="{name: 'user_setting_change-password'}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <div class="d-flex align-center">
                            <span class="mr-4 grey--text">
                                Phone:
                            </span>
                            <span class="mr-auto font-weight-medium">
                                {{ getterMainInfo.phone_code }}
                                {{ getterMainInfo.phone }}
                            </span>
                            <span>
                                <v-btn
                                    icon
                                    link
                                    :to="{name: 'user_setting_change-phone'}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <div class="d-flex align-center">
                            <span class="mr-4 grey--text">
                                Two-Factor Authentication:
                            </span>
                            <span class="mr-auto font-weight-medium">
                                {{ getterTwoFa ? 'Enabled' : 'Disabled' }}
                            </span>
                            <span>
                                <v-btn
                                    icon
                                    link
                                    :to="{name: 'user_setting_change-twofa'}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import AvatarUpload from '@/components/shared/AvatarUpload'
import { ACTION_UPLOAD_AVATAR } from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: { AvatarUpload },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo',
            'getterTwoFa'
        ])
    },
    metaInfo() {
        return {
            title: 'Profile'
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_UPLOAD_AVATAR
        }),
        async onUploadFiles(payload) {
            try {
                await this[ACTION_UPLOAD_AVATAR](payload)
                eventBus.$emit('showSnackBar', 'The avatar has been uploaded', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style>

</style>
