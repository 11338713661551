<template>
    <modal
        :loading="loading"
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Edit writer fee</span>
        </template>
        <template #body>
            <p>This tool is used to edit writer fee.</p>
            <v-text-field
                v-model="fee"
                v-mask="priceMask"
                label="Fee"
                outlined
                hidedetails
            />
        </template>
    </modal>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Modal from '@/components/shared/Modals/Modal'

const priceMask = createNumberMask({
    prefix: '$',
    allowDecimal: true
})

export default {
    components: {
        Modal
    },
    props: {
        loading: {
            type: Boolean,
            defalut: false
        }
    },
    data() {
        return {
            priceMask,
            fee: '',
            showModal: true
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                cost: Number(this.fee.replace(/\$|,/g, ''))
            })
        }
    }
}
</script>

<style>

</style>
