<template>
    <div
        v-if="!loading"
        class="wrapper py-5"
    >
        <writers-details-top
            :info="writer"
            :is-writer="true"
            :toolbars="toolbars"
            @loadAvatar="onLoadAvatar"
            @toolbarActivated="onToolbarActivated"
        />

        <writers-details-tabs
            :writer="writer"
            :tabs="tabs"
            to="/writers/"
            class="mt-10 mb-15"
            @update:counters="updateCounters"
        />

        <!--        <toolbar-menu-->
        <!--            :toolbars="toolbars"-->
        <!--            @toolbarActivated="onToolbarActivated"-->
        <!--        />-->

        <component
            :is="modalComponent"
            :writer="writer"
            :writerid="writer.sw_id"
            :title="modal.title"
            :description="modal.description"
            :input-label="modal.inputLabel"
            :trust-statuses="trustStatuses"
            :file-types="fileTypes"
            :form-data-type-key="modal.typeKey"
            :loading="loadingInModal"
            :writer-limit="+writer.projects_limit"
            :allowed-file-types="allowedFileTypes"
            :current="createTicketPrepend"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import WritersDetailsTop from '@/components/Writer/WritersDetails/WritersDetailsTop'
import WritersDetailsTabs from '@/components/Writer/WritersDetails/WritersDetailsTabs'
// import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

// MODALS
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalWriterTrust from '@/components/shared/Modals/ModalWriterTrust'
import ModalWriterEdit from '@/components/shared/Modals/ModalWriterEdit'
import ModalUpload from '@/components/shared/Modals/ModalUpload'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'
import ModalWriterUpdate from '@/components/shared/Modals/ModalWriterUpdate.vue'
import ModalAvatarUpload from '@/components/shared/Modals/ModalAvatarUpload'
import ModalWriterCredits from '@/components/shared/Modals/ModalWriterCredits'

import {
    ACTION_CHANGE_AVATAR,
    ACTION_EDIT_UPDATE,
    ACTION_GET_FETCH_WRITER,
    ACTION_SET_STATUS_TRUSTED_TO_NEW,
    ACTION_SET_STATUS_TRUSTED_TO_TRUSTED,
    ACTION_UPLOAD_WRITER_FILE,
    ACTION_WRITER_ACTIVATE,
    ACTION_WRITER_DEACTIVATE,
    ACTION_WRITER_EDIT,
    ACTION_WRITER_POST_NOTE,
    ACTION_REFRESH_STATISTIC,
    ACTION_WRITER_CREDIT
} from '@/store/modules/writers/action-types';

import {
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';

import {
    ALLOWED_FILE_TYPES, TRUST_STATUSES, WRITERS_TOOLBARS
} from '@/constants/Writers'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('writers');

const {
    mapActions: mapAdminActionsSupport
} = createNamespacedHelpers('support');

export default {
    components: {
        WritersDetailsTop,
        WritersDetailsTabs,
        // ToolbarMenu,
        // MODALS
        ModalAlert,
        ModalWriterEdit,
        ModalUpload,
        ModalTextarea,
        ModalCreateTicketWriter,
        ModalWriterTrust,
        ModalWriterUpdate,
        ModalAvatarUpload,
        ModalWriterCredits
    },
    data() {
        return {
            openedModal: '',
            modal: {},
            writer: null,
            fileTypes: [],
            loading: true,
            loadingInModal: false
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterWriter'
        ]),
        toolbars() {
            const subMenu = WRITERS_TOOLBARS.find((element) => Object.prototype.hasOwnProperty.call(element, 'subMenu'))
            if (subMenu) {
                const newToolbar = WRITERS_TOOLBARS.filter((element) => element !== subMenu)
                subMenu.subMenu.forEach((element) => newToolbar.push(element))
                return newToolbar
            }
            return WRITERS_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        },
        trustStatuses() {
            return TRUST_STATUSES
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        },
        createTicketPrepend() {
            return {
                sw_id: this.writer.sw_id,
                writer: {}
            }
        }
    },
    metaInfo() {
        return {
            title: `${this.writer?.firstname} ${this.writer?.lastname} | Writers`
        }
    },
    async activated() {
        try {
            this.loading = true
            await this.getWriter()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_FETCH_WRITER,
            ACTION_WRITER_ACTIVATE,
            ACTION_WRITER_DEACTIVATE,
            ACTION_WRITER_POST_NOTE,
            ACTION_WRITER_EDIT,
            ACTION_SET_STATUS_TRUSTED_TO_TRUSTED,
            ACTION_SET_STATUS_TRUSTED_TO_NEW,
            ACTION_UPLOAD_WRITER_FILE,
            ACTION_EDIT_UPDATE,
            ACTION_CHANGE_AVATAR,
            ACTION_REFRESH_STATISTIC,
            ACTION_WRITER_CREDIT
        }),
        ...mapAdminActionsSupport({
            ACTION_CREATE_TICKET_WRITER
        }),
        onToolbarActivated(payload) {
            // this.actionName = payload.type
            this.actionName = payload.actionName
            this.modal = payload.modal
        },
        async getWriter() {
            const { id } = this.$route.params
            await this[ACTION_GET_FETCH_WRITER]({ id })
            this.writer = this.getterWriter
        },
        onCloseModal() {
            this.modal = {}
        },
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'activate':
                    await this.activateWriter()
                    break
                case 'notes':
                    await this.postNote({ ...payload })
                    break
                case 'edit_writer':
                    await this.editWriter({ ...payload })
                    break
                case 'contact_writer':
                    await this.contactWriter({ ...payload })
                    break
                case 'deactivate':
                    await this.deactivateWriter()
                    break
                case 'edit_trust_status':
                    await this.editTrustStatus(payload)
                    break
                case 'upload_files':
                    await this.uploadFiles(payload)
                    break
                case 'edit_limit':
                    await this.updateWriter(payload)
                    break
                case 'change_avatar':
                    await this.writerAvatarChage(payload)
                    break
                case 'statistic_refresh':
                    await this.refreshStatistic(payload)
                    break
                case 'credits':
                    await this.creditWriter({ ...payload })
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        onLoadAvatar() {
            this.actionName = 'change_avatar'
            this.modal = {
                name: 'ModalAvatarUpload',
                title: 'Change the writer avatar',
                description: 'Change the writer avatar',
                success_message: 'The writer photo has been changed'
            }
        },
        async writerAvatarChage(payload) {
            payload.append('sw_id', this.writer.sw_id)
            const data = await this[ACTION_CHANGE_AVATAR](payload)
            this.writer.avatar = data
            await this.getWriter()
        },
        async activateWriter() {
            await this[ACTION_WRITER_ACTIVATE]({ writer_id: this.writer.sw_id })
            this.writer.is_active = 1
        },
        async deactivateWriter() {
            await this[ACTION_WRITER_DEACTIVATE]({ writer_id: this.writer.sw_id })
            this.writer.is_active = 0
        },
        async postNote(payload) {
            await this[ACTION_WRITER_POST_NOTE]({ writer_id: this.writer.id, ...payload })
            this.writer.notes_count += 1
        },
        async editWriter(payloads) {
            await this[ACTION_WRITER_EDIT](payloads)
        },
        async contactWriter(payload) {
            await this[ACTION_CREATE_TICKET_WRITER](payload)
        },
        async editTrustStatus(payload) {
            if (payload.trust_status === 'TRUSTED') {
                await this[ACTION_SET_STATUS_TRUSTED_TO_TRUSTED]({ writers: [this.writer.sw_id] })
                this.writer.trust_status = 'TRUSTED'
                return
            }
            await this[ACTION_SET_STATUS_TRUSTED_TO_NEW]({ writers: [this.writer.sw_id] })
            this.writer.trust_status = 'NEW'
        },
        async uploadFiles({ formData, callback }) {
            const { sw_id } = this.$route.params
            formData.append('sw_id', sw_id)
            await this[ACTION_UPLOAD_WRITER_FILE]({
                formData, callback
            })
            this.writer.files_count += 1
        },
        async updateWriter(payload) {
            await this[ACTION_EDIT_UPDATE]({ writer_id: this.writer.id, ...payload })
            this.writer.projects_limit = payload.projects_limit
        },
        async refreshStatistic() {
            await this[ACTION_REFRESH_STATISTIC]({ writer_id: this.writer.sw_id })
            await this.getWriter()
        },
        async creditWriter(payload) {
            const { sw_id } = this.writer
            await this[ACTION_WRITER_CREDIT]({ sw_id, ...payload })
        },
        updateCounters(e) {
            if (e.type === 'files') {
                this.writer.counters = {
                    ...this.writer.counters,
                    files_count: this.writer.counters.files_count + e.counter
                }
            }
            if (e.type === 'notes') {
                this.writer.counters = {
                    ...this.writer.counters,
                    notes_count: this.writer.counters.notes_count + e.counter
                }
            }
            if (e.type === 'tickets') {
                this.writer.counters = {
                    ...this.writer.counters,
                    tickets_count: this.writer.counters.tickets_count + e.counter
                }
            }
        }
    }
}
</script>

<style>

</style>
