<template>
    <modal
        :is-show-btns="uploadProgress === 0"
        :is-active-confirm="files.length > 0"
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Upload tool</span>
        </template>
        <template #body>
            <v-alert
                dense
                type="info"
                dark
                class="py-5"
                color="rgba(255, 84, 4, 0.1)"
                icon="mdi-alert-circle-outline"
            >
                <div class="black--text">
                    {{ description }}
                </div>
            </v-alert>
            <div
                v-if="uploadProgress > 0"
                class="d-flex align-center justify-center pb-4"
            >
                <v-progress-circular
                    :rotate="-90"
                    size="100"
                    :value="uploadProgress"
                    color="#284067"
                    width="10"
                >
                    {{ uploadProgress }}
                </v-progress-circular>
            </div>
            <div v-if="files.length && uploadProgress === 0">
                <template v-for="(item, index) in files">
                    <div
                        :key="index"
                    >
                        <div
                            class="d-flex align-center justify-space-between col-12"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="mr-3">
                                    mdi-paperclip
                                </v-icon>
                                <div>
                                    <p class="text-subtitle-2 black--text mb-0">
                                        {{ item.name }}
                                    </p>
                                    <p class="gray--text mb-0">
                                        Additional file
                                    </p>
                                </div>
                            </div>
                            <v-spacer />
                            <div>
                                <v-btn
                                    icon
                                    class="d-flex justify-center"
                                    @click="deleteFile(index)"
                                >
                                    <v-icon class="">
                                        mdi-delete-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-divider />
                    </div>
                </template>
            </div>
            <div
                v-if="uploadProgress === 0"
                class="mt-5"
            >
                <v-select
                    v-if="fileTypes"
                    v-model="type"
                    :items="fileTypes"
                    :error="!isSelectedType"
                    label="Type"
                    outlined
                />
            </div>
            <file-input
                v-if="uploadProgress === 0"
                ref="inputComponent"
                :allowed-types="allowedFileTypes"
                :upload-progress="0"
                @uploadFiles="onUploadFiles"
            />
            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </template>
    </modal>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import Modal from '@/components/shared/Modals/Modal'
import FileInput from '@/components/shared/FileInput'

const priceMask = createNumberMask({
    prefix: '$',
    allowDecimal: true
})

export default {
    name: 'ModalUpload',
    components: {
        Modal,
        FileInput,
        ModalAlert
    },
    props: {
        fileTypes: {
            type: Array
        },
        description: {
            type: String,
            default: 'All file extensions are supported. You can upload files up to 30 MB in size.'
        },
        formDataTypeKey: {
            type: String
        },
        allowedFileTypes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            priceMask,
            fee: '',
            showModal: true,
            modal: {},
            files: [],
            isSelectedType: true,
            type: '',
            uploadProgress: 0
        }
    },
    computed: {
        modalComponent() {
            return this.modal.name || ''
        }
    },
    methods: {
        onCloseModal() {
            if (this.modal.name === 'ModalAlert') {
                this.modal = {}
                return
            }
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            if (this.modal.name === 'ModalAlert') {
                this.modal = {}
                return
            }
            if (this.validate()) {
                this.$emit('confirmModal', { formData: this.generateFormData(), callback: this.onUploadProgress })
            }
        },
        onUploadFiles(payload) {
            // eslint-disable-next-line no-restricted-syntax
            for (const ALLOWED_TYPE of this.allowedFileTypes) {
                if (payload.type === ALLOWED_TYPE) {
                    this.files.push(payload)
                    return
                }
            }
            this.modal = {
                name: 'ModalAlert',
                title: 'Type error',
                description: 'File type not supported'
            }
        },
        deleteFile(index) {
            this.files.splice(index, 1)
            this.$refs['inputComponent'].deleteFile()
        },
        validate() {
            if (this.fileTypes) {
                if (this.type.length) {
                    this.isSelectedType = true
                    return true
                }
                this.isSelectedType = false
                return false
            }
            return true
        },
        generateFormData() {
            const formData = new FormData()
            this.files.forEach((element) => {
                formData.append('file[]', element, element.name)
            })
            if (this.fileTypes) {
                formData.append(this.formDataTypeKey, this.type)
            }
            return formData
        },
        onUploadProgress(progressEvent) {
            const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.uploadProgress = uploadPercentage
        }
    }
}
</script>

<style>

</style>
