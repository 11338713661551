<template>
    <div class="wrapper mt-5 payroll-page">
        <v-container
            fluid
            class="white rounded "
        >
            <v-row class="mb-5" />

            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            />
            <credits-table
                class="mt-6"
                :items="getterWritersCreditsList"
                :last-page="getterWritersLastPage"
                :total="getterWritersTotal"
                :total-credits="getterWritersTotalCredit"
                :total-debits="getterWritersTotalDebit"
                :list-loading="getterWritersListLoading"
                :totals-loading="getterWritersTotalLoading"
                :search-options="searchOptions"
                :sort-desc="sortDesc"
                is-writer
                @updatePage="paginationPageEvent"
                @updatePerpage="onUpdatePerpage"
                @updateOptions="updateOptions"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'

import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

import {
    ACTION_GET_WRITER_CREDITS_LIST,
    ACTION_GET_WRITER_CREDITS_TOTALS
} from '@/store/modules/credits/action-types';

import CreditsTable from '@/components/Credits/CreditsTable.vue';

const {
    mapActions: mapCreditsActions
} = createNamespacedHelpers('credits');

export default {
    name: 'CreditsWriter',
    components: {
        TheMainFilter,
        CreditsTable
    },
    data() {
        return {
            search_fields: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true
        }
    },
    metaInfo: {
        title: 'CreditsWriter'
    },
    computed: {
        ...mapGetters('credits', [
            'getterWritersCreditsList',
            'getterWritersLastPage',
            'getterWritersTotal',
            'getterWritersTotalCredit',
            'getterWritersTotalDebit',
            'getterWritersListLoading',
            'getterWritersTotalLoading'
        ]),
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortDesc() {
            return this.sort.sort_order === 'ASC'
        }
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        if (!this.isFirstLoad) {
            requests.push(this.getCredits())
        }
        await Promise.allSettled(requests)
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('credits-writer')
        },
        ...mapCreditsActions({
            ACTION_GET_WRITER_CREDITS_LIST,
            ACTION_GET_WRITER_CREDITS_TOTALS
        }),
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getCredits(false)
        },
        async getCredits(with_total = true) {
            try {
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const requests = [
                    this[ACTION_GET_WRITER_CREDITS_LIST]({ ...this.searchOptions, mode: 'list' })
                ]
                if (with_total) {
                    requests.push(
                        this[ACTION_GET_WRITER_CREDITS_TOTALS]({ ...this.searchOptions, mode: 'count' })
                    )
                }
                await Promise.all(requests)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            await this.getCredits()
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getCredits()
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getCredits()
        }
    }
}
</script>
