<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Create a support ticket</span>
        </template>
        <template #body>
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="details"
                            outlined
                            color="accent"
                            class="mt-3"
                            label="Allowed users comments"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        feedback: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            status_priority: 'NORMAL',
            subject: 'inquiry',
            details: '',
            msgtype: 'inquiry',
            msgcategory: '',
            msgresolution: ''
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                clientid: this.feedback.clientid,
                orderid: this.feedback.orderid,
                status_priority: this.status_priority,
                subject: this.subject,
                details: this.details,
                msgtype: this.msgtype,
                msgcategory: this.msgcategory,
                msgresolution: this.msgresolution
            })
        }
    }
}
</script>

<style>

</style>
