<template>
    <div
        class="file-uploader__box file-uploader__box_upload"
        :class="{ dragging: isDragging }"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent
        @drop="onDrop"
    >
        <div
            class="file-uploader__dragzone"
        >
            <div class="d-flex flex-column align-center">
                <v-icon
                    v-if="!avatar"
                    large
                >
                    mdi-paperclip
                </v-icon>
                <v-avatar
                    v-else
                    size="60"
                >
                    <img
                        :src="avatar"
                        alt="avatar"
                        @error="loadError = true"
                    >
                </v-avatar>
                <p class="file-uploader__dragzone-text mt-2">
                    Drag files here
                </p>
            </div>

            <div class="file-uploader__file-input">
                <input
                    id="file"
                    ref="input"
                    type="file"
                    multiple
                    name="uploaded_image"
                    accept="image/*"
                    class="avatar__uploader"
                    @change="setImage($event)"
                >
            </div>
        </div>

        <div
            v-show="files.length"
            class="file-uploader__file-loading"
        >
            <circle-progress
                v-if="uploadProgress > 0"
                :progress="uploadProgress"
            />
        </div>
        <component
            :is="modalComponent"
            :title="modal.title"
            :image="image"
            @rotate="rotate"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        >
            <template #content>
                <vue-cropper
                    v-if="image"
                    ref="cropper"
                    :src="image"
                    alt="Source Image"
                    :aspect-ratio="1"
                    preview=".preview"
                    class="avatar-cropper"
                />
            </template>
        </component>
    </div>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import Compressor from 'compressorjs';
import CroperModal from '@/components/shared/Modals/CroperModal'
import { eventBus } from '@/helpers/event-bus/'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'

export default {
    components: {
        CroperModal,
        VueCropper
    },
    props: {
        avatar: String,
        uploadProgress: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            image: null,
            cropedImage: '',
            isDragging: false,
            file: [],
            files: [],
            loadError: false,
            modal: {}
        }
    },
    computed: {
        modalComponent() {
            return this.modal.name || ''
        }
    },
    methods: {
        modalActivated() {
            this.modal = {
                name: 'CroperModal',
                title: 'Crop your photo'
            }
        },
        onCloseModal() {
            this.modal = {}
            this.image = null
            this.$refs.input.value = ''
        },
        onDragEnter(e) {
            e.preventDefault();

            this.dragCount += 1;
            this.isDragging = true;
        },
        onDragLeave(e) {
            e.preventDefault();

            this.dragCount -= 1;
            if (this.dragCount <= 0) {
                this.isDragging = false;
            }
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;

            this.loadImage(e.dataTransfer.files[0])
            this.modalActivated()
        },
        upload(payload) {
            this.$emit('uploadFiles', payload)
        },
        setImage(e) {
            this.loadImage(e.target.files[0])
            this.modalActivated()
        },
        loadImage(file) {
            this.fileName = file.name
            const reader = new FileReader()
            reader.onload = (event) => {
                this.image = event.target.result
            };
            reader.readAsDataURL(file)
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        onConfirmModal() {
            this.loadError = false
            const $vm = this
            this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
                new Compressor(blob, {
                    quality: 1,
                    async success(result) {
                        if (result.size / 1024 / 1024 > 8) {
                            eventBus.$emit('showSnackBar', 'File size exceeds 8MiB', 'error');
                            throw new Error('Error')
                        }
                        const formData = new FormData();
                        formData.append('file', result, $vm.fileName);
                        $vm.upload(formData)
                        $vm.onCloseModal()
                    },
                    error(err) {
                        eventBus.$emit('showSnackBar', err.message, 'error');
                    }
                });
            });
        }
    }
}
</script>

<style lang="scss">
    .file-uploader {
        padding-bottom: 40px;

        &__box {
            min-height: 100px;
            border: 2px dashed #96a1b2;
            text-align: center;
            position: relative;
            transition: background .2s ease, border .1s ease-in;

            &_uploaded {
                margin: 10px 0px 0 0px;
                padding: 30px 0 0;
                border: none;
                position: relative;
                border-bottom: 1px solid #fff;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #e7ecf4;
                    display: none;
                }

                h4 {
                    text-align: left;
                    color: #1976D2;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 20px;
                    margin-bottom: 20px;
                    width: 100%;
                    padding-bottom: 19px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: calc(100% + 40px);
                        height: 1px;
                        background: #e7ecf4;
                        bottom: 0;
                        left: -20px;
                    }
                }
            }

            &.dragging {
                background: rgba(100%, 41%, 5%, .1);
                transition: background .2s ease, border .1s ease-in;
            }
        }

        &__box_upload {
            &:hover {
                border: 2px dashed #1976D2;
                background:rgba(100%, 41%, 5%, .1);

                .file-uploader__dragzone-text {
                    color: #1976D2;

                    &:before {
                        background: #1976D2;
                    }
                }
            }
        }

        &__dragzone {
            padding: 50px 0;
            position: relative;

            &-text {
                margin: 0 !important;
                display: inline-block;
                color: #96a1b2;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
            }
        }

        &__file-input {
            width: 100%;
            height: 100%;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            input {
                background: transparent;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: auto;
            }
        }

        &__file-loading {
            padding: 50px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            .file-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 40px;
                position: relative;

                &__progress {
                    position: relative;
                    width: 80px;
                    height: 50px;
                    margin-right: 18px;

                    img {
                        width: 80px;
                        height: 100%;
                        border: 1px solid #e7ecf4;
                    }

                    .progressbar {
                        position: absolute;
                        left: calc(50% - 15px);
                        top: calc(50% - 15px);
                    }
                }

                .details {
                    font-size: 14px;
                    text-align: left;
                    max-width: 136px;
                    width: 100%;

                    .name {
                        word-wrap: break-word;
                        margin-top: 8px;
                    }
                }
            }
        }
}

</style>
