<template>
    <div class="text-body-2">
        <!-- PAGES -->
        <div class="d-flex align-center">
            <div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-1"
                            size="22"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-file-outline
                        </v-icon>
                    </template>
                    <span>Pages</span>
                </v-tooltip>
                {{ getLabel(`page`, item.pages) }}
            </div>
        </div>
        <!-- Slides -->
        <div
            v-if="item.slides"
            class="d-flex align-center"
        >
            <div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-1"
                            size="22"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-image-outline
                        </v-icon>
                    </template>
                    <span>Slides</span>
                </v-tooltip>
                {{ getLabel(`slide`, item.slides) }}
            </div>
        </div>
        <!-- Charts -->
        <div
            v-if="item.charts"
            class="d-flex align-center"
        >
            <div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-1"
                            size="22"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-chart-box-outline
                        </v-icon>
                    </template>
                    <span>Charts</span>
                </v-tooltip>
                {{ getLabel(`chart`, item.charts) }}
            </div>
        </div>
        <!-- Problems -->
        <div
            v-if="item.problems"
            class="d-flex align-center"
        >
            <div>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="mr-1"
                            size="22"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-chart-box-outline
                        </v-icon>
                    </template>
                    <span>Problems</span>
                </v-tooltip>
                {{ getLabel(`problem`, item.problems) }}
            </div>
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'

export default {
    mixins: [
        filtersMixin
    ],
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        getLabel(singular, qty) {
            return (qty > 1) ? `${qty} ${singular}s` : `${qty} ${singular}`
        }
    }
}
</script>

<style>

</style>
