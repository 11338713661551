<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <h2>{{ title }}</h2>
        </template>
        <template #body>
            <div :class="{ 'circle-image' : circleMode }">
                <slot name="content" />
            </div>
        </template>
        <template #actions>
            <v-card-actions
                class="py-5 px-5"
            >
                <v-btn
                    color="accent"
                    class="py-4 px-3 mr-4"
                    height="max-content"
                    @click="onConfirmModal"
                >
                    confirm
                </v-btn>

                <v-btn
                    class="py-4 px-3"
                    height="max-content"
                    @click="onCloseModal"
                >
                    cancel
                </v-btn>
                <v-btn
                    class="py-3 px-3"
                    height="52px"
                    @click="rotate(90)"
                >
                    <v-icon>
                        mdi-rotate-right
                    </v-icon>
                </v-btn>
                <v-btn
                    class="py-3 px-3"
                    height="52px"
                    @click="rotate(-90)"
                >
                    <v-icon>
                        mdi-rotate-left
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'CroperModal',
    components: {
        Modal
    },
    props: {
        title: String,
        content: String,
        name: String,
        circleMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showModal: true
        }
    },
    methods: {
        rotate(deg) {
            this.$emit('rotate', deg)
        },
        onCloseModal() {
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            // this.showModal = false
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style>
.avatar-cropper {
    max-height: 60vh;
}

.avatar-cropper .cropper-view-box {
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51,153,255,.75);
    overflow: hidden;
    width: 100%;
}

.circle-image .avatar-cropper .cropper-view-box {
    border-radius: 50%!important;
}

.cropper-wrap-box {
    background: #808080;
}
</style>
