<template>
    <v-tooltip
        top
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                class="rating--chip"
                :style="`background: ${backgroundColor}`"
                v-bind="attrs"
                v-on="on"
            >
                <div class="d-flex flex-column align-center">
                    <div v-if="rank_all_count">
                        <span
                            :class="`text-body-2 line-height-1 rating--reviews`"
                            :style="`background: ${backgroundColorCounter}`"
                        >
                            {{ rank_all_count || '0' }}
                        </span>
                        <div :class="`${fontColor(rank_all)} font-weight-bold text-body-1 line-height-1 rating--total`">
                            {{ rank_all || '0.00' }}
                        </div>
                        <div :class="`${fontColor(rank_thirty_days)} font-weight-bold text-body-1 line-height-1 `">
                            {{ rank_thirty_days || '0.00' }}
                        </div>
                    </div>
                    <span
                        v-else
                        style="color: rgba(255, 0, 0, 1); font-weight: 600"
                    >
                        N/A
                    </span>
                </div>
            </div>
        </template>
        <div>
            <p class="mb-0">
                Feedbacks: {{ rank_all_count }}
            </p>
            <p class="mb-0">
                Total rate: {{ rank_all }}
            </p>
            <p class="mb-0">
                Monthly rate: {{ rank_thirty_days }}
            </p>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['rank_all_count', 'rank_all', 'rank_thirty_days'],
    computed: {
        backgroundColor() {
            if (this.rank_all >= 5 && this.rank_all < 9) {
                return 'rgba(240, 125, 0, 0.1)'
            } if (this.rank_all >= 9) {
                return 'rgba(39, 217, 31, 0.1)'
            }
            return 'rgba(255, 0, 0, 0.1)'
        },
        backgroundColorCounter() {
            if (this.rank_all >= 5 && this.rank_all < 9) {
                return 'rgba(240, 125, 0, 1)'
            } if (this.rank_all >= 9) {
                return 'rgba(39, 217, 31, 1)'
            }
            return 'rgba(255, 0, 0, 1)'
        }
    },
    methods: {
        fontColor(rank) {
            if (rank >= 5 && rank < 9) {
                return 'accent--text'
            } if (rank >= 9) {
                return 'success--text'
            }
            return 'error--text'
        }
    }
}
</script>

<style lang="scss">
.line-height-1{
    line-height: 1.1 !important;
}
.rating{
    &--chip{
        // aspect-ratio: 1;
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &--total{
        position: relative;
    }
    &--reviews{
        border-radius: 4px;
        top: 5px;
        padding: 3px;
        font-size: 12px;
        line-height: 1;
        color: #fff;
        position: absolute;
        left: 50px;
        white-space: nowrap;
        z-index: 1;
    }
}
</style>
