<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="exams"
            :items-per-page="-1"
            :hide-default-footer="true"
            :sort-by="searchOptions.sort_by"
            :sort-desc="sortDesc"
            must-sort
            @update:options="updateOptions"
        >
            <template v-slot:body="{ items }">
                <tbody>
                    <template v-for="(item, index) in items">
                        <tr
                            :key="item.session_id"
                            :class="rowClasses(item)"
                        >
                            <!-- INDEX -->
                            <td class="td_index">
                                <div class="d-flex align-center height-100">
                                    {{ index + 1 }}
                                </div>
                            </td>
                            <!-- ID -->
                            <td>
                                <div class="d-flex align-center height-100">
                                    {{ item.session_id }}
                                </div>
                            </td>
                            <!-- CREATED -->
                            <td>
                                <div class="d-flex align-center height-100">
                                    {{ $date.humanizeDate(item.created_at) }}
                                </div>
                            </td>
                            <!-- UPDATED -->
                            <td>
                                <div class="d-flex align-center height-100">
                                    {{ $date.humanizeDate(item.updated_at) }}
                                </div>
                            </td>
                            <!-- RIGHT_ANSWERS -->
                            <td>
                                <div class="d-flex align-center height-100">
                                    <v-avatar
                                        :color="colored(item.count_correct_answer, item.answers_total).bg"
                                        size="43"
                                    >
                                        <div :class="`font-weight-bold text-body-2 ${colored(item.count_correct_answer, item.answers_total).text}`">
                                            {{ item.count_correct_answer || '0' }}
                                        </div>
                                    </v-avatar>
                                </div>
                            </td>
                            <!-- QUESTIONS -->
                            <td>
                                <div class="d-flex align-center height-100">
                                    <v-avatar
                                        color="rgba(39, 217, 31, 0.1)"
                                        size="43"
                                    >
                                        <div class="font-weight-bold text-body-2 success--text">
                                            {{ item.answers_total || '0' }}
                                        </div>
                                    </v-avatar>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-center align-center">
                                    <v-btn
                                        v-if="openedRow.includes(item.session_id)"
                                        icon
                                        @click="openFullRow(item.session_id)"
                                    >
                                        <v-icon>
                                            mdi-chevron-up
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        icon
                                        @click="openFullRow(item.session_id)"
                                    >
                                        <v-icon>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                        <tr
                            :key="`${item.session_id}-exdent`"
                            class="exdent-row"
                            :class="extarsRowClasses(item)"
                        >
                            <td
                                :colspan="headers.length + 1"
                            >
                                <general-extras
                                    :details="item.session_details"
                                    :is-show="openedRow.includes(item.session_id)"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!exams.length"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6 grey--text">
                No data found
            </p>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_GET_EXAM_LIST
} from '@/store/modules/writers/action-types';

import {
    EXAM_TABLE_HEADER
} from '@/constants/Writers'

import GeneralExtras from '@/components/Writer/WritersDetails/ExamsGeneralExtras.vue';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        GeneralExtras
    },
    data() {
        return {
            exams: [],
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            searchOptions: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true,
            openedRow: []
        }
    },
    computed: {
        headers() {
            return EXAM_TABLE_HEADER
        },
        sortDesc() {
            return this.sort.sort_order === 'ASC'
        }
    },
    async activated() {
        await this.getExams()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_EXAM_LIST
        }),
        async getExams() {
            try {
                const { id } = this.$route.params
                this.exams = await this[ACTION_GET_EXAM_LIST]({ writer_id: id, ...this.searchOptions })
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getExams()
            }
        },
        colored(answer, total) {
            const percentage = 100 / (total / answer)
            switch (true) {
            // eslint-disable-next-line no-restricted-globals
            case (percentage <= 40 || isNaN(percentage)): {
                return {
                    bg: 'rgba(255, 0, 0, 0.1)',
                    text: 'error--text'
                }
            }
            case (percentage > 40 && percentage <= 90): {
                return {
                    bg: 'rgba(240, 125, 0, 0.1)',
                    text: 'accent--text'
                }
            }
            default: {
                return {
                    bg: 'rgba(39, 217, 31, 0.1)',
                    text: 'success--text'
                }
            }
            }
        },
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        rowClasses(item) {
            return {
                'table-row--opened': this.openedRow.includes(item.session_id)
            }
        },
        extarsRowClasses(item) {
            return {
                'table-extras-row--opened': this.openedRow.includes(item.session_id)
            }
        }
    }
}
</script>
<style lang="scss">
.height-100{
    height: 100%;
}
</style>
