<template>
    <Viewer
        :title="'Batches'"
        @closeSidePanel="closeBatches"
    >
        <template #body>
            <the-batch-table
                :loading="loading"
                :search-options="searchOptions"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @sortTable="sortTable"
            />
            <!-- <div
                v-if="batches"
                class=""
            >
                <template v-for="(order,index) in batches">
                    <order-card
                        :key="index"
                        :index="index + 1"
                        :order="order"
                        class="my-5"
                    />
                </template>
            </div> -->
        </template>
    </Viewer>
</template>

<script>
import Viewer from '@/components/SidePanels/Viewer'
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import {
    ACTION_GET_BATCH_LIST
} from '@/store/modules/batch/action-types';

// import Api from '@/helpers/api/index.js'
// import OrderCard from '@/components/shared/Cards/OrderCard'
import TheBatchTable from '@/components/BatchTable/TheBatchTable.vue'

const {
    mapActions: mapBatchActions
} = createNamespacedHelpers('batch');

export default {
    components: {
        Viewer,
        // OrderCard,
        TheBatchTable
    },
    props: {
        batchid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            isFirstLoad: true,
            sort: {
                sort_by: 'created_at',
                sort_order: 'ASC'
            },
            searchOptions: {
                page: 1,
                per_page: 1,
                search_for: this.batchid,
                search_by: 'batchid',
                sort_by: 'created_at',
                sort_order: 'DESC',
                domain: ''
            }
        }
    },
    computed: {
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        }
    },
    async created() {
        await this.getBatch()
    },
    methods: {
        ...mapBatchActions({
            ACTION_GET_BATCH_LIST
        }),
        async getBatch() {
            try {
                this.loading = true
                await this[ACTION_GET_BATCH_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        closeBatches() {
            this.$emit('closeBatches', true)
        },
        async sortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            this.sort.sort_by = e.sortBy[0] || 'clientid'
            this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
            if (!this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...this.sort, page: 1 }
                await this.getListBatch()
            }
            this.isFirstLoad = false
        }
    }
}
</script>

<style lang="scss">

</style>
