<template>
    <files
        :orderid="orderid"
        :client="client"
        :files-type="filesType"
    />
</template>

<script>
import Files from '@/components/files/Main.vue'

export default {
    name: 'OrderFilesMain',
    components: {
        Files
    },
    props: {
        orderid: {
            type: String,
            required: true
        },
        client: {
            type: Object,
            required: false
        },
        filesType: {
            type: String,
            required: false
        }
    }
}
</script>

<style>

</style>
