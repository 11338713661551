<template>
    <setting-layout
        title="Change user name"
    >
        <template #form>
            <v-form
                class="px-5 pb-5"
                @submit.prevent="changeName"
            >
                <v-text-field
                    id="firstname"
                    v-model="firstname"
                    outlined
                    label="Enter new first name"
                />
                <v-text-field
                    id="lastname"
                    v-model="lastname"
                    outlined
                    label="Enter new last name"
                />

                <v-btn
                    color="accent"
                    width="100%"
                    height="56px"
                    type="submit"
                >
                    Submit
                </v-btn>
            </v-form>
        </template>
    </setting-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import SettingLayout from '@/layout/SettingLayout'

import { eventBus } from '@/helpers/event-bus';

import {
    ACTION_UPDATE_SELF
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingLayout
    },
    data() {
        return {
            firstname: '',
            lastname: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ])
    },
    metaInfo: {
        title: 'Change name'
    },
    created() {
        this.firstname = this.getterMainInfo.firstname
        this.lastname = this.getterMainInfo.lastname
    },
    methods: {
        ...mapAdminActions({
            ACTION_UPDATE_SELF
        }),
        async changeName() {
            try {
                await this[ACTION_UPDATE_SELF]({ firstname: this.firstname, lastname: this.lastname })
                eventBus.$emit('showSnackBar', 'success', 'success')
                this.$router.push({ name: 'user_setting' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style>

</style>
