<template>
    <div
        class="file-uploader__box file-uploader__box_upload"
        :class="{ dragging: isDragging }"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent
        @drop="onDrop"
    >
        <div
            class="file-uploader__dragzone"
        >
            <div
                v-if="!startUpload"
                class="d-flex flex-column"
            >
                <v-icon large>
                    mdi-paperclip
                </v-icon>
                <p class="file-uploader__dragzone-text mt-2">
                    Drag files here
                </p>
            </div>

            <div
                v-else
                class="d-flex align-center justify-center"
            >
                <v-progress-circular
                    :value="uploadProgress"
                    color="accent"
                />
            </div>

            <div class="file-uploader__file-input">
                <input
                    id="file"
                    ref="input"
                    type="file"
                    multiple
                    :accept="allowedTypes"
                    @change="onInputChange"
                >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        uploadProgress: {
            type: Number,
            required: false,
            default: 0
        },
        startUpload: {
            type: Boolean,
            defailt: false
        },
        allowedTypes: {
            type: Array
        }
    },
    data() {
        return {
            isDragging: false
        }
    },
    methods: {
        onDragEnter(e) {
            e.preventDefault();

            this.dragCount += 1;
            this.isDragging = true;
        },
        onDragLeave(e) {
            e.preventDefault();

            this.dragCount -= 1;
            if (this.dragCount <= 0) {
                this.isDragging = false;
            }
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;

            const { files } = e.dataTransfer;

            Array.from(files).forEach((file) => this.upload(file));
        },
        onInputChange(e) {
            const { files } = e.target;
            Array.from(files).forEach((file) => this.upload(file));
        },
        upload(file) {
            this.$emit('uploadFiles', file)
        },
        deleteFile() {
            this.$refs['input'].value = null
        }
    }
}
</script>

<style lang="scss">
    .file-uploader {
        padding-bottom: 40px;

        &__box {
            min-height: 100px;
            border: 2px dashed #96a1b2;
            text-align: center;
            position: relative;
            transition: background .2s ease, border .1s ease-in;
            overflow: hidden;

            &_uploaded {
                margin: 10px 0px 0 0px;
                padding: 30px 0 0;
                border: none;
                position: relative;
                border-bottom: 1px solid #fff;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #e7ecf4;
                    display: none;
                }

                h4 {
                    text-align: left;
                    color: #1976D2;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 20px;
                    margin-bottom: 20px;
                    width: 100%;
                    padding-bottom: 19px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: calc(100% + 40px);
                        height: 1px;
                        background: #e7ecf4;
                        bottom: 0;
                        left: -20px;
                    }
                }
            }

            &.dragging {
                background: rgba(100%, 41%, 5%, .1);
                transition: background .2s ease, border .1s ease-in;
            }
        }

        &__box_upload {
            &:hover {
                border: 2px dashed #1976D2;
                background:rgba(100%, 41%, 5%, .1);

                .file-uploader__dragzone-text {
                    color: #1976D2;

                    &:before {
                        background: #1976D2;
                    }
                }
            }
        }

        &__dragzone {
            padding: 50px 0;
            position: relative;

            &-text {
                margin: 0 !important;
                display: inline-block;
                color: #96a1b2;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
            }
        }

        &__file-input {
            width: 100%;
            height: 100%;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            input {
                background: transparent;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
                z-index: auto;
            }
        }

        &__file-loading {
            padding: 50px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            .file-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 40px;
                position: relative;

                &__progress {
                    position: relative;
                    width: 80px;
                    height: 50px;
                    margin-right: 18px;

                    img {
                        width: 80px;
                        height: 100%;
                        border: 1px solid #e7ecf4;
                    }

                    .progressbar {
                        position: absolute;
                        left: calc(50% - 15px);
                        top: calc(50% - 15px);
                    }
                }

                .details {
                    font-size: 14px;
                    text-align: left;
                    max-width: 136px;
                    width: 100%;

                    .name {
                        word-wrap: break-word;
                        margin-top: 8px;
                    }
                }
            }
        }
}

</style>
