<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-alert
                dense
                type="info"
                dark
                class="py-5"
                color="rgba(255, 84, 4, 0.1)"
                icon="mdi-alert-circle-outline"
            >
                <div class="black--text">
                    {{ description }}
                </div>
            </v-alert>
            <div class="d-flex align-center pa-4">
                <div class="bids_avatar">
                    <writer-avatar
                        :id="+bid.owner"
                        :feedbacks="bid.author_statistics.reviews"
                        :rating_rc="bid.rank_rc"
                        :rating="bid.rank"
                        :status="bid.trust_status"
                        :firstname="bid.author.firstname"
                        :lastname="bid.author.lastname"
                        :avatar-url="bid.author.avatar_url"
                        :avatar-fallback="bid.author.avatar_fallback"
                        :order_limit="bid.author.projects_limit"
                        :country="bid.author.country"
                        :country-nice-name="bid.author.country_nice_name"
                    />
                </div>
                <div class="ml-5">
                    <p class="mb-0 font-weight-medium">
                        {{ bid.author.firstname }} {{ bid.author.lastname }}
                    </p>
                    <p class="mb-0">
                        Writer ID: <b>{{ bid.author.id }}</b>
                    </p>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'

export default {
    components: {
        Modal,
        WriterAvatar
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        bid: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showModal: true
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style>

</style>
