import { render, staticRenderFns } from "./Rating.vue?vue&type=template&id=03d17fad&"
import script from "./Rating.vue?vue&type=script&lang=js&"
export * from "./Rating.vue?vue&type=script&lang=js&"
import style0 from "./Rating.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
