<template>
    <div class="">
        <v-data-table
            v-model="selected"
            :headers="tableHeader"
            :mobile="true"
            :items="tableItems"
            item-key="id"
            :server-items-length="total"
            :loading="loading"
            hide-default-footer
            show-select
            calculate-widths
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            must-sort
            @update:options="sortTable"
        >
            <template #header="{ }">
                <thead
                    v-if="!mobile"
                    class="v-data-table-header"
                >
                    <tr>
                        <th
                            v-for="h in prependHeader"
                            :key="h.value"
                        >
                            <template v-if="h.value === 'orderid'">
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Orders:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="loading_totals"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.orders | number }}</b>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Pages:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="loading_totals"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ Math.ceil(getterSummary.orders / per_page) | number }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template v-if="h.value === 'total'">
                                <div class="d-flex align-center">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Total:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="loading_totals"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.total | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template v-if="h.value === 'sw_total'">
                                <div class="d-flex align-center">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Total Writer:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="loading_totals"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.total_writer | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template v-if="h.value === 'writer_id'">
                                <div class="d-flex align-center">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Total Net:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="loading_totals"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ (getterSummary.total - getterSummary.total_writer) | money }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </th>
                    </tr>
                </thead>
                <thead v-else>
                    <tr>
                        <th class="d-flex">
                            <div class="d-flex align-center">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Orders:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.orders | number }}</b>
                                    </p>
                                </div>
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Pages:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ Math.ceil(getterSummary.orders / per_page) | number }}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-center mr-4">
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Total:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.total | money }}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-center mr-4">
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Total Writer:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.total_writer | money }}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex align-center mr-4">
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Total Net:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ (getterSummary.total - getterSummary.total_writer) | money }}</b>
                                    </p>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <table-skeleton
                    v-if="loading && (items && items.length === 0)"
                    :per-page="per_page"
                    :column-count="tableHeaderLength"
                />
                <table-loader
                    v-if="loading"
                />
                <tbody>
                    <template v-for="(item, index) in items">
                        <tr
                            :key="item.orderid"
                            class="hovered-row"
                            :class="rowClasses(item)"
                            @mouseenter="selectItem(item.orderid)"
                            @mouseleave="unSelectItem(item.orderid)"
                        >
                            <!-- CHECKBOX -->
                            <td class="td_checkbox">
                                <v-checkbox
                                    v-model="selected"
                                    multiple
                                    :value="item"
                                    style="margin:0px;padding:0px"
                                    hide-details
                                />
                            </td>
                            <!-- CREATED -->
                            <td
                                class="td_dates"
                                style="position: sticky; left: 0;z-index: 9; background-color: white;"
                            >
                                <div class="d-flex align-center">
                                    <p class="mb-0">
                                        {{ $date.dateFromNow(item.dates.created_at) }}
                                    </p>
                                    <information-tooltip>
                                        <div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Ordered:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.dates.created_at ? $date.fullDate(item.dates.created_at) : '—' }}
                                                </p>
                                            </div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Completed:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.dates.completed_at ? $date.fullDate(item.dates.completed_at) : '—' }}
                                                </p>
                                            </div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Paid:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.dates.paid_at ? $date.fullDate(item.dates.paid_at) : '—' }}
                                                </p>
                                            </div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Refunded:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.dates.refunded_at ? $date.fullDate(item.dates.refunded_at) : '—' }}
                                                </p>
                                            </div>
                                        </div>
                                    </information-tooltip>
                                </div>
                            </td>
                            <!-- ORDER DETAILS -->
                            <td class="td_general">
                                <general
                                    :data="item"
                                    :opened-row="openedRow"
                                    :hovered-item="hoveredItem"
                                    :index="(per_page * (page - 1)) + index + 1"
                                    :order-id="orderId"
                                    @showNotes="onShowNotes"
                                    @showBatches="$emit('showBatches', $event, item.orderid)"
                                    @showTestimonials="onShowTestimonials"
                                    @openPreferredWriters="onOpenPreferredWriters(item)"
                                    @openBlockedWriters="onOpenBlockedWriters(item)"
                                    @showBids="showBids(item)"
                                    @showDetails="onShowDetails"
                                    @menuActivated="onMenuActivated"
                                    @openFullRow="onOpenFullRow"
                                    @orderID="orderID"
                                />
                            </td>
                            <!-- DOMAIN -->
                            <td
                                class="td_domain"
                            >
                                <domain-favicon
                                    :domain="item.client.domain"
                                />
                            </td>
                            <!-- CLIENT -->
                            <td class="td_user">
                                <p>{{ item.client.firstname }} {{ item.client.lastname }}</p>
                                <div class="d-flex">
                                    <client-avatar
                                        v-if="item.client && item.client.id"
                                        :id="item.client.id"
                                        :is-client="true"
                                        :orders-count="item.client.statistics.orders_total"
                                        :client-country="item.client.country"
                                        :client-email="item.client.email"
                                        :country-nice-name="item.client.country_nice_name"
                                        :firstname="item.client.firstname ? item.client.firstname : ''"
                                        :lastname="item.client.lastname ? item.client.lastname : ''"
                                    />
                                    <p
                                        v-else
                                        class="text-center"
                                    >
                                        —
                                    </p>
                                    <div class="ml-2">
                                        <v-tooltip
                                            v-if="item.client.utm"
                                            top
                                            color="primary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    v-bind="attrs"
                                                    class="mb-4 info-text"
                                                    color="primary"
                                                    size="24"
                                                    v-on="on"
                                                >
                                                    mdi-advertisements
                                                </v-icon>
                                            </template>
                                            <div class="d-flex flex-column">
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Campaign:
                                                    </p>
                                                    <p
                                                        v-if="item.client.utm.utm_campaign"
                                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                                    >
                                                        <span>{{ item.client.utm.utm_campaign }}</span>
                                                    </p>
                                                    <p v-else>
                                                        &mdash;
                                                    </p>
                                                </div>
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Source:
                                                    </p>
                                                    <p
                                                        v-if="item.client.utm.utm_source"
                                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                                    >
                                                        <span>{{ item.client.utm.utm_source }}</span>
                                                    </p>
                                                    <p v-else>
                                                        &mdash;
                                                    </p>
                                                </div>
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Medium:
                                                    </p>
                                                    <p
                                                        v-if="item.client.utm.utm_medium"
                                                        class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular"
                                                    >
                                                        <span>{{ item.client.utm.utm_medium }}</span>
                                                    </p>
                                                    <p v-else>
                                                        &mdash;
                                                    </p>
                                                </div>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </td>
                            <!-- DUE -->
                            <td
                                class="td_dates"
                                style="background:#eaeaea;"
                            >
                                <div class="d-flex align-center">
                                    <p
                                        :class="`${$date.dateColorDue(item.dates.due_at)}--text`"
                                        class="mb-2 font-weight-bold"
                                    >
                                        {{ statusesWhenHideDueDate.includes(item.status) ? '—' : $date.dateFromNow(item.dates.due_at) }}
                                    </p>
                                    <information-tooltip v-if="statusesWhenHideDueDate.includes(item.status) ? '' : $date.dateFromNow(item.dates.due_at)">
                                        <div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Due:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.dates.due_at ? $date.fullDate(item.dates.due_at) : '—' }}
                                                </p>
                                            </div>
                                        </div>
                                    </information-tooltip>
                                </div>
                            </td>

                            <!-- TOTAL -->
                            <td class="td_status">
                                <div class="order_table--max-width">
                                    <p
                                        v-if="item.fin.total"
                                        class="mb-2 font-weight-bold tw-bg-green-700 tw-text-lg tw-text-white tw-rounded-full tw-px-2 tw-py-1 tw-text-center"
                                    >
                                        {{ item.fin.total | money }}
                                    </p>
                                    <p
                                        v-if="item.fin.total && item.fin.invoice && item.fin.invoice.total > item.fin.total"
                                        class="overline tw-bg-green-500 tw-text-xl tw-text-white tw-rounded-full tw-px-2 tw-py-1 tw-text-center"
                                    >
                                        {{ item.fin.invoice.total | money }}
                                    </p>
                                </div>
                                <div class="d-flex flex-column align-center tw-text-red-500">
                                    <b v-if="item.discount !== '0.00'">{{ item.discount | money }}</b>
                                    <span v-if="item.discount_code"><b>{{ item.discount_code }}</b></span>
                                </div>
                            </td>
                            <!-- TOTAL WRITER -->
                            <td>
                                <div class="order_table--max-width">
                                    <p class="mb-0 font-weight-bold tw-text-red-700">
                                        {{ item.fin.sw_total | money }}
                                    </p>
                                </div>
                            </td>
                            <!-- WRITER DATA -->
                            <td
                                class="td_user"
                                align="center"
                            >
                                <div
                                    v-if="item.writer && item.writer.id"
                                    class="relative mt-2"
                                >
                                    <writer-avatar
                                        :id="item.writer.id"
                                        :feedbacks="item.writer.statistics.rank_all_count"
                                        :feedbacks_thirty_days_count="item.writer.statistics.rank_thirty_days_count"
                                        :rating="item.writer.statistics.rank_all"
                                        :rating_thirty_days="item.writer.statistics.rank_thirty_days"
                                        :applied="item.ts_apply"
                                        :status="item.writer.trust_status"
                                        :firstname="item.writer.firstname"
                                        :lastname="item.writer.lastname"
                                        :nickname="item.writer.nickname"
                                        :avatar-url="item.writer.avatar_url"
                                        :avatar-fallback="item.writer.avatar_fallback"
                                        :order_limit="item.projects_limit"
                                        :country="item.writer.country"
                                        :country-nice-name="item.writer.country_nice_name"
                                    />
                                </div>
                                <p v-else>
                                    —
                                </p>
                            </td>
                            <!-- STATUSES -->
                            <td class="td_status">
                                <div class="">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip
                                                v-if="item.status"
                                                :color="statusBgColor(item.status)"
                                                :text-color="statusTextColor(item.status)"
                                                v-bind="attrs"
                                                class="mb-2 d-flex justify-center"
                                                v-on="on"
                                            >
                                                {{ item.status }}
                                            </v-chip>
                                        </template>
                                        <span>
                                            Client status
                                        </span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip
                                                v-if="item.sw_status"
                                                :color="statusBgColor(item.sw_status)"
                                                :text-color="statusTextColor(item.sw_status)"
                                                v-bind="attrs"
                                                class="mb-2 d-flex justify-center"
                                                v-on="on"
                                            >
                                                {{ item.sw_status }}
                                            </v-chip>
                                        </template>
                                        <span>
                                            Writer status
                                        </span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <!-- open extras -->
                        </tr>
                        <tr
                            :key="`${item.orderid}-exdent`"
                            class="exdent-row"
                            :class="extarsRowClasses(item)"
                        >
                            <td
                                :colspan="tableHeader.length + 1"
                            >
                                <general-extras
                                    :data="item"
                                    :is-show="openedRow.includes(item.orderid)"
                                    @showBatches="onShowBatches($event, item.orderid)"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-data-table>

        <div
            v-if="!tableItems.length && !loading "
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No orders found
            </p>
        </div>

        <pagination-with-perpage
            v-if="!loading"
            :list-lenght="tableItems.length"
            :last-page="lastPage"
            :search-options="{ page, per_page }"
            :total-visible="5"
            @updatePage="paginationPageEvent"
            @updatePerpage="onUpdatePerpage"
        />

        <prefered-blocked-viewer
            v-if="showWriters && showPanel"
            :items="getterWritersShortList"
            :title="writersTitle"
            @closeWriters="onCloseWriters"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import filtersMixin from '@/mixins/filtersMixin'

import General from '@/components/Orders/OrderTable/General'
import GeneralExtras from '@/components/Orders/OrderTable/GeneralExtras'

import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import InformationTooltip from '@/components/shared/InformationTooltip'

import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import Helper from '@/helpers/functions';
import {
    ORDER_TABLE_HEADER, ORDER_TOOLBARS
} from '@/constants/Order'

import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import PreferedBlockedViewer from '@/components/SidePanels/PreferedBlockedViewer';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';

import {
    ACTION_GET_WRITER_SHORT_LIST
} from '@/store/modules/writers/action-types'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

const {
    mapActions: mapWritersActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        General,
        GeneralExtras,
        DomainFavicon,
        PaginationWithPerpage,
        ClientAvatar,
        WriterAvatar,
        PreferedBlockedViewer,
        TableLoader,
        TableSkeleton,
        InformationTooltip
    },
    mixins: [
        filtersMixin
    ],
    props: {
        tableItems: {
            type: Array,
            required: true
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        loading_totals: {
            type: Boolean,
            required: true
        },
        lastPage: {
            type: Number,
            default: 0
        },
        per_page: {
            type: Number,
            default: 10
        },
        currentItem: {
            type: Object
        },
        focusedOrderid: {
            type: String
        }
    },
    data() {
        return {
            selected: [],
            hoveredItem: null,
            page: null,
            isFirstLoad: true,
            sort: {
                sort_by: 'created_at',
                sort_order: 'ASC'
            },
            openedRow: [],
            showWriters: false,
            writersTitle: '',
            writersId: [],
            mobile: false,
            orderId: null,
            showPanel: false
        }
    },
    computed: {
        ...mapGetters('orders', [
            'getterSummary'
        ]),
        ...mapGetters('writers', [
            'getterWritersShortList'
        ]),
        tableHeader() {
            return ORDER_TABLE_HEADER
        },
        tableHeaderLength() {
            return ORDER_TABLE_HEADER.length + 1
        },
        prependHeader() {
            const newArr = [...ORDER_TABLE_HEADER]
            newArr.unshift({ text: 'checkbox', value: 'checkbox' })
            return newArr
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        toolbars() {
            return ORDER_TOOLBARS
        },
        statusesWhenHideDueDate() {
            return ['COMPLETE', 'TO_REFUND', 'UNPAID']
        }
    },
    watch: {
        tableItems: {
            handler() {
                this.selected = []
            },
            deep: true
        },
        selected() {
            this.$emit('selected', this.selected)
        },
        currentPage() {
            this.page = this.currentPage
        },
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    created() {
        this.page = this.currentPage
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        ...mapWritersActions({
            ACTION_GET_WRITER_SHORT_LIST
        }),
        statusText,
        statusBgColor,
        statusTextColor,
        rowClasses(item) {
            return {
                'table-row--updated': item.isUpdated,
                'table-row--opened': this.openedRow.includes(item.orderid),
                'table-row--selected': this.selected.some((select) => select?.id === item.orderid),
                'table-row--selected-once': this.currentItem?.id === item.orderid,
                'table-row--blured': !!this.focusedOrderid && this.focusedOrderid !== item.orderid
            }
        },
        extarsRowClasses(item) {
            return {
                'table-extras-row--opened': this.openedRow.includes(item.orderid),
                'table-extras-row--selected': this.selected.some((select) => select?.orderid === item.orderid)
            }
        },
        onOpenFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        selectItem(item) {
            this.hoveredItem = item
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        sortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            this.sort.sort_by = e.sortBy[0] || 'created_at'
            this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
            if (!this.isFirstLoad) {
                this.$emit('sortTable', { ...this.sort })
            }
            this.isFirstLoad = false
        },
        actionRow(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        showBids(item) {
            this.$emit('showBids', item)
        },
        paginationPageEvent(e) {
            this.$emit('paginate', e)
        },
        onUpdatePerpage(payload) {
            this.$emit('updatePerpage', payload)
        },
        onShowNotes(id) {
            this.$emit('showNotes', id)
        },
        onShowBatches(batchid, id) {
            this.$emit('showBatches', batchid, id)
        },
        onShowTestimonials(id) {
            this.$emit('showTestimonials', id)
        },
        onMenuActivated(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        onOpenPreferredWriters(item) {
            this.showWriters = true
            this.showPanel = true
            this.writersTitle = 'Preffered writers'
            this.writersId = item.writer_preferrences.preferred
            this.getWritersShortList()
        },
        onOpenBlockedWriters(item) {
            this.showWriters = true
            this.showPanel = true
            this.writersTitle = 'Blocked writers'
            this.writersId = item.writer_preferrences.blocked
            this.getWritersShortList()
        },
        onCloseWriters() {
            this.showWriters = false
            this.showPanel = false
        },
        getWritersShortList() {
            this[ACTION_GET_WRITER_SHORT_LIST]({ writers: this.writersId })
        },
        onShowDetails(id) {
            this.$emit('showDetails', id)
        },
        orderID(id) {
            this.orderId = id
        }
    }
}
</script>

<style lang="scss">
    .user_orders_count{
        position: relative;
        &__counter{
            position: absolute;
            top: 0;
            left: 30px;
            background: #59C74D;
            border-radius: 4px;
            color: #fff;
            padding: 3px 4px;
            font-weight: bold;
            font-size: 12px;
            text-align: center;
            line-height: 1;
        }
    }
    .relative{
        position: relative;
    }
    .avatar_rank--check{
        position: absolute !important;
        bottom: 0;
        right: -5px;
        width: 17px;
        height: 17px;
        background: rgba(89, 199, 77, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
