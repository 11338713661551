<template>
    <setting-layout title="Change phone">
        <template #form>
            <v-form
                class="px-5 pb-5"
                @submit.prevent="changePhone"
            >
                <phone-verification
                    ref="phone-verification"
                    @phoneVerivied="onPhoneVerivied"
                />
                <v-btn
                    color="accent"
                    width="100%"
                    height="56px"
                    type="submit"
                    :disabled="phoneValid"
                >
                    Submit
                </v-btn>
            </v-form>
        </template>
    </setting-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import SettingLayout from '@/layout/SettingLayout'
import PhoneVerification from '@/components/shared/PhoneVerification/PhoneVerification'
import { eventBus } from '@/helpers/event-bus';

import {
    ACTION_UPDATE_SELF
} from '@/store/modules/client/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingLayout,
        PhoneVerification
    },
    data() {
        return {
            phone: {},
            phoneRef: null
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        phoneValid() {
            return !this.phoneRef?.isComplete || false
        }
    },
    metaInfo: {
        title: 'Change phone'
    },
    created() {
        this.email = this.getterMainInfo.email
    },
    mounted() {
        this.phoneRef = this.$refs['phone-verification']
    },
    methods: {
        ...mapAdminActions({
            ACTION_UPDATE_SELF
        }),
        onPhoneVerivied(data) {
            this.phone = data
        },
        async changePhone() {
            this.$refs['phone-verification'].validate()
            if (!this.$refs['phone-verification'].isComplete) {
                eventBus.$emit('showSnackBar', 'Please compele phone form', 'error')
            }
            try {
                await this[ACTION_UPDATE_SELF]({ ...this.phone })
                eventBus.$emit('showSnackBar', 'success', 'success')
                this.$router.push({ name: 'user_setting' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style>

</style>
