<template>
    <div class="wrapper py-5 writers-page">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <the-filter-preset
                        :active-preset="searchOptions.preset"
                        :presets="getterPresets"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>

            <v-data-table
                :headers="tableHeader"
                item-key="sw_id"
                :options.sync="options"
                :items="getterWritersList"
                :server-items-length="getterTotal"
                :loading="getterListLoading"
                hide-default-footer
                calculate-widths
                class="mt-6"
                must-sort
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:options="updateOptions"
            >
                <template #header="{ }">
                    <thead
                        v-if="!mobile"
                        class="v-data-table-header"
                    >
                        <tr>
                            <th
                                v-for="h in tableHeader"
                                :key="h.value"
                            >
                                <template v-if="h.value === 'lastname'">
                                    <div class="d-flex align-center">
                                        <div class="mr-4">
                                            <p class="mb-0 grey--text">
                                                Total:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterSummary.count | number }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="h.value === 'orders_complete_sum_sw_total'">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Total earnings:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.total_earning | money }}</b>
                                        </p>
                                    </div>
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <thead v-else>
                        <tr>
                            <th class="d-flex">
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Total:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.count | number }}</b>
                                        </p>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="getterListLoading"
                    />
                    <tbody>
                        <template v-for="(item, index) in items">
                            <tr
                                :key="item.sw_id"
                                class="hovered-row"
                                :class="rowClasses(item.sw_id)"
                                @mouseenter="selectItem(item.sw_id)"
                                @mouseleave="unSelectItem()"
                            >
                                <!-- Index -->
                                <td class="writer-table__index td_index">
                                    <p class="text-center mb-0">
                                        {{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}
                                    </p>
                                </td>
                                <!-- GENERAL -->
                                <td style="position: sticky; left: 0;z-index: 9; background-color: white">
                                    <general
                                        :data="item"
                                        @showNotes="onShowNotes"
                                    />
                                </td>
                                <!-- COUNTRY -->
                                <td class="writer-table__country">
                                    <div
                                        v-if="item.country"
                                        class="mb-2 d-flex align-center"
                                    >
                                        <v-tooltip
                                            top
                                            color="primary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    :class="'ml-2 flag-icon flag-input-icon flag-icon-' + item.country.toLowerCase()"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <span>{{ item.country_nice_name }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </td>
                                <!-- Rating -->
                                <td class="writer-table__rating">
                                    <rating
                                        v-if="item.statistics_parsed && item.statistics_parsed.rank"
                                        :rank_all_count="item.statistics_parsed.rank.rank_all_count"
                                        :rank_all="item.statistics_parsed.rank.rank_all"
                                        :rank_thirty_days=" item.statistics_parsed.rank.rank_thirty_days"
                                    />
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <td class="writer-table__rating">
                                    <div v-if="item.exams.length">
                                        <v-tooltip
                                            top
                                            color="primary"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <b
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-avatar
                                                        color="rgba(39, 217, 31, 0.1)"
                                                        size="43"
                                                        style="cursor: pointer"
                                                    >
                                                        <div class="font-weight-bold text-body-2 success--text">
                                                            <span style="font-size: 18px;">{{ item.exams.length }}</span>
                                                        </div>
                                                    </v-avatar>
                                                </b>
                                            </template>
                                            <div
                                                v-for="(exam, idx) in item.exams"
                                                :key="idx"
                                                class="d-flex align-center"
                                                style="gap: 20px"
                                            >
                                                <div
                                                    class="mb-4"
                                                    style="width: 175px"
                                                >
                                                    <p class="mb-0 text-body-2">
                                                        Created at
                                                    </p>
                                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                        <span>{{ $date.fullDate(exam.created_at) }}</span>
                                                    </p>
                                                </div>
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Answers total
                                                    </p>
                                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                        <span style="font-size: 18px;">{{ exam.answers_total }}</span>
                                                    </p>
                                                </div>
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Correct answers
                                                    </p>
                                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                        <span style="font-size: 18px;">{{ exam.count_correct_answer }}</span>
                                                    </p>
                                                </div>
                                                <div class="mb-4">
                                                    <p class="mb-0 text-body-2">
                                                        Questions total
                                                    </p>
                                                    <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                        <span style="font-size: 18px;">{{ exam.questions_total }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                    <span v-else>-</span>
                                </td>
                                <!-- Login / Request -->
                                <!-- <td>
                                    <last-login :data="item" />
                                </td> -->
                                <!-- APPLIED -->
                                <td>
                                    <p class="text-body-2 mb-2">
                                        {{ $date.relativeTimeFromNow(item.created_at) }}
                                        <br>
                                        {{ $date.humanizeDate(item.created_at) }}
                                    </p>
                                </td>
                                <td>
                                    <div class="d-flex mt-3">
                                        <span
                                            class="online-status"
                                            :class="{'online-status--green': item.is_online}"
                                        />
                                    </div>
                                </td>
                                <!-- PAYMENTS -->
                                <td>
                                    <p
                                        v-if="item.statistics_parsed && item.statistics_parsed.money"
                                        class="font-weight-bold"
                                    >
                                        {{ item.statistics_parsed.money.earnings | money }}
                                    </p>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- balance -->
                                <td>
                                    <p
                                        v-if="item.statistics_parsed && item.statistics_parsed.money"
                                        class="font-weight-bold"
                                    >
                                        {{ item.statistics_parsed.money.balance | money }}
                                    </p>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- reviews -->
                                <td>
                                    <p
                                        v-if="item.statistics_parsed && item.statistics_parsed.rank"
                                        class="font-weight-bold"
                                    >
                                        {{ item.statistics_parsed.rank.rank_all_count | number }}
                                    </p>
                                    <span v-else>
                                        —
                                    </span>
                                </td>
                                <!-- ACCESSSTATUS -->
                                <td>
                                    <access-status
                                        :accessstatus="item.accessstatus"
                                        :mobile="mobile"
                                    />
                                </td>
                                <!-- ACTIVE STATUS -->
                                <td>
                                    <img
                                        v-if="item.is_active"
                                        src="@images/svg/trusted_writer.svg"
                                        alt="trusted_writer"
                                        width="21"
                                        height="21"
                                        class="mt-1"
                                    >
                                    <img
                                        v-else
                                        src="@images/svg/new_writer.svg"
                                        alt="trusted_writer"
                                        width="21"
                                        height="21"
                                        class="mt-1"
                                    >
                                </td>
                                <td>
                                    <p class="text-body-2 font-weight-medium">
                                        {{ item.projects_limit }}
                                    </p>
                                </td>
                                <td>
                                    <div class="d-flex align-center">
                                        <div class="d-flex flex-column justify-center align-center mr-5">
                                            <table-rows-menu
                                                :toolbars="item.accessstatus === 'APPLICANT' ? applicantToolbars : toolbars"
                                                :item="item"
                                                @menuActivated="onMenuActivated"
                                            />
                                            <v-btn
                                                v-if="openedRow.includes(item.sw_id)"
                                                icon
                                                @click="openFullRow(item.sw_id)"
                                            >
                                                <v-icon>
                                                    mdi-chevron-up
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                icon
                                                @click="openFullRow(item.sw_id)"
                                            >
                                                <v-icon>
                                                    mdi-chevron-down
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                        <router-link
                                            :to="{ name: setRoute, params: { id: item.sw_id } }"
                                        >
                                            <v-btn color="grey-lighten-3">
                                                details
                                            </v-btn>
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                :key="`${item.sw_id}-exdent`"
                                class="exdent-row"
                                :class="extarsRowClasses(item)"
                            >
                                <td
                                    :colspan="tableHeader.length + 1"
                                >
                                    <general-extras
                                        :data="item"
                                        :is-show="openedRow.includes(item.sw_id)"
                                    />
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </template>
            </v-data-table>
            <div
                v-if="!getterWritersList.length && !getterListLoading"
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No data found
                </p>
            </div>

            <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterWritersList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <!-- NOTES -->
            <writer-notes-viewer
                v-if="showNotes"
                :writer_id="currentItem.sw_id"
                @closeNotes="onCloseNotes"
                @deleteNote="onDeleteNote"
            />

            <component
                :is="modalComponent"
                :writer="currentItem"
                :writerid="currentItem.sw_id"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :trust-statuses="trustStatuses"
                :file-types="fileTypes"
                :form-data-type-key="modal.typeKey"
                :writer-limit="+currentItem.projects_limit"
                :allowed-file-types="allowedFileTypes"
                :loading="loadingInModal"
                :current="createTicketPrepend"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import filterMixin from '@/mixins/filtersMixin'

// PAGE COMPONENT
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

// MODALS
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalWriterTrust from '@/components/shared/Modals/ModalWriterTrust'
import ModalWriterEdit from '@/components/shared/Modals/ModalWriterEdit'
import ModalUpload from '@/components/shared/Modals/ModalUpload'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'
import ModalWriterCredits from '@/components/shared/Modals/ModalWriterCredits'
import ModalWriterUpdate from '@/components/shared/Modals/ModalWriterUpdate'
import ModalAvatarUpload from '@/components/shared/Modals/ModalAvatarUpload'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'

import {
    ACTION_CHANGE_AVATAR,
    ACTION_EDIT_UPDATE,
    ACTION_GET_FETCH_WRITER,
    ACTION_GET_PRESETS_WRITER,
    ACTION_GET_WRITERS_LIST,
    ACTION_SET_STATUS_TRUSTED_TO_NEW,
    ACTION_SET_STATUS_TRUSTED_TO_TRUSTED,
    ACTION_UPLOAD_WRITER_FILE,
    ACTION_WRITER_ACTIVATE,
    ACTION_WRITER_DEACTIVATE,
    ACTION_WRITER_EDIT,
    ACTION_WRITER_POST_NOTE,
    ACTION_GET_WRITERS_TOTALS,
    ACTION_REFRESH_STATISTIC,
    ACTION_APPROVE_APPLICANT,
    ACTION_APPLICANT_DEACTIVATE,
    ACTION_WRITER_CREDIT
} from '@/store/modules/writers/action-types';

import { CLEAR_WRITERS_LIST } from '@/store/modules/writers/mutation-types';

import {
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';

// TABLE COMPONENT
import General from '@/components/Writer/WriterTable/General'
import Rating from '@/components/Writer/WriterTable/Rating'
import LastLogin from '@/components/Writer/WriterTable/LastLogin'

import {
    PER_PAGE
} from '@/constants/PerPages'

import {
    ALLOWED_FILE_TYPES, TRUST_STATUSES, WRITERS_TABLE_HEADER, WRITERS_TOOLBARS, TOOLBARS_APPLICANT
} from '@/constants/Writers'

import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import WriterNotesViewer from '@/components/SidePanels/WriterNotesViewer'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import AccessStatus from '@/components/Writer/WriterTable/AccessStatus.vue';
import GeneralExtras from '@/components/Writer/WriterTable/GeneralExtras'

const {
    mapActions: mapAdminActions,
    mapMutations: mapWritersMutations
} = createNamespacedHelpers('writers');

const {
    mapActions: mapAdminActionsSupport
} = createNamespacedHelpers('support');

export default {
    components: {
        ToolbarMenu,
        General,
        Rating,
        LastLogin,
        TheMainFilter,
        TheFilterPreset,
        TableRowsMenu,
        WriterNotesViewer,
        // Information,
        // MODALS
        ModalAlert,
        ModalWriterTrust,
        ModalWriterEdit,
        ModalUpload,
        ModalTextarea,
        ModalCreateTicketWriter,
        ModalWriterUpdate,
        PaginationWithPerpage,
        TableSkeleton,
        ModalAvatarUpload,
        TableLoader,
        AccessStatus,
        GeneralExtras,
        ModalWriterCredits
    },
    mixins: [
        filterMixin
    ],
    data() {
        return {
            loading: false,
            openedModal: '',
            modal: {},
            writers: [],
            options: {},
            isFirstLoad: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                status: '',
                search_for: '',
                search_by: 'sw_id',
                from: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                to: '',
                preset: ''
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            hoveredItem: {},
            currentItem: {},
            actionName: '',
            fileTypes: [],
            // notes panel
            showNotes: false,
            notesWriterid: null,
            search_fields: null,
            savedScroll: { x: 0, y: 0 },
            openedRow: [],
            loadingInModal: false,
            mobile: false
        }
    },
    metaInfo: {
        title: 'Writers'
    },
    computed: {
        perPages() {
            return PER_PAGE
        },
        ...mapGetters('writers', [
            'getterWritersList',
            'getterLastPage',
            'getterTotal',
            'getterCurrentPage',
            'getterPresets',
            'getterListLoading',
            'getterTotalsLoading',
            'getterSummary'
        ]),
        tableHeader() {
            return WRITERS_TABLE_HEADER
        },
        tableHeaderLength() {
            return WRITERS_TABLE_HEADER.length + 1
        },
        toolbars() {
            return WRITERS_TOOLBARS
        },
        applicantToolbars() {
            return TOOLBARS_APPLICANT
        },
        modalComponent() {
            return this.modal.name || ''
        },
        trustStatuses() {
            return TRUST_STATUSES
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        createTicketPrepend() {
            return {
                sw_id: this.currentItem.sw_id,
                writer: {}
            }
        },
        setRoute() {
            if (this.$route.name === 'applicant') {
                return 'applicant-profile'
            }
            return 'writer-profile'
        }
    },
    async activated() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        })
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        if (!this.isFirstLoad) {
            requests.push(this.getWritersList())
        }
        await Promise.allSettled(requests)
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        if (!to.name.includes('writer')) {
            this[CLEAR_WRITERS_LIST]()
        }
        next()
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('writer')
        },
        ...mapAdminActions({
            ACTION_GET_WRITERS_LIST,
            ACTION_GET_FETCH_WRITER,
            ACTION_WRITER_ACTIVATE,
            ACTION_WRITER_DEACTIVATE,
            ACTION_WRITER_POST_NOTE,
            ACTION_WRITER_EDIT,
            ACTION_SET_STATUS_TRUSTED_TO_TRUSTED,
            ACTION_SET_STATUS_TRUSTED_TO_NEW,
            ACTION_UPLOAD_WRITER_FILE,
            ACTION_EDIT_UPDATE,
            ACTION_CHANGE_AVATAR,
            ACTION_GET_PRESETS_WRITER,
            ACTION_GET_WRITERS_TOTALS,
            ACTION_REFRESH_STATISTIC,
            ACTION_APPROVE_APPLICANT,
            ACTION_APPLICANT_DEACTIVATE,
            ACTION_WRITER_CREDIT
        }),
        ...mapWritersMutations({
            CLEAR_WRITERS_LIST
        }),
        ...mapAdminActionsSupport({
            ACTION_CREATE_TICKET_WRITER
        }),
        // HOVER ON TABLE ROW
        selectItem(item) {
            this.hoveredItem = item
        },
        rowClasses(id) {
            return {
                'table-row--opened': this.openedRow.includes(id),
                'table-row--selected-once': this.currentItem?.sw_id === id
            }
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        // TABLE LOGIC
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getWritersList(false)
        },
        async getWritersList(with_total = true) {
            try {
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const requestArr = [this[ACTION_GET_WRITERS_LIST]({ ...this.searchOptions, mode: 'list' }), this[ACTION_GET_PRESETS_WRITER]()]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_WRITERS_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getWritersList()
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort,
                    page: 1
                }
                await this.getWritersList()
            }
        },
        // MODALS ACTION
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        // Modals logic
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'activate':
                    await this.activateWriter()
                    break
                case 'notes':
                    await this.postNote({ ...payload })
                    break
                case 'edit_writer':
                    await this.editWriter({ ...payload })
                    break
                case 'contact_writer':
                    await this.contactWriter({ ...payload })
                    break
                case 'deactivate':
                    await this.deactivateWriter()
                    break
                case 'edit_trust_status':
                    await this.editTrustStatus(payload)
                    break
                case 'upload_files':
                    await this.uploadFiles(payload)
                    break
                case 'edit_limit':
                    await this.updateWriter(payload)
                    break
                case 'change_avatar':
                    await this.writerAvatarChage(payload)
                    break
                case 'statistic_refresh':
                    await this.refreshStatistic(payload)
                    break
                case 'applicant_approve':
                    await this.approveApplicant()
                    break
                case 'applicant_deactivate':
                    await this.deactivateApplicant()
                    break
                case 'credits':
                    await this.creditWriter({ ...payload })
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        async activateWriter() {
            await this[ACTION_WRITER_ACTIVATE]({ writer_id: this.currentItem.sw_id })
        },
        async writerAvatarChage(payload) {
            payload.append('sw_id', this.currentItem.sw_id)
            await this[ACTION_CHANGE_AVATAR](payload)
        },
        async deactivateWriter() {
            await this[ACTION_WRITER_DEACTIVATE]({ writer_id: this.currentItem.sw_id })
        },
        async postNote(payload) {
            await this[ACTION_WRITER_POST_NOTE]({ writer_id: this.currentItem.sw_id, ...payload })
        },
        async editWriter(payloads) {
            await this[ACTION_WRITER_EDIT](payloads)
        },
        async contactWriter(payload) {
            await this[ACTION_CREATE_TICKET_WRITER](payload)
        },
        async editTrustStatus(payload) {
            if (payload.trust_status === 'TRUSTED') {
                await this[ACTION_SET_STATUS_TRUSTED_TO_TRUSTED]({ writers: this.currentItem.sw_id })
                return
            }
            await this[ACTION_SET_STATUS_TRUSTED_TO_NEW]({ writers: this.currentItem.sw_id })
        },
        async updateWriter(payload) {
            await this[ACTION_EDIT_UPDATE]({ writer_id: this.currentItem.sw_id, ...payload })
        },
        async refreshStatistic() {
            await this[ACTION_REFRESH_STATISTIC]({ writer_id: this.currentItem.sw_id })
            await this.getWritersList()
        },
        async uploadFiles({ formData, callback }) {
            const { sw_id } = this.currentItem
            formData.append('sw_id', sw_id)
            await this[ACTION_UPLOAD_WRITER_FILE]({
                formData, callback
            })
        },
        async approveApplicant() {
            const { sw_id } = this.currentItem
            await this[ACTION_APPROVE_APPLICANT]({ writer_id: sw_id })
        },
        async deactivateApplicant() {
            const { sw_id } = this.currentItem
            await this[ACTION_APPLICANT_DEACTIVATE]({ writer_id: sw_id })
        },
        async creditWriter(payload) {
            const { sw_id } = this.currentItem
            await this[ACTION_WRITER_CREDIT]({ sw_id, ...payload })
        },
        // NOTES
        onShowNotes(writerid) {
            this.showNotes = true
            this.currentItem.sw_id = writerid
        },
        onCloseNotes() {
            this.showNotes = false
            this.currentItem = {}
        },
        onDeleteNote() {
        },
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        extarsRowClasses(item) {
            return {
                'table-extras-row--opened': this.openedRow.includes(item.sw_id)
            }
        }
    }
}
</script>

<style lang="scss">
    .order_table__total{
        word-wrap: break-word;
    }
    .order_table--max-width{
        max-width: 130px;
    }
    .writers-page{
        .v-data-table__wrapper{
            table {
                tbody {
                    & > tr{
                        position: relative !important;
                        // & > td {
                        //     padding-bottom: 30px !important;
                        // }
                    }
                }
            }
        }
        .action_block{
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            &--active{
                opacity: 1;
            }
            .resolved-btn{
                display: flex;
                align-items: center;
                font-size: 12px;
            }
        }
        .v-item-group.v-bottom-navigation .v-btn{
            max-width: initial;
        }
    }
    .online-status{
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
        box-shadow: 0 0 25px 2px red;
        &--green {
            background: green;
            box-shadow: 0 0 25px 2px green;
        }
    }
    @media (max-width: 599px) {
        .v-data-table  .writer-table {
            &__country, &__index {
                display: none;
            }
        }
        .v-data-table > .v-data-table__wrapper > table > tbody > tr > td.writer-table__rating {
            padding-right: 15px !important;
        }
    }
</style>
