<template>
    <modal
        :loading="loading"
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Assign order</span>
        </template>
        <template #body>
            <p>
                This action is used to assign available orders to a specific writer.
            </p>

            <v-row dense>
                <v-col cols="3">
                    <v-select
                        v-model="search_by"
                        :items="search_by_list"
                        label="Search by"
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col cols="9">
                    <v-autocomplete
                        v-model="model"
                        :items="writers"
                        :search-input.sync="search"
                        color="accent"
                        :item-text="search_by"
                        :item-value="search_by"
                        label="Writer"
                        placeholder="Start typing to Search"
                        outlined
                        hide-details
                        return-object
                    >
                        <template
                            slot="selection"
                            slot-scope="{ item }"
                        >
                            <writer-avatar
                                :id="item.sw_id"
                                :feedbacks="item.fb_reviews"
                                :rating="item.fb_rating"
                                :rating_rc="item.rank_rc"
                                :applied="item.ts_apply"
                                :status="item.trust_status"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                :order_limit="item.projects_limit"
                                :country="item.country"
                            />
                            <div class="d-flex flex-column ml-8">
                                <span class="">{{ item.firstname }} {{ item.lastname }}</span>
                                <span class="">ID: {{ item.sw_id }}</span>
                            </div>
                        </template>
                        <template
                            slot="item"
                            slot-scope="{ item }"
                        >
                            <writer-avatar
                                :id="item.sw_id"
                                :feedbacks="item.fb_reviews"
                                :rating="item.fb_rating"
                                :rating_rc="item.rank_rc"
                                :applied="item.ts_apply"
                                :status="item.trust_status"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                :order_limit="item.projects_limit"
                                :country="item.country"
                            />
                            <div class="d-flex flex-column ml-8">
                                <span class="text-caption">{{ item.firstname }} {{ item.lastname }}</span>
                                <span class="text-caption">ID: {{ item.sw_id }}</span>
                            </div>
                        </template>
                        <template v-slot:append-item>
                            <div v-intersect="endWriter" />
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </template>
    </modal>
</template>

<script>
import { debounce } from 'lodash'

import Api from '@/helpers/api/index.js'

import Modal from '@/components/shared/Modals/Modal'

import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'

import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

export default {
    components: {
        Modal,
        WriterAvatar
    },
    props: {
        loading: {
            type: Boolean,
            defalut: false
        }
    },
    data() {
        return {
            showModal: true,
            isLoading: true,
            writers: [],
            model: null,
            search: null,
            lastPage: null,
            writerPage: 1,
            search_by: 'sw_id'
        }
    },
    computed: {
        search_by_list() {
            return [
                {
                    text: 'Writer ID',
                    value: 'sw_id'
                },
                {
                    text: 'Firstname',
                    value: 'firstname'
                },
                {
                    text: 'Lastname',
                    value: 'lastname'
                }
            ]
        }
    },
    watch: {
        search: debounce(async function (val) {
            this.writerPage = 1
            const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                search_by: this.search_by, search_for: val, page: this.writerPage, accessstatus: 'WRITER', mode: 'list'
            }))
            const { data: totals } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                search_by: this.search_by, search_for: val, page: this.writerPage, accessstatus: 'WRITER', mode: 'count'
            }))
            this.lastPage = Math.ceil(totals.count / 10)
            this.writers = [...this.writers, ...data.data]
            this.$forceUpdate()
        }, 500)
    },
    async created() {
        await this.getWriters()
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                ...this.model
            })
        },
        async getWriters(payload) {
            const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                ...payload, page: this.writerPage, accessstatus: 'WRITER', mode: 'list'
            }))
            const { data: totals } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                ...payload, page: this.writerPage, accessstatus: 'WRITER', mode: 'count'
            }))
            this.lastPage = Math.ceil(totals.count / 10)
            this.writers = [...this.writers, ...data.data]
        },
        async endWriter(entries, observer, isIntersecting) {
            if (isIntersecting && this.lastPage > this.writerPage) {
                this.writerPage += 1
                await this.getWriters({ search_by: this.search_by, search_for: this.writerSearch })
            }
        },
        getAvatar(avatar) {
            if (avatar.includes('http')) {
                return avatar
            }
            // eslint-disable-next-line
            return `/img/avatars/${avatar}`
        }
    }
}
</script>

<style lang="scss">
    .avatar_wrapper{
        position: relative;
        &--check{
            position: absolute !important;
            top: 0;
            right: 5px;
            width: 17px;
            height: 17px;
            background: rgba(89, 199, 77, 0.1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
