<template>
    <viewer
        :title="'Testimonials'"
        @closeSidePanel="closeTestimonials"
    >
        <template #body>
            <v-row>
                <v-col
                    cols="12"
                    class="pt-8"
                >
                    <template
                        v-for="(feedback, index) in getterFeedbacksList"
                    >
                        <feedback-card
                            :ref="`card-${feedback.id}`"
                            :key="index"
                            :feedback="feedback"
                            :with-actions="false"
                        />
                    </template>

                    <pagination-with-perpage
                        :list-lenght="getterFeedbacksList.length"
                        :last-page="getterLastPage"
                        :search-options="searchOptions"
                        :total-visible="7"
                        @updatePage="paginationPageEvent"
                        @updatePerpage="onUpdatePerpage"
                    />
                    <div
                        v-if="!getterFeedbacksList.length"
                        class="d-flex flex-column align-center w-full mt-5 mb-5"
                    >
                        <div class="">
                            <img
                                src="@images/svg/no-data.svg"
                                alt=""
                            >
                        </div>
                        <p class="text-h6">
                            No data found
                        </p>
                    </div>
                </v-col>
            </v-row>
        </template>
    </viewer>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST,
    ACTION_SET_STATUS_FEEDBACK,
    ACTION_GET_FEEDBACKS_TOTALS
} from '@/store/modules/feedbacks/action-types';
import Viewer from '@/components/SidePanels/Viewer'
import FeedbackCard from '@/components/shared/Cards/FeedbackCard';
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('feedbacks');

export default {
    name: 'OrderFeedback',
    components: {
        Viewer,
        FeedbackCard,
        PaginationWithPerpage
    },
    props: {
        orderid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            searchOptions: {
                search_by: 'orderid',
                search_for: this.orderid,
                page: 1,
                per_page: 10
            }
        }
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Feedback | Order`
        }
    },
    computed: {
        ...mapGetters('feedbacks', [
            'getterFeedbacksList',
            'getterLastPage'
        ])
    },
    async created() {
        await this.getOrderFeedbacks()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_FEEDBACKS_LIST,
            ACTION_EDIT_FEEDBACK,
            ACTION_CREATE_TICKET_FEEDBACK,
            ACTION_SET_STATUS_FEEDBACK,
            ACTION_GET_FEEDBACKS_TOTALS
        }),
        async paginationPageEvent(payload) {
            this.searchOptions.page = payload
            this.getOrderFeedbacks()
        },
        onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            this.getOrderFeedbacks()
        },
        async getOrderFeedbacks(with_total = true) {
            if (!this.orderid) { return }
            try {
                const requestArr = [this[ACTION_GET_FEEDBACKS_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_FEEDBACKS_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoading = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        closeTestimonials() {
            this.$emit('closeTestimonials')
        }
    }
}
</script>
