<template>
    <v-chip
        v-if="rate"
        :color="statusColor.bg"
    >
        <span :class="statusColor.text">{{ rate * 2 + ' / 10' }}</span>
    </v-chip>
</template>
<script>
export default {
    name: 'StatusChip',
    props: {
        rate: {
            type: Number,
            required: true
        }
    },
    computed: {
        statusColor() {
            switch (true) {
            case ((this.rate * 2) >= 9): {
                return {
                    bg: 'rgba(39, 217, 31, 0.1)',
                    text: 'success--text'
                }
            }
            case ((this.rate * 2) < 5): {
                return {
                    bg: 'rgba(255, 0, 0, 0.1)',
                    text: 'error--text'
                }
            }
            default: {
                return {
                    bg: 'rgba(240, 125, 0, 0.1)',
                    text: 'accent--text'
                }
            }
            }
        }
    }
}
</script>
