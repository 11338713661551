<template>
    <div class="my-5">
        <v-btn
            color="primary"
            class=" mb-5"
            @click="createTicket"
        >
            New ticket
        </v-btn>
        <div
            v-if="!items.length && !listLoading"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No tickets found
            </p>
        </div>
        <div
            v-if="listLoading"
            class="d-flex w-full justify-center my-5"
        >
            <v-progress-circular
                :size="35"
                color="primary"
                indeterminate
            />
        </div>
        <v-row
            v-for="(ticket, index) in items"
            v-else
            :key="ticket.id"
            :class="index !== 0 && 'my-4'"
        >
            <v-col
                cols="12"
            >
                <support-card
                    :index="(per_page * (page - 1)) + index + 1"
                    :ticket="ticket"
                    :show-chat="openTicket === ticket.ticketid"
                    @showChat="onShowChat"
                    @actionTicket="onActionTicket"
                    @actionChat="onActionChat"
                />
            </v-col>
        </v-row>

        <pagination-with-perpage
            :list-lenght="items.length"
            :last-page="lastPage"
            :search-options="{ page, per_page }"
            :total-visible="7"
            @updatePage="paginationPageEvent"
            @updatePerpage="onUpdatePerpage"
        />

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :current="order"
            :loading="loadingInModal"
            input-label="Write something that you want to remind"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import SupportCard from '@/components/shared/Cards/SupportCard'

import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalCreateTicketWriter from '@/components/shared/Modals/ModalCreateTicketWriter'

import {
    ACTION_CREATE_TICKET,
    ACTION_CREATE_TICKET_WRITER
} from '@/store/modules/support/action-types';

const {
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    name: 'TicketsListComponent',
    components: {
        SupportCard,
        ModalAlert,
        ModalTransfer,
        ModalTextarea,
        PaginationWithPerpage,
        ModalCreateTicketClient,
        ModalCreateTicketWriter
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        lastPage: {
            type: [Number, String],
            default: 0
        },
        order: {
            type: Object,
            required: false
        },
        orderid: {
            type: String,
            required: false,
            default: ''
        },
        isClient: {
            type: Boolean,
            required: false
        },
        listLoading: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            page: 1,
            per_page: 10,
            openTicket: '',
            modal: {},
            actionName: '',
            currentId: null,
            currentBdId: null,
            loadingInModal: false
        }
    },
    computed: {
        modalComponent() {
            return this.modal?.name || ''
        }
    },
    methods: {
        ...mapSupportActions({
            ACTION_CREATE_TICKET_WRITER,
            ACTION_CREATE_TICKET
        }),
        async paginationPageEvent(e) {
            this.page = e
            this.$emit('update', { page: this.page, per_page: this.per_page })
        },
        // TICKETS LOGIC
        async createBookmark(payload) {
            this.$emit('bookmark', { ...payload, referenceids: [this.currentId] })
        },
        async closeTicket() {
            this.$emit('close', { id: this.currentId })
        },
        async createClientTicket(payload) {
            await this[ACTION_CREATE_TICKET]({
                orderid: this.orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                msgcategory: '',
                msgresolution: '',
                ...payload
            })
            this.$emit('update:counters', { type: 'tickets-client', counter: 1 })
        },
        async createWriterTicket(payload) {
            await this[ACTION_CREATE_TICKET_WRITER]({
                orderid: this.orderid,
                status_priority: 'NORMAL',
                subject: 'inquiry',
                msgtype: 'inquiry',
                msgcategory: '',
                msgresolution: '',
                ...payload
            })
            this.$emit('update:counters', { type: 'tickets', counter: 1 })
        },
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'close_ticket':
                    await this.closeTicket()
                    break
                case 'bookmark':
                    this.createBookmark(payload)
                    break
                case 'client':
                    await this.createClientTicket(payload)
                    break
                case 'message':
                    await this.createWriterTicket(payload)
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        onCloseModal() {
            this.actionName = ''
            this.modal = {}
            this.currentId = ''
        },
        onActionTicket(payload) {
            this.actionName = payload.toolbar.name
            this.modal = payload.toolbar.modal
            this.currentId = payload.item.ticketid
            this.currentBdId = payload.item.id
        },
        async onActionChat(payload) {
            this.$emit('send', { ...payload })
        },
        onUpdatePerpage(payload) {
            this.page = payload.page
            this.per_page = payload.per_page
            this.$emit('updatePerpage', payload)
        },
        createTicket() {
            if (this.isClient) {
                this.actionName = 'client'
                this.modal = {
                    name: 'ModalCreateTicketClient',
                    title: 'Contact the client',
                    description: 'This tool is used to contact the client. A support ticket will be generated and the client will get an e-mail notification.',
                    inputLabel: 'Client Ticket Details',
                    success_message: 'The ticket for the client has been created'
                }
            } else {
                this.actionName = 'message'
                this.modal = {
                    name: 'ModalCreateTicketWriter',
                    title: 'Contact the writer',
                    description: 'This tool is used to contact the writer. A support ticket will be generated and the writer will get an e-mail notification.',
                    inputLabel: 'Details',
                    success_message: 'The ticket for the writer has been created'
                }
            }
        },
        onShowChat(id) {
            if (this.openTicket === id) {
                this.openTicket = ''
                return
            }
            this.openTicket = id
        }
    }
}
</script>

<style>

</style>
