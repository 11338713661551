import { MASS_TOOLBARS, TOOLBARS } from './order-toolbars'
import { ALLOWED_TYPES, FILES_TABLE_HEAD } from './order-files'

export const MASS_ORDER_TOOLBARS = MASS_TOOLBARS
export const ORDER_TOOLBARS = TOOLBARS
export const ORDER_FILES_TABLE_HEAD = FILES_TABLE_HEAD
export const ALLOWED_FILE_TYPES = ALLOWED_TYPES

export const ORDER_TABLE_HEADER = [
    {
        text: 'Ordered',
        sortable: true,
        value: 'created_at',
        width: '130px'
    },
    {
        text: 'General',
        sortable: false,
        value: 'orderid'
    },
    {
        text: 'Domain',
        sortable: false,
        value: 'domain',
        align: 'center',
        width: '90px'
    },
    {
        text: 'Client',
        sortable: true,
        value: 'client_id',
        width: '80px'
    },
    {
        text: 'Due date',
        sortable: true,
        value: 'due_at',
        width: '120px'
    },
    {
        text: 'Total',
        sortable: true,
        value: 'total',
        width: '80px'
    },
    {
        text: 'Tot. (wr)',
        sortable: true,
        value: 'sw_total',
        width: '80px'
    },
    {
        text: 'Writer',
        sortable: true,
        value: 'writer_id',
        width: '75px'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'status',
        width: '60px'
    }
]
