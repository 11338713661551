<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-alert
                dense
                type="info"
                dark
                class="py-5"
                color="rgba(255, 84, 4, 0.1)"
                icon="mdi-alert-circle-outline"
            >
                <div class="black--text">
                    {{ description }}
                </div>
            </v-alert>
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <!-- <v-autocomplete
                            ref="orderid"
                            v-model="orderid"
                            color="accent"
                            outlined
                            :items="orders"
                            :loading="orderLoading"
                            item-text="orderid"
                            item-value="orderid"
                            :search-input.sync="orderSearch"
                            hide-details
                            label="Order ID"
                        >
                            <template v-slot:append-item>
                                <div v-intersect="endOrderid" />
                            </template>
                        </v-autocomplete> -->
                        <v-select
                            v-model="trust_status"
                            :items="trustStatuses"
                            label="Trust status"
                            :error="!isValid"
                            outlined
                            @change="validate()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        trustStatuses: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            trust_status: '',
            isValid: true
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.validate()
            if (this.isValid) {
                this.$emit('confirmModal', {
                    trust_status: this.trust_status
                })
            }
        },
        validate() {
            if (this.trust_status.length) {
                this.isValid = true
                return
            }
            this.isValid = false
        }
    }
}
</script>

<style>

</style>
