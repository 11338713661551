<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card
                :elevation="hover ? 6 : 2"
                class="px-5 py-5"
                min-width="100%"
                outlined
                hover
            >
                <v-row>
                    <v-col
                        cols="12"
                        class="d-flex flex-column"
                    >
                        <div class="d-flex align-center">
                            <div class="d-flex w-full align-center mb-2">
                                <status-colored
                                    v-if="ticket.types.type"
                                    :type="ticket.types.type"
                                    class="mb-0 mr-2"
                                />
                                <status-colored
                                    v-if="ticket.types.category"
                                    :type="ticket.types.category"
                                    class="mb-0 mr-2"
                                />
                                <status-colored
                                    v-if="ticket.types.resolution"
                                    :type="ticket.types.resolution"
                                    class="mb-0 mr-2"
                                />
                            </div>
                            <v-col
                                class="d-flex align-center justify-end"
                                style="cursor: pointer"
                                @click="slideChat"
                            >
                                <v-icon v-if="showChat">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </v-col>
                        </div>
                        <div class="d-flex align-center">
                            <span
                                v-if="index"
                                class="text-subtitle-1 grey--text mr-2"
                            >
                                {{ index }}.
                            </span>

                            <div
                                class="text-subtitle-1 black--text text-decoration-none d-flex w-full d-flex align-center"
                            >
                                <router-link
                                    class="text-subtitle-1 black--text text-decoration-none"
                                    :to="{path: `/support/${isClientTicket ? 'client' : 'writer'}/${ticket.ticketid}`}"
                                >
                                    «{{ ticket.subject }}»
                                </router-link>
                                <status-colored
                                    :type="ticket.status_support"
                                    class="ml-5"
                                />
                                <span class="grey--text ml-5 text-body-1">
                                    {{ $date.relativeTimeFromNow(ticket.last_message.created_at) }}
                                </span>
                                <div class="ml-auto">
                                    <template v-for="toolbar in supportActions">
                                        <v-tooltip
                                            :key="toolbar.name"
                                            top
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    tile
                                                    color="rgba(255, 0, 0, 0)"
                                                    v-bind="attrs"
                                                    :disabled="toolbar.name === 'close_ticket' && ticket.status_support === 'CLOSED'"
                                                    v-on="on"
                                                    @click="actionTicket(toolbar)"
                                                >
                                                    <v-icon>
                                                        {{ toolbar.icon }}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ toolbar.text }}</span>
                                        </v-tooltip>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-divider class="my-5" />
                <v-scroll-x-transition>
                    <v-row v-if="!showChat">
                        <v-col>
                            <p class="grey--text mb-0">
                                Last message {{ $date.relativeTimeFromNow(ticket.last_message.created_at) }}
                            </p>
                            <p class="mb-0 d-flex align-center">
                                «{{ ticket.last_message.details }}»
                            </p>
                        </v-col>
                        <v-col>
                            <div class="d-flex justify-end">
                                <div
                                    v-if="ticket.author"
                                    class="mr-10"
                                >
                                    <p class="grey--text mb-0">
                                        Creator:
                                    </p>
                                    <div class="d-flex align-center">
                                        <avatar-rolles
                                            :access-status="authorType(ticket)"
                                            :firstname="ticket.author.firstname"
                                            :lastname="ticket.author.lastname || ''"
                                            :avatar-url="ticket.author.avatar_url"
                                            :is-accent="ticket.author.type === 'Client'"
                                            avatar-size="45"
                                        />
                                        <div class="ml-4">
                                            <p class="mb-0 font-weight-bold">
                                                {{ ticket.author.firstname }}
                                                ({{ ticket.author.type }})
                                            </p>
                                            <p
                                                v-if="ticket.author.id || ticket.owner_id"
                                                class="mb-0"
                                            >
                                                ID: {{ ticket.author.id || ticket.owner_id }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ticket.respondent">
                                    <p class="grey--text mb-0">
                                        Respondent:
                                    </p>
                                    <div class="d-flex align-center">
                                        <avatar-rolles
                                            :access-status="authorType(ticket)"
                                            :firstname="ticket.respondent.firstname"
                                            :lastname="ticket.respondent.lastname || ''"
                                            :avatar-url="ticket.respondent.avatar_url"
                                            :is-accent="ticket.respondent.type === 'Client'"
                                            avatar-size="45"
                                        />
                                        <div class="ml-4">
                                            <p class="mb-0 font-weight-bold">
                                                {{ ticket.respondent.firstname }}
                                                ({{ ticket.respondent.type }})
                                            </p>
                                            <p
                                                v-if="ticket.respondent.id || ticket.respondent_id"
                                                class="mb-0"
                                            >
                                                ID: {{ ticket.respondent.id || ticket.respondent_id }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-scroll-x-transition>
                <v-expand-transition>
                    <div v-if="showChat">
                        <v-row>
                            <v-col
                                cols="6"
                                md="4"
                                lg="2"
                            >
                                <p class="grey--text mb-0">
                                    Created at
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        color="primary"
                                        class="mr-1"
                                    >
                                        mdi-file-outline
                                    </v-icon>
                                    {{ $date.humanizeDate(ticket.created_at) }}
                                </p>
                            </v-col>
                            <v-col
                                cols="6"
                                md="4"
                                lg="2"
                            >
                                <p class="grey--text mb-0">
                                    Ticket ID
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        color="primary"
                                        class="mr-1"
                                    >
                                        mdi-clipboard-check-outline
                                    </v-icon>
                                    <router-link
                                        class="text-decoration-none black--text"
                                        :to="{
                                            name: 'support-details',
                                            params: {
                                                id: ticket.ticketid,
                                                type: isClientTicket ? 'client' : 'writer'
                                            }
                                        }"
                                    >
                                        {{ ticket.ticketid }}
                                    </router-link>
                                </p>
                            </v-col>
                            <v-col
                                v-if="ticket.orderid"
                                cols="6"
                                md="4"
                                lg="2"
                            >
                                <p class="grey--text mb-0">
                                    Order ID
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        color="primary"
                                        class="mr-1"
                                    >
                                        mdi-barcode
                                    </v-icon>
                                    <router-link
                                        class="text-decoration-none black--text"
                                        :to="{
                                            name: 'order-details',
                                            params: {
                                                id: ticket.orderid,
                                            }
                                        }"
                                    >
                                        {{ ticket.orderid }}
                                    </router-link>
                                </p>
                            </v-col>
                            <v-col
                                cols="6"
                                md="4"
                                lg="2"
                            >
                                <p class="grey--text mb-0">
                                    Comments
                                </p>
                                <p class="mb-0 d-flex align-center">
                                    <v-icon
                                        color="primary"
                                        class="mr-1"
                                    >
                                        mdi-comment-multiple-outline
                                    </v-icon>
                                    {{ ticket.counters.messages_count }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <div
                                v-if="getterChatLoading"
                                class="d-flex justify-center align-center w-full py-4"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                />
                            </div>
                            <v-col
                                cols="12"
                                class="support_card__chat"
                            >
                                <chat
                                    class="support-card__chat"
                                    :chat="getterChat"
                                    :owner-roles="['Admin']"
                                    :loading="getterMessageIsSending"
                                    :typing-message="typingMessage"
                                    @typing="typingListener"
                                    @sendChatMessage="onSendChatMessage"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </v-expand-transition>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'

import StatusColored from '@/components/shared/Helpers/StatusColored'
import Chat from '@/components/Chat/Chat'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import {
    SUPPORT_CARD_ACTIONS
} from '@/constants/Support';
import { SET_SUPPORT_CHAT_MESSAGE } from '@/store/modules/support/mutation-types';

import {
    ACTION_GET_SUPPORT_CHAT
} from '@/store/modules/support/action-types';

const {
    mapActions,
    mapMutations: mapSupportMutations
} = createNamespacedHelpers('support');

export default {
    components: {
        StatusColored,
        Chat,
        AvatarRolles
    },
    props: {
        ticket: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: false
        },
        isMessageSending: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        showChat: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            typingMessage: '',
            typing: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterChat',
            'getterChatLoading',
            'getterMessageIsSending'
        ]),
        supportActions() {
            return SUPPORT_CARD_ACTIONS
        },
        isClientTicket() {
            return this.ticket.ticketid.includes('TC-')
        }
    },
    watch: {
        async showChat() {
            if (this.showChat) {
                try {
                    await this[ACTION_GET_SUPPORT_CHAT]({ id: this.ticket.ticketid, isWriter: !this.isClientTicket })
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                }
            }
        },
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticket.ticketid
            })
        }
    },
    created() {
        this.socketConnect()
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', { ticketid: this.ticket.ticketId })
    },
    methods: {
        ...mapActions({
            ACTION_GET_SUPPORT_CHAT
        }),
        ...mapSupportMutations([
            SET_SUPPORT_CHAT_MESSAGE
        ]),
        actionTicket(toolbar) {
            this.$emit('actionTicket', { toolbar, item: this.ticket })
        },
        onSendChatMessage(payload) {
            this.$emit('actionChat', { payload, item: this.ticket })
        },
        async slideChat() {
            this.$emit('showChat', this.ticket.ticketid)
        },
        authorType(message) {
            return message?.author.type.toLowerCase()
        },
        typingListener(value) {
            this.typing = value
        },
        socketConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticket.ticketid })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainInfo.id) return
                this[SET_SUPPORT_CHAT_MESSAGE]({ ...data, ticketid: this.ticket.ticketId })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Admin') return
                this.typingMessage = data.message
            })

            this.$socket.on('disconnect', () => {
                this.socketConnect()
            });
        }
    }
}
</script>

<style lang="scss">
    .support_card__chat{
        .chat_items {
            max-height: 600px;
        }
    }
</style>
