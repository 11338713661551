<template>
    <div class="">
        <!-- Dates -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Dates
                    </span>
                </div>
            </div>
            <!-- Created -->
            <div class="extras-row_col">
                <p class="grey--text mb-0 text-body-2">
                    Created:
                </p>
                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                    {{ data.created_at ? $date.fullDate(data.created_at) : '—' }}
                </p>
            </div>
            <!-- Updated -->
            <div class="extras-row_col">
                <p class="grey--text mb-0 text-body-2">
                    Updated:
                </p>
                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                    {{ data.updated_at ? $date.fullDate(data.updated_at) : '—' }}
                </p>
            </div>
        </div>
        <!-- Writer rate -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Writer
                    </span>
                </div>
            </div>
            <!-- Writer -->
            <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_old.rate_writer" />
                    </p>
                </div>
            </div>
        </div>
        <!-- Company rate -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Company
                    </span>
                </div>
            </div>
            <!-- Writer -->
            <div class="extras-row_col">
                <div class="d-flex align-center">
                    <p class="mb-0 text-body-2 ml-2">
                        <marks-chip :rate="+data.parsed_testimonial_old.rate_company" />
                    </p>
                </div>
            </div>
        </div>
        <!-- Details -->
        <div class="extras-row flex-column pa-2 my-2">
            <!-- row title -->
            <div class=" extras-row_col">
                <div class="">
                    <span class="font-weight-bold text-uppercase text-subtitle-2">
                        Details
                    </span>
                </div>
            </div>
            <!-- details details -->
            <div class="extras-row_col extras-row_col--auto">
                <div class="">
                    <p class="mb-0 text-body-2">
                        {{ data.parsed_testimonial_old.message }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip'

export default {
    components: {
        MarksChip
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>
