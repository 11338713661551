<template>
    <v-card
        width="100%"
        class="px-5 pb-5"
    >
        <tickets-list-component
            ref="list"
            :orderid="orderid"
            :items="getterSupportList"
            :last-page="getterLastPage"
            :is-client="false"
            :order="order"
            :list-loading="getterSupportLoading"
            @update="updateList"
            @updatePerpage="onUpdatePerpage"
            @bookmark="createBookmark"
            @close="closeTicket"
            @send="onActionChat"
            @createTicket="onCreateTicket"
            @update:counters="updateCounters"
        />
    </v-card>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import TicketsListComponent from '@/components/shared/TicketsListComponent'

import {
    ACTION_WRITER_CLOSE_TICKET,
    ACTION_WRITER_GET_SUPPORT_LIST,
    ACTION_WRITER_SEND_MESSAGE
} from '@/store/modules/support/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import { eventBus } from '@/helpers/event-bus/'

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('support');

export default {
    components: {
        TicketsListComponent
    },
    props: {
        orderid: {
            type: String,
            require: true
        },
        order: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            searchOptions: {
                page: 1,
                per_page: 10
            }
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterSupportList',
            'getterLastPage',
            'getterSupportLoading'
        ])
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Writer Chat | Order`
        }
    },
    async activated() {
        await this.updateList()
    },
    async created() {
        if (this.$route.name === 'orders') {
            await this.updateList()
        }
        if (this.$route.name === 'hr-clients-details') {
            await this.updateList()
        }
    },
    methods: {
        ...mapAdminActions({
            ACTION_WRITER_GET_SUPPORT_LIST,
            ACTION_WRITER_SEND_MESSAGE,
            ACTION_WRITER_CLOSE_TICKET,
            ACTION_CREATE_BOOKMARK
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        async updateList(payload) {
            this.searchOptions = {
                ...this.searchOptions,
                ...payload
            }
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'orderid', search_for: this.orderid, page: this.searchOptions.page, per_page: this.searchOptions.per_page, sort_by: 'created_at', sort_order: 'DESC'
            })
        },
        async onUpdatePerpage({ page, per_page }) {
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'orderid', search_for: this.orderid, page, per_page, sort_by: 'created_at', sort_order: 'DESC'
            })
        },
        // TICKETS LOGIC
        async createBookmark(payload) {
            try {
                await this[ACTION_CREATE_BOOKMARK](payload)
                eventBus.$emit('showSnackBar', 'The ticket(s) is successfully added to bookmarks.', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async closeTicket(payload) {
            try {
                await this[ACTION_WRITER_CLOSE_TICKET](payload)
                this.$refs.list.onCloseModal()
                eventBus.$emit('showSnackBar', 'success', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onActionChat(payload) {
            try {
                await this[ACTION_WRITER_SEND_MESSAGE](payload)
                eventBus.$emit('showSnackBar', 'Message is sent', 'success')
                this.$refs.list.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCreateTicket(payload) {
            this.$emit('createTicket', payload)
        },
        async updateCounters(e) {
            this.$emit('update:counters', e)
            await this[ACTION_WRITER_GET_SUPPORT_LIST]({
                search_by: 'orderid', search_for: this.orderid, page: this.searchOptions.page, per_page: this.searchOptions.per_page, sort_by: 'created_at', sort_order: 'DESC'
            })
        }
    }
}
</script>

<style>

</style>
