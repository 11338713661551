<template>
    <Viewer
        :title="'Notes'"
        @closeSidePanel="closeNotes"
    >
        <template #body>
            <div v-if="getterNotes && getterNotes.length">
                <v-row class="pt-5">
                    <v-col
                        cols="12"
                    >
                        <template
                            v-for="(item, index) in getterNotes"
                        >
                            <div
                                :key="index"
                            >
                                <div
                                    v-if="item.admin"
                                    class="text-subtitle-2 text-capitalize pl-13"
                                >
                                    {{ item.admin.firstname }}
                                    {{ item.admin.lastname }},
                                    <span class="text-body-1 grey--text">
                                        <!-- role: {{ item.admin.role }}, -->
                                        id: {{ item.admin.id }}
                                    </span>
                                </div>
                                <div
                                    class="d-flex flex-grow-1"
                                >
                                    <avatar-rolles
                                        v-if="item.admin"
                                        access-status="ADMIN"
                                        :firstname="item.admin.firstname"
                                        :lastname="item.admin.lastname"
                                        :avatar-url="item.admin.avatar_url"
                                        :avatar-fallback="item.admin.avatar_fallback"
                                    />
                                    <div class="d-flex flex-column ml-2 flex-grow-1">
                                        <div
                                            class="notes-detail pa-5"
                                        >
                                            <div>
                                                {{ item.details }}
                                            </div>
                                            <div class="action_block">
                                                <template v-for="toolbar in toolbars">
                                                    <v-tooltip
                                                        :key="toolbar.name"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                dark
                                                                tile
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="actionRow(item, {modal: toolbar.modal, actionName: toolbar.name})"
                                                            >
                                                                <v-icon>
                                                                    {{ toolbar.icon }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ toolbar.text }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </div>
                                        </div>
                                        <p class="grey--text mt-2 mb-5">
                                            {{ $date.dateFromNow(item.ts_issued) }},
                                            {{ $date.humanizeDate(item.ts_issued) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div
                            v-if="getterNotes && !getterNotes.length"
                            class="d-flex flex-column align-center w-full mt-5 mb-5"
                        >
                            <div class="">
                                <img
                                    src="@images/svg/no-data.svg"
                                    alt=""
                                >
                            </div>
                            <p class="text-h6">
                                No data found
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </template>
        <template #modals>
            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :detail-redact="currentItem.details"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </template>
    </Viewer>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_DELETE_NOTE,
    ACTION_EDIT_NOTE,
    ACTION_GET_NOTES
} from '@/store/modules/orders/action-types';

import { WRITERS_NOTE_TOOLBARS } from '@/constants/Writers'

import Viewer from '@/components/SidePanels/Viewer'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('orders');

export default {
    components: {
        Viewer,
        ModalAlert,
        ModalTextarea,
        AvatarRolles
    },
    props: {
        order_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            modal: {},
            actionName: '',
            currentItem: {}
        }
    },
    computed: {
        ...mapGetters('orders', [
            'getterNotes'
        ]),
        toolbars() {
            return WRITERS_NOTE_TOOLBARS
        },
        modalComponent() {
            return this.modal.name || ''
        }
    },
    async created() {
        this.isLoading = true
        await this.getNotes()
        this.isLoading = false
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_NOTES,
            ACTION_DELETE_NOTE,
            ACTION_EDIT_NOTE
        }),
        async getNotes() {
            await this[ACTION_GET_NOTES]({ orderid: this.order_id })
        },
        actionRow(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'delete_note':
                    await this.deleteNote(this.currentItem.id)
                    break
                case 'edit_note':
                    await this.editNote(payload)
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteNote(payload) {
            await this[ACTION_DELETE_NOTE](payload)
            this.$emit('deleteNote', this.order_id)
        },
        async editNote(payload) {
            const { id } = this.currentItem
            await this[ACTION_EDIT_NOTE]({ ...payload, id })
        },
        include() {
            return [...document.querySelectorAll('.v-dialog__content')]
        },
        closeNotes() {
            this.$emit('closeNotes', true)
        }
    }
}
</script>

<style lang="scss">
    .notes-detail{
        position: relative;
        background: #F4F8FD;
        border: 1px solid #EFEFEF;
        .action_block{
            position: absolute;
            top: 10px;
            right: 10px;
            bottom: initial;
            left: initial;
            transform: initial;
            opacity: 0;
            .resolved-btn{
                display: flex;
                align-items: center;
                font-size: 12px;
            }
        }
        &:hover{
            .action_block{
                opacity: 1;
            }
        }
    }
</style>
