<template>
    <div class="">
        <v-data-table
            :headers="headers"
            :items="files"
            :items-per-page="-1"
            :hide-default-footer="true"
            :sort-by.sync="sort_options.sort_by"
            :sort-desc.sync="sort_options.sort_desc"
        >
            <template v-slot:body="{ items }">
                <TransitionGroup
                    tag="tbody"
                    name="delete"
                >
                    <template>
                        <tr
                            v-for="item in items"
                            :key="item.file_id"
                        >
                            <!-- NAME -->
                            <td>
                                <div class="d-flex align-center">
                                    <div class="loaded-file__preview">
                                        <span class="loaded-file__preview-format">
                                            {{ getFileExtension(item.filename) }}
                                        </span>
                                    </div>

                                    <span class="loaded-file__name">
                                        {{ item.filename }}
                                    </span>
                                </div>
                            </td>
                            <!-- TYPE -->
                            <td v-if="showType">
                                <b>
                                    {{ item.type_description }}
                                </b>
                            </td>
                            <!-- OWNER -->
                            <td v-if="showOwner">
                                <template v-if="item.author">
                                    <div
                                        v-if="item.author.type === 'Writer' || item.author.type === 'Admin'"
                                        class="d-flex"
                                    >
                                        <avatar-rolles
                                            :access-status="item.author.type"
                                            :firstname="item.author.firstname"
                                            :lastname="item.author.firstname"
                                            :avatar-url="item.author.avatar_url"
                                            :avatar-fallback="item.author.avatar_fallback"
                                            avatar-size="50"
                                        />
                                        <div class="ml-3">
                                            <p class="mb-1 font-weight-bold">
                                                {{ item.author.firstname }} ({{ item.author.type }})
                                            </p>
                                            <p class="mb-0">
                                                ID: <b>{{ item.author.id }}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <client-avatar
                                        v-else
                                        :id="item.author.id"
                                        :is-client="true"
                                        :firstname="item.author.firstname"
                                        :lastname="item.author.firstname"
                                    />
                                </template>
                            </td>
                            <!-- DATE -->
                            <td>
                                <div class="d-flex align-center">
                                    <span>
                                        {{ $date.dateFromNow(item.created_at) }}
                                    </span>
                                    <information-tooltip>
                                        <div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Created at:
                                                </p>
                                                <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-regular">
                                                    {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                                </p>
                                            </div>
                                        </div>
                                    </information-tooltip>
                                </div>
                            </td>
                            <!-- FILESIZE -->
                            <td>
                                <span>
                                    {{ humanFileSize(item.filesize) }}
                                </span>
                            </td>
                            <!-- Actions -->
                            <td>
                                <div class="d-flex">
                                    <v-btn
                                        icon
                                        target="blank"
                                        :href="item.url"
                                    >
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        @click="deleteFile(item.file_id)"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </div>
                            </td>
                        </tr>
                    </template>
                </TransitionGroup>
            </template>
        </v-data-table>
        <div
            v-if="files.length === 0"
            class=""
        >
            <div
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No files found
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import InformationTooltip from '@/components/shared/InformationTooltip.vue';

export default {
    components: {
        InformationTooltip,
        ClientAvatar,
        AvatarRolles
    },
    props: {
        files: {
            type: Array,
            required: true
        },
        headers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            sort_options: {
                sort_by: 'created_at',
                sort_desc: true
            }
        }
    },
    computed: {
        showOwner() {
            return this.headers ? this.headers.findIndex((item) => item.value === 'owner_type') !== -1 : [];
        },
        showType() {
            return this.headers ? this.headers.findIndex((item) => item.value === 'status') !== -1 : [];
        }
    },
    methods: {
        getFileExtension(filename) {
            const data = filename.split('.')
            if (data[data.length - 1]) return data[data.length - 1]
            return false
        },
        humanFileSize(bytes, si = false, dp = 1) {
            const thresh = si ? 1000 : 1024;

            if (Math.abs(bytes) < thresh) {
                return `${bytes} B`;
            }

            const units = si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            let u = -1;
            const r = 10 ** dp;

            do {
                bytes /= thresh;
                u += 1;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

            return `${bytes.toFixed(dp)} ${units[u]}`;
        },
        downloadFile(file) {
            window.location = file.url
        },
        deleteFile(id) {
            this.$emit('deleteFile', id)
        }
    }
}
</script>

<style lang="scss">
    .loaded-file {
        &__preview {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-right: 20px;

        }

        &__preview-format {
            display: block;
            width: 50px;
            height: 50px;
            padding-top: 28px;
            padding-left: 6px;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            background: url("~@images/svg/doc_empty.svg") no-repeat center;
            background-size: cover;
            text-align-last: left;
            transform: translateX(-2px);
        }

        &__preview-img {
            width: 100%;
            height: auto;
            max-height: 100%;
            border-radius: 4px;
        }

        &__name {
            font-weight: bold;
            width: 350px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media (max-width:996px) {
                width: 200px;
            }
        }

        &__author {
            display: flex;
            align-items: center;
        }

        &__author-details {
            display: flex;
            flex-direction: column;

            &--id {
                color: #afb2b9;
                font-size: 14px;
                font-weight: 400;
            }
        }

        &__author-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 45px;
            height: 45px;
            margin-right: 15px;
            border-radius: 50%;
            overflow: hidden;
            // border: 3px solid $main-color;

            .v-avatar {
                background-color: #ff5423;
            }

            &.loaded-file__author-avatar_default {
                background: #e6e6e6;
                border-color: #cbcbcb;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }

        &__author-naming {
            min-width: 0;
        }

        &__author-name {
            width: 100%;
            font-weight: 500;
            text-align: left;
        }

        &__author-role {
            width: 100%;
            color: #7E8993;
            text-align: left;
        }
    }
    .delete-enter-active,
    .delete-leave-active {
        transition: all 0.9s ease;
    }
    .delete-enter-from,
    .delete-leave-to {
        opacity: 0;
        background: rgba(255,0,0,0.2);
        transform: translateX(30px);
    }
    .delete-enter-to,
    .delete-leave-from {
        background: rgba(0,255,0,0.2);
    }
</style>
