<template>
    <div>
        <v-data-table
            must-sort
            :headers="tableHeader"
            :sort-by="searchOptions.sort_by"
            :sort-desc="searchOptions.sort_order === 'DESC'"
            :items="getterFeedbacksList"
            item-key="id"
            :server-items-length="getterTotal"
            :loading="getterListLoading"
            :single-select="false"
            hide-default-footer
            calculate-widths
            class="mt-6"
            @update:options="updateOptions"
        >
            <template #header="{ }">
                <thead
                    v-if="!mobile"
                    class="v-data-table-header"
                >
                    <tr>
                        <th
                            v-for="h in tableHeaderTotal"
                            :key="h.value"
                        >
                            <template v-if="h.value === 'total'">
                                <div class="d-flex align-center justify-end py-2">
                                    <div class="">
                                        <p class="mb-0 grey--text">
                                            Total:
                                        </p>
                                        <p class="mb-0">
                                            <b>{{ getterTotal }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </th>
                    </tr>
                </thead>
                <thead v-else>
                    <tr>
                        <th>
                            <div class="d-flex py-2">
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Total:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterTotal }}</b>
                                    </p>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <table-skeleton
                    v-if="getterListLoading && items.length === 0"
                    :per-page="searchOptions.per_page"
                    :column-count="tableHeaderLength"
                />
                <table-loader
                    v-if="getterListLoading"
                />
                <tbody>
                    <template v-for="(item, index) in items">
                        <tr
                            :key="item.testimonialid"
                            class="cursor--pointer hovered-row"
                            :class="rowClasses(item)"
                        >
                            <!-- CHECKBOX -->
                            <!-- <td
                                class="td_checkbox"
                                @click.stop
                            >
                                <v-checkbox
                                    v-model="selected"
                                    multiple
                                    :value="item"
                                    style="margin:0px;padding:0px"
                                    hide-details
                                />
                            </td> -->
                            <!-- Index -->
                            <td class="td_index">
                                <p class="text-center mb-0">
                                    {{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}
                                </p>
                            </td>
                            <!-- DATE CREATE -->
                            <td>
                                <div
                                    class="d-flex align-center"
                                    style="white-space: nowrap"
                                >
                                    <p class="mb-0">
                                        {{ item.created_at && $date.fromWithText(item.created_at) }}
                                    </p>
                                    <information-tooltip>
                                        <div class="mb-1">
                                            <p class="mb-0 text-body-2">
                                                Created:
                                            </p>
                                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                            </p>
                                        </div>
                                        <div class="mb-1">
                                            <p class="mb-0 text-body-2">
                                                Updated:
                                            </p>
                                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                {{ item.updated_at ? $date.fullDate(item.updated_at) : '—' }}
                                            </p>
                                        </div>
                                    </information-tooltip>
                                </div>
                            </td>
                            <!-- ORDER ID -->
                            <td>
                                <div
                                    class="d-inline-flex align-center"
                                    style="white-space: nowrap"
                                    @click.stop
                                >
                                    <router-link
                                        :to="{
                                            name: 'order-details',
                                            params: {
                                                id: item.orderid
                                            }
                                        }"
                                        class="black--text text-decoration-none"
                                        @click.stop
                                    >
                                        <span class="mr-2">{{ item.orderid }}</span>
                                    </router-link>
                                    <copy-to-click-board
                                        :copy-string="item.orderid"
                                        icon
                                        @click.stop
                                    />
                                </div>
                            </td>
                            <!-- RATE -->
                            <td>
                                <marks-chip :rate="getAverageRating(item)" />
                            </td>
                            <!-- FEEDBACK -->
                            <td>
                                <div
                                    class="feedback_card-details"
                                    style="width: 200px"
                                >
                                    <p
                                        v-if="item.parsed_testimonial_new.details"
                                    >
                                        {{ item.parsed_testimonial_new.details }}
                                    </p>
                                    <p v-else>
                                        -
                                    </p>
                                </div>
                            </td>
                            <!-- DOMAIN -->
                            <td
                                class="td_domain"
                            >
                                <domain-favicon
                                    :domain="item.domain"
                                />
                            </td>
                            <!-- CLIENT -->
                            <td>
                                <client-avatar
                                    v-if="item.parsed_client && item.parsed_client.id"
                                    :id="item.parsed_client.id"
                                    :is-client="true"
                                    :orders-count="item.parsed_client.statistics.orders_total"
                                    :client-country="item.parsed_client.country"
                                    :firstname="item.parsed_client.firstname"
                                    :lastname="item.parsed_client.lastname"
                                    :avatar="item.parsed_client.avatar"
                                />
                            </td>
                            <!-- WRITER -->
                            <td>
                                <div class="d-flex align-center">
                                    <writer-avatar
                                        v-if="item.parsed_writer"
                                        :id="item.parsed_writer.id"
                                        :feedbacks="item.parsed_writer.statistics.rank_all_count"
                                        :rating="item.parsed_writer.statistics.rank_all"
                                        :rating_rc="item.parsed_writer.statistics.rank_thirty_days"
                                        :applied="item.parsed_writer.ts_apply"
                                        :status="item.parsed_writer.trust_status"
                                        :firstname="item.parsed_writer.firstname"
                                        :lastname="item.parsed_writer.lastname"
                                        :avatar-url="item.parsed_writer.avatar_url"
                                        :avatar-fallback="item.parsed_writer.avatar_fallback"
                                        :order_limit="item.parsed_writer.projects_limit"
                                        :country="item.parsed_writer.country"
                                        :country-nice-name="item.parsed_writer.country_nice_name"
                                    />
                                    <span>{{ item.parsed_writer.firstname }} {{ item.parsed_writer.lastname }}</span>
                                </div>
                            </td>
                            <!-- STATUS -->
                            <td>
                                <status-chip :status="item.status" />
                            </td>
                            <!-- open extras -->
                            <td
                                @click.stop
                            >
                                <div class="d-flex justify-center align-center">
                                    <v-icon
                                        v-if="openedRow.includes(item.testimonialid)"
                                        @click="openFullRow(item.testimonialid)"
                                    >
                                        mdi-chevron-up
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        @click="openFullRow(item.testimonialid)"
                                    >
                                        mdi-chevron-down
                                    </v-icon>
                                    <table-rows-menu
                                        :toolbars="itemToolbars"
                                        :item="item"
                                        class="ml-2"
                                        @menuActivated="onMenuActivated"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr
                            :key="`${item.testimonialid}-exdent`"
                            class="exdent-row"
                            :class="rowClasses(item)"
                        >
                            <td
                                :colspan="tableHeader.length + 1"
                            >
                                <general-extras
                                    :is-show="openedRow.includes(item.testimonialid)"
                                    :data="item"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!getterFeedbacksList.length && !getterListLoading "
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No testimonials found
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    FEEDBACKS_TABLE_HEADER, FEEDBACKS_TABLE_HEADER_TOTAL, FEEDBACKS_TOOLBAR
} from '@/constants/Feedbacks'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

import InformationTooltip from '@/components/shared/InformationTooltip'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import GeneralExtras from '@/components/Feedback/FeedbackTable/GeneralExtras';
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip'
import StatusChip from '@/components/Feedback/FeedbackTable/StatusChip';

export default {
    components: {
        MarksChip,
        StatusChip,
        TableSkeleton,
        TableRowsMenu,
        TableLoader,
        InformationTooltip,
        CopyToClickBoard,
        GeneralExtras,
        WriterAvatar,
        ClientAvatar,
        DomainFavicon
    },
    props: {
        searchOptions: {
            type: Object
        }
    },
    data() {
        return {
            // selected: [],
            openedRow: [],
            mobile: false
        }
    },
    computed: {
        ...mapGetters('feedbacks', [
            'getterFeedbacksList',
            'getterLastPage',
            'getterTotal',
            'getterPresets',
            'getterTotalsLoading',
            'getterListLoading'
        ]),
        tableHeader() {
            return FEEDBACKS_TABLE_HEADER
        },
        tableHeaderTotal() {
            return FEEDBACKS_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return FEEDBACKS_TABLE_HEADER.length + 1
        },
        itemToolbars() {
            return FEEDBACKS_TOOLBAR
        }
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        onMenuActivated(feedback, action) {
            this.$emit('menuActivated', feedback, action)
        },
        rowClasses(item) {
            return {
                'table-row--opened': this.openedRow.includes(item.testimonialid),
                // 'table-row--selected': this.selected.some((select) => select?.testimonialid === item.testimonialid),
                'table-row--selected-once': this.currentFeedback?.testimonialid === item.testimonialid
            }
        },
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        updateOptions(e) {
            this.$emit('updateOptions', e)
        },
        getAverageRating(item) {
            let rate = 0
            if (item.parsed_testimonial_new) {
                const {
                    communication, content, delivery, support, website
                } = item.parsed_testimonial_new
                rate = (Number(communication) + Number(content) + Number(delivery) + Number(support) + Number(website)) / 5
            }
            if (item.parsed_testimonial_old) {
                const {
                    rate_writer, rate_company
                } = item.parsed_testimonial_new
                rate = (Number(rate_writer) + Number(rate_company)) / 2
            }
            return rate
        }
    }
}
</script>
