<template>
    <credits-table
        class="mt-6"
        :items="getterWritersCreditsList"
        :last-page="getterWritersLastPage"
        :total="getterWritersTotal"
        :total-credits="getterWritersTotalCredit"
        :total-debits="getterWritersTotalDebit"
        :list-loading="getterWritersListLoading"
        :totals-loading="getterWritersTotalLoading"
        :search-options="searchOptions"
        :sort-desc="sortDesc"
        is-writer
        is-details
        @updatePage="paginationPageEvent"
        @updatePerpage="onUpdatePerpage"
        @updateOptions="updateOptions"
    />
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
    ACTION_GET_WRITER_CREDITS_LIST,
    ACTION_GET_WRITER_CREDITS_TOTALS
} from '@/store/modules/credits/action-types';

import CreditsTable from '@/components/Credits/CreditsTable.vue';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('credits');

export default {
    components: {
        CreditsTable
    },
    props: {
        clientName: {
            type: String,
            required: true
        }
    },
    data() {
        const { id } = this.$route.params
        return {
            searchOptions: {
                sort_order: 'DESC',
                sort_by: 'created_at',
                page: 1,
                per_page: 10,
                search_by: 'sw_id',
                search_for: id
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true
        }
    },
    metaInfo() {
        return {
            title: `${this.clientName} | Credits | Client`
        }
    },
    computed: {
        ...mapGetters('credits', [
            'getterWritersCreditsList',
            'getterWritersLastPage',
            'getterWritersTotal',
            'getterWritersTotalCredit',
            'getterWritersTotalDebit',
            'getterWritersListLoading',
            'getterWritersTotalLoading'
        ]),
        sortDesc() {
            return this.sort.sort_order === 'ASC'
        }
    },
    async activated() {
        await this.getCreditsList()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_WRITER_CREDITS_LIST,
            ACTION_GET_WRITER_CREDITS_TOTALS
        }),
        async getCreditsList() {
            const requestArr = [
                this[ACTION_GET_WRITER_CREDITS_LIST]({ sw_id: this.$route.params.id, ...this.searchOptions, mode: 'list' }),
                this[ACTION_GET_WRITER_CREDITS_TOTALS]({ sw_id: this.$route.params.id, ...this.searchOptions, mode: 'count' })
            ]
            await Promise.all(requestArr)
            this.isFirstLoad = false
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getCreditsList()
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getCreditsList()
            }
        },
        async onUpdatePerpage(payload) {
            this.searchOptions.page = 1
            this.searchOptions.per_page = payload.per_page
            await this.getCreditsList()
        }
    }
}
</script>

<style lang="scss" scoped>
    .credit {
        color: green;
    }
    .debit {
        color: red;
    }
    .balance {
        font-weight: 700;
    }
</style>
