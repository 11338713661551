<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-alert
                dense
                type="info"
                dark
                class="py-5"
                color="rgba(255, 84, 4, 0.1)"
                icon="mdi-alert-circle-outline"
            >
                <div class="black--text">
                    {{ description }}
                </div>
            </v-alert>
            <v-form ref="drop-writer-form">
                <v-text-field
                    v-model="reason"
                    outlined
                    :rules="rules.reason"
                    label="Reason for the releasing of an order"
                />
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'
import { eventBus } from '@/helpers/event-bus/'

export default {
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: Boolean,
            defalut: false
        }
    },
    data() {
        return {
            showModal: true,
            reason: '',
            rules: {
                reason: [
                    (val) => val.length > 20 || 'Enter at least 20 characters'
                ]
            }
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs['drop-writer-form'].validate()
            if (this.reason.length < 20) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error')
                return
            }
            this.$emit('confirmModal', { reason: this.reason })
        }
    }
}
</script>

<style>

</style>
