<template>
    <v-chip
        v-if="!mobile && accessstatus"
        :color="colors.background"
        :text-color="colors.text"
    >
        {{ accessstatus }}
    </v-chip>
    <v-chip
        v-else-if="mobile && accessstatus"
        :color="colors.background"
        :text-color="colors.text"
    >
        {{ accessstatus[0] }}
    </v-chip>
    <span v-else>
        -
    </span>
</template>

<script>
export default {
    name: 'AccessStatus',
    props: {
        accessstatus: {
            type: String
        },
        mobile: {
            type: Boolean
        }
    },
    computed: {
        colors() {
            switch (this.accessstatus) {
            case 'WRITER':
                return {
                    background: 'rgba(255, 84, 4, 0.1)',
                    text: '#284067'
                }
            case 'APPLICANT':
                return {
                    background: '#1976D21A',
                    text: '#1976D2'
                }
            default:
                return {
                    background: 'rgba(255, 0, 0, 0.1)',
                    text: '#FF0000'
                }
            }
        }
    }

}
</script>
