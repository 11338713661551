export const TOOLBARS = [
    {
        name: 'assign_bid',
        text: 'Assign the bid',
        modal: {
            name: 'ModalBidAssign',
            success_message: 'The project was assigned',
            title: 'Assign',
            description: 'You are going to assign the project to'
        },
        icon: 'mdi-account-arrow-left-outline'
    },
    {
        name: 'delete_bid',
        text: 'Delete the bid',
        modal: {
            name: 'ModalAlert',
            success_message: 'The bid was deleted',
            title: 'Delete',
            description: 'Do you really want to delete the bid?'
        },
        icon: 'mdi-delete-outline'
    }
]
