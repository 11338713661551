<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col cols="12">
                <the-payroll-table
                    class="mt-6"
                    :search-options="searchOptions"
                    :sort-desc="sortDesc"
                    @updateOptions="updateOptions"
                    @payoutProceed="onPayoutProceed"
                    @pendingProceed="onPendingProceed"
                />

                <pagination-with-perpage
                    :list-lenght="getterPayoutsTotal"
                    :last-page="getterPayoutsLastPage"
                    :search-options="{ page: searchOptions.page, per_page: searchOptions.per_page }"
                    :total-visible="7"
                    @updatePage="paginationPageEvent"
                    @updatePerpage="onUpdatePerpage"
                />

                <component
                    :is="modalComponent"
                    :title="modal.title"
                    :description="modal.description"
                    :input-label="modal.inputLabel"
                    :payable-data="modal.payableData"
                    :payoutid="modal.payoutid"
                    :loading="loadingActions"
                    @closeModal="onCloseModal"
                    @confirmModal="onConfirmModal"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_GET_WRITERS_PAYMENTS_LIST,
    ACTION_GET_WRITERS_PAYMENTS_TOTALS,
    ACTION_GET_PAYABLE,
    ACTION_CREATE_BATCH,
    ACTION_PAY_PAYONEER,
    ACTION_PAY_WIRE,
    ACTION_PAY_MPESA
} from '@/store/modules/writers/action-types';
import ThePayrollTable from '@/components/PayrollTable/ThePayrollTable.vue';
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'
import ModalProceedRequest from '@/components/shared/Modals/ModalProceedRequest'
import ModalAlert from '@/components/shared/Modals/ModalAlert'

const {
    mapActions: mapPayoutsActions
} = createNamespacedHelpers('writers');

export default {
    components: {
        PaginationWithPerpage,
        ThePayrollTable,
        ModalProceedRequest,
        ModalAlert
    },
    data() {
        return {
            selected: [],
            modal: {},
            actionName: '',
            loading: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: '',
                sort_order: ''
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true,
            openedRow: [],
            processedPaymentId: null,
            loadingActions: false
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterPayoutsTotal',
            'getterPayoutsLastPage'
        ]),
        modalComponent() {
            return this.modal.name || ''
        },
        sortDesc() {
            return this.sort.sort_order === 'DESC'
        }
    },
    async activated() {
        await this.getPayouts()
    },
    methods: {
        ...mapPayoutsActions({
            ACTION_GET_WRITERS_PAYMENTS_LIST,
            ACTION_GET_WRITERS_PAYMENTS_TOTALS,
            ACTION_GET_PAYABLE,
            ACTION_CREATE_BATCH,
            ACTION_PAY_PAYONEER,
            ACTION_PAY_WIRE,
            ACTION_PAY_MPESA
        }),
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getPayouts(false)
        },
        async getPayouts(with_total = true) {
            try {
                const { id } = this.$route.params
                const requests = [
                    this[ACTION_GET_WRITERS_PAYMENTS_LIST]({
                        writer_id: id, page: this.searchOptions.page, per_page: this.searchOptions.per_page, mode: 'list'
                    })
                ]
                if (with_total) {
                    requests.push(
                        this[ACTION_GET_WRITERS_PAYMENTS_TOTALS]({
                            writer_id: id, page: this.searchOptions.page, per_page: this.searchOptions.per_page, mode: 'count'
                        })
                    )
                }
                await Promise.all(requests)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCloseModal() {
            this.modal = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'ProceedRequest':
                    await this.createBatch(payload)
                    break
                case 'payProceed':
                    await this.payProceed()
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onPayoutProceed(payoutid) {
            try {
                const data = await this[ACTION_GET_PAYABLE](payoutid)
                this.actionName = 'ProceedRequest'
                this.modal = {
                    name: 'ModalProceedRequest',
                    payableData: data,
                    payoutid
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onPendingProceed(payoutid, type) {
            try {
                this.actionName = 'payProceed'
                this.processedPaymentId = payoutid
                this.processedPaymentType = type
                this.modal = {
                    name: 'ModalAlert',
                    title: 'Continue payment',
                    description: 'Are you sure you want to pay for this request?'
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async createBatch({ orders, credits, payoutid }) {
            const requestData = {
                payout_id: payoutid,
                orders: [...orders.map(({ orderid }) => (orderid))],
                credits: [...credits.map(({ id }) => (id))]
            }
            try {
                this.loadingActions = true
                await this[ACTION_CREATE_BATCH](requestData)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payProceed() {
            switch (this.processedPaymentType) {
            case 'payoneer':
                await this.payPayoneer()
                break
            case 'wire':
                await this.payWire()
                break
            case 'mpesa':
                await this.payMpesa()
                break
            default:
            }
        },
        async payPayoneer() {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_PAYONEER]({ payout_id: this.processedPaymentId })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payWire() {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_WIRE]({ payout_id: this.processedPaymentId })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async payMpesa() {
            try {
                this.loadingActions = true
                await this[ACTION_PAY_MPESA]({ payout_id: this.processedPaymentId })
                this.processedPaymentId = null
                eventBus.$emit('showSnackBar', 'Payment was successful', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingActions = false
            }
        },
        async onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            await this.getPayouts()
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getPayouts()
            }
        }
    }
}
</script>

<style>

</style>
