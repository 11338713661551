<template>
    <div>
        <v-expand-transition>
            <div v-show="isShow">
                <!-- Finance -->
                <div class="extras-row green lighten-5">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-currency-usd
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Payment
                            </span>
                        </div>
                    </div>
                    <!-- Payment method -->
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Payment method:
                            </p>
                            <p
                                v-if="data.fin.invoice"
                                class="mb-0 text-body-2"
                            >
                                <span v-if="data.fin.invoice.payment_provider">{{ data.fin.invoice.payment_provider.name }}</span>
                                <span v-else> — </span>
                            </p>
                            <span v-else>
                                —
                            </span>
                        </div>
                    </div>

                    <!-- ordered services -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Ordered services:
                            </span>
                            <the-order-total
                                :item="data.project_information"
                            />
                        </div>
                    </div>

                    <!-- Discount amount -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Discount amount:
                            </span>
                            <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-bold">
                                <span v-if="data.fin.discount && data.fin.discount.amount">
                                    {{ data.fin.discount.amount | money }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- Discount type -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Discount type / code:
                            </span>
                            <p class="mb-0 d-flex align-center text-subtitle-2 font-weight-bold">
                                <span v-if="data.fin.discount && data.fin.discount.type === `loyalty`">
                                    Loyalty
                                </span>
                                <span v-else-if="data.fin.discount && data.fin.discount.code">
                                    {{ data.fin.discount.code }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- Discount rate -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Discount rate
                            </span>
                            <!-- TOTALS -->
                            <p class="mb-0 d-flex align-center text-subtitle-2">
                                <span
                                    v-if="data.fin.discount && data.fin.discount.rate"
                                    class="font-weight-bold"
                                >
                                    {{ data.fin.discount.rate | percent }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- store credit -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Store credit
                            </span>
                            <!-- TOTALS -->
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span v-if="data.fin.invoice && data.fin.invoice.store_credit > 0">
                                    {{ data.fin.invoice.store_credit | money }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>

                    <!-- desired price -->
                    <div class="extras-row_col">
                        <div class="flex">
                            <span class="grey--text">
                                Desired price:
                            </span>
                            <!-- TOTALS -->
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <span v-if="data.fin.desired_price">
                                    {{ data.fin.desired_price | money }}
                                </span>
                                <span v-else>
                                    —
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- counters -->
                <div class="extras-row brown lighten-5">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-counter
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Counters
                            </span>
                        </div>
                    </div>
                    <div
                        class="extras-row_col"
                    >
                        <p class="grey--text mb-0 text-body-2">
                            Messages:
                        </p>
                        <p
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <router-link
                                :to="{
                                    path: `/orders/${data.orderid}/comment-log`,
                                }"
                                class="mb-0 text-decoration-none text-subtitle-2 black--text d-flex align-center"
                            >
                                <v-icon>
                                    mdi-forum
                                </v-icon>
                                <v-avatar
                                    color="primary"
                                    size="25"
                                    class="ml-2"
                                >
                                    <span
                                        class="font-weight-regular text-body-2 white--text"
                                    >
                                        {{ data.counters.messages_count || 0 }}
                                    </span>
                                </v-avatar>
                            </router-link>
                        </p>
                    </div>
                    <!-- END: Messages counter -->

                    <!-- START: Tickets client counter -->
                    <div
                        class="extras-row_col"
                    >
                        <p class="grey--text mb-0 text-body-2">
                            Tickets client:
                        </p>
                        <p
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <router-link
                                :to="{
                                    path: `/orders/${data.orderid}/client-chat`,
                                }"
                                class="mb-0 text-decoration-none text-subtitle-2 black--text d-flex align-center"
                            >
                                <v-icon>
                                    mdi-ticket-account
                                </v-icon>
                                <v-avatar
                                    color="primary"
                                    size="25"
                                    class="ml-2"
                                >
                                    <span
                                        class="font-weight-regular text-body-2 white--text"
                                    >
                                        {{ data.counters.tickets_client_count || 0 }}
                                    </span>
                                </v-avatar>
                            </router-link>
                        </p>
                    </div>
                    <!-- END: Tickets client counter -->

                    <!-- START: Tickets writer counter -->
                    <div
                        class="extras-row_col"
                    >
                        <p class="grey--text mb-0 text-body-2">
                            Tickets writer:
                        </p>
                        <p
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <router-link
                                :to="{
                                    path: `/orders/${data.orderid}/writer-chat`,
                                }"
                                class="mb-0 text-decoration-none text-subtitle-2 black--text d-flex align-center"
                            >
                                <v-icon>
                                    mdi-ticket-account
                                </v-icon>
                                <v-avatar
                                    color="primary"
                                    size="25"
                                    class="ml-2"
                                >
                                    <span
                                        class="font-weight-regular text-body-2 white--text"
                                    >
                                        {{ data.counters.tickets_writer_count || 0 }}
                                    </span>
                                </v-avatar>
                            </router-link>
                        </p>
                    </div>
                    <!-- END: Tickets writer counter -->

                    <!-- START: Files main counter -->
                    <div
                        class="extras-row_col"
                    >
                        <p class="grey--text mb-0 text-body-2">
                            Files main:
                        </p>
                        <p
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <router-link
                                :to="{
                                    path: `/orders/${data.orderid}/files`,
                                }"
                                class="mb-0 text-decoration-none text-subtitle-2 black--text d-flex align-center"
                            >
                                <v-icon>
                                    mdi-file-multiple
                                </v-icon>
                                <v-avatar
                                    color="primary"
                                    size="25"
                                    class="ml-2"
                                >
                                    <span
                                        class="font-weight-regular text-body-2 white--text"
                                    >
                                        {{ data.counters.files_main_count || 0 }}
                                    </span>
                                </v-avatar>
                            </router-link>
                        </p>
                    </div>
                    <!-- END: Files main counter -->

                    <!-- START: Files add counter -->
                    <div
                        class="extras-row_col"
                    >
                        <p class="grey--text mb-0 text-body-2">
                            Files add:
                        </p>
                        <p
                            class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                        >
                            <router-link
                                :to="{
                                    path: `/orders/${data.orderid}/files-add`,
                                }"
                                class="mb-0 text-decoration-none text-subtitle-2 black--text d-flex align-center"
                            >
                                <v-icon>
                                    mdi-file-multiple-outline
                                </v-icon>
                                <v-avatar
                                    color="primary"
                                    size="25"
                                    class="ml-2"
                                >
                                    <span
                                        class="font-weight-regular text-body-2 white--text"
                                    >
                                        {{ data.counters.files_add_count || 0 }}
                                    </span>
                                </v-avatar>
                            </router-link>
                        </p>
                    </div>
                    <!-- END: Files add counter -->
                </div>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin'

import TheOrderTotal from '@/components/Orders/OrderTable/TheOrderTotal'

import { statusBgColor, statusText, statusTextColor } from '@/helpers/order/statusHelper'

export default {
    components: {
        TheOrderTotal
    },
    mixins: [
        filtersMixin
    ],
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: false,
            default: 1
        },
        isShow: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        statusText,
        statusBgColor,
        statusTextColor
    }
};
</script>

<style lang="scss">
    .table_general{
        max-width: 440px !important;
    }
    .truncate-text{
        display: inline-block;
        width:max-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
