<template>
    <modal
        :loading="loading"
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Deadline</span>
        </template>
        <template #body>
            <p>This function sets a new deadline for the selected order.</p>

            <v-datetime-picker
                v-model="datetime"
                label="Select Date Time"
                outlined
                date-format="MMM dd,"
                time-format="p"
                :date-picker-props="{
                    min:minDateTime
                }"
            >
                <template #dateIcon>
                    Date
                </template>
                <template #timeIcon>
                    Time
                </template>
            </v-datetime-picker>
        </template>
    </modal>
</template>

<script>
import dayjs from 'dayjs'
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        loading: {
            type: Boolean,
            defalut: false
        },
        current: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            menu: false,
            datetime: ''
        }
    },
    computed: {
        minDateTime() {
            return new Date().toISOString()
        }
    },
    created() {
        this.datetime = new Date(dayjs(this.current.due_at).toISOString())
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                deadline: dayjs(this.datetime).toISOString()
            })
        }
    }
}
</script>

<style>

</style>
