<template>
    <v-container fluid>
        <v-row>
            <template>
                <!--                <v-tabs-->
                <!--                    v-model="activeTab"-->
                <!--                    show-arrows-->
                <!--                >-->
                <!--                    <template v-for="tab in tabsNew">-->
                <!--                        <v-tab-->
                <!--                            v-if="!tab.hidden"-->
                <!--                            :key="tab.path"-->
                <!--                            :to="tab.path"-->
                <!--                        >-->
                <!--                            {{ tab.text }}-->
                <!--                            <span-->
                <!--                                v-if="tab.total !== null"-->
                <!--                                class="tab-total"-->
                <!--                            >-->
                <!--                                {{ tab.total }}-->
                <!--                            </span>-->
                <!--                        </v-tab>-->
                <!--                    </template>-->
                <!--                </v-tabs>-->
                <select-tabs
                    v-model="activeTab"
                    :items="tabsNew"
                />
                <keep-alive max="3">
                    <router-view
                        :key="$route.fullPath"
                        class="white col-12"
                        :writer="writer"
                        :writer_id="writer.id"
                        :create-ticket-prepend="createTicketPrepend"
                        @createTicket="onCreateTicket"
                        @update:counters="updatCounters"
                    />
                </keep-alive>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import selectTabs from '@/components/shared/SelectTabs.vue';

export default {
    components: {
        selectTabs
    },
    props: {
        writer: {
            type: Object,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        },
        to: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            activeTab: ''
        }
    },
    computed: {
        // writerID() {
        //     return this.$route.params.id
        // },
        createTicketPrepend() {
            return {
                sw_id: this.writer.id,
                writer: {}
            }
        },
        tabsNew() {
            return [
                {
                    text: 'Details',
                    key: 'details',
                    path: `/writers/${this.writer.id}`,
                    total: null
                },
                {
                    text: 'Orders',
                    key: 'orders',
                    path: `/writers/${this.writer.id}/orders`,
                    total: this.writer.statistics.orders_total
                },
                {
                    text: 'Notes',
                    key: 'notes',
                    path: `/writers/${this.writer.id}/notes`,
                    total: this.writer.counters.notes_count
                },
                {
                    text: 'Tickets',
                    key: 'tickets',
                    path: `/writers/${this.writer.id}/tickets`,
                    total: this.writer.counters.tickets_count
                },
                {
                    text: 'Files',
                    key: 'files',
                    path: `/writers/${this.writer.id}/files`,
                    total: this.writer.counters.files_count
                },
                {
                    text: 'Payment History',
                    key: 'payments',
                    path: `/writers/${this.writer.id}/payments`,
                    total: this.writer.counters.payouts_count
                },
                {
                    text: 'Credits',
                    key: 'credits',
                    path: `/writers/${this.writer.id}/credits`,
                    total: null
                },
                {
                    text: 'Testimonials',
                    key: 'testimonials',
                    path: `/writers/${this.writer.id}/testimonials`,
                    total: this.writer.counters.testimonials_count
                },
                {
                    text: 'Exams',
                    key: 'exams',
                    path: `/writers/${this.writer.id}/exams`,
                    total: this.writer.counters.exams_count
                }
            ]
        }
    },
    methods: {
        onCreateTicket() {
            this.$emit('createTicket')
        },
        updatCounters(e) {
            this.$emit('update:counters', e)
        }
    }
}
</script>

<style lang="scss">
    .v-tabs-items{
        width: 100%;
    }
    .tab-total {
      padding: 4px 10px;
      background: rgba(79, 79, 79, 0.1);
      border-radius: 4px;
      margin-left: 10px;
    }
    .v-tab {
      min-width: 180px;
    }
</style>
