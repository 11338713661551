export const BATCH_TABLE_HEADER = [
    {
        text: 'In-x',
        sortable: false,
        value: 'index',
        width: '50px'
    },
    {
        text: 'Created',
        sortable: true,
        value: 'created_at',
        width: '180px'
    },
    {
        text: 'Batch ID',
        sortable: false,
        value: 'batchid'
    },
    {
        text: 'Domain',
        sortable: true,
        value: 'domain',
        width: '120px'
    },
    {
        text: 'Client ID',
        sortable: false,
        value: 'clientid',
        width: '200px'
    },
    {
        text: 'Status',
        sortable: true,
        value: 'status',
        width: '150px'
    },
    {
        text: 'Orders count',
        sortable: false,
        value: 'order_count',
        width: '100px'
    },
    {
        text: 'Paymethod',
        sortable: true,
        value: 'paymethod',
        width: '150px'
    },
    {
        text: 'Payment ID',
        sortable: false,
        value: '',
        width: '75px'
    },
    {
        text: 'Store Credit',
        sortable: true,
        value: 'store_credit',
        width: '110px'
    },
    {
        text: 'Total',
        sortable: true,
        value: 'total',
        width: '70px',
        align: 'end'
    },
    {
        text: '',
        sortable: false,
        value: 'arrow',
        width: '100px'
    }
]
