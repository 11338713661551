<template>
    <div class="writer-table__avatar d-flex">
        <writer-avatar
            :id="data.sw_id"
            :feedbacks="getRank && getRank.rank_all_count"
            :rating="getRank && getRank.rank_all"
            :rating_rc="getRank && `${getRank.rank_thirty_days_count}`"
            :applied="data.created_at"
            :status="data.trust_status"
            :firstname="data.firstname"
            :lastname="data.lastname"
            :avatar-url="data.avatar_url"
            :avatar-fallback="data.avatar_fallback"
            :order_limit="data.projects_limit"
            :country="data.country"
            :country-nice-name="data.country_nice_name"
        />
        <div class="ml-2">
            <div class="d-flex">
                <router-link
                    :to="{ name: setRoute, params: { id: data.sw_id } }"
                    class="d-flex flex-column font-weight-bold mb-0 black--text text-decoration-none"
                >
                    <span>
                        {{ nameCapitalized(data.firstname) }}
                        {{ nameCapitalized(data.lastname) }}
                    </span>
                    <span
                        v-if="data.nickname"
                        class="caption"
                    >{{ nameCapitalized(data.nickname) }}</span>
                </router-link>
            </div>
            <p class="grey--text mb-0">
                {{ data.sw_id }}
            </p>
            <span
                v-if="data.counters.notes_count > 0"
                class="badge mt-2 d-inline-flex"
                style="cursor: pointer;"
                @click.stop="showNotes"
            >
                <b class="mr-1">{{ data.counters.notes_count }}</b> {{ `${data.counters.notes_count === 1 ? 'note' : 'notes'}` }}
            </span>
        </div>
    </div>
</template>

<script>

import { nameCapitalized } from '@/helpers/capitalized/index'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar.vue'

export default {
    components: { WriterAvatar },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        getRank() {
            return this.data.statistics_parsed?.rank
        },
        setRoute() {
            if (this.$route.name === 'applicant') {
                return 'applicant-profile'
            }
            return 'writer-profile'
        }
    },
    methods: {
        nameCapitalized,
        showNotes() {
            this.$emit('showNotes', this.data.sw_id)
        }
    }
}
</script>

<style lang="scss">
    .badge{
        font-size: 12px;
        padding: 4px 8px;
        background: #D3D9E2;
        border-radius: 4px;
    }
    @media (max-width: 599px) {
        .writer-table__avatar {
            flex-direction: column;
        }
    }
</style>
