export const TOOLBARS = [
    {
        name: 'assign',
        text: 'Assign',
        modal: {
            name: 'ModalAssign',
            success_message: 'The order was assign'
        },
        icon: 'mdi-account-arrow-left-outline'
    },
    {
        name: 'notes',
        text: 'Note',
        modal: {
            name: 'ModalTextarea',
            title: 'Post a note',
            description: "Post a note to writer's profile.",
            inputLabel: 'Details',
            success_message: 'The note has been posted'
        },
        icon: 'mdi-note-outline'
    },
    {
        name: 'bookmark',
        text: 'Bookmark',
        modal: {
            name: 'ModalTextarea',
            title: 'Bookmark',
            description: 'Bookmark an item. Any important order or ticket can be bookmarked for easy access in the future.',
            inputLabel: 'Provide details please',
            success_message: 'The order(s) is successfully added to bookmarks.'
        },
        icon: 'mdi-star-outline'
    },
    {
        name: 'client',
        text: 'Client',
        modal: {
            name: 'ModalCreateTicketClient',
            title: 'Create the support ticket for a client',
            success_message: 'The ticket for the client has been created'
        },
        icon: 'mdi-account-circle-outline'
    },
    // {
    //     name: 'comments',
    //     text: 'Comments',
    //     modal: {
    //         name: 'ModalTextarea',
    //         title: 'Comments',
    //         description: 'This tool is used to post comments to the communication log for the writer(s) to see. The function also creates a writer ticket and posts the same message there for further communication with the writer currently assigned.',
    //         inputLabel: 'Comment',
    //         success_message: 'Comment is created'
    //     },
    //     icon: 'mdi-comment-text-outline'
    // },
    {
        name: 'message',
        text: 'Writer',
        modal: {
            name: 'ModalCreateTicketWriter',
            title: 'Create the support ticket for a writer',
            success_message: 'The ticket for the writer has been created'
        },
        icon: 'mdi-comment-plus-outline'
    },
    {
        name: 'revision',
        text: 'Revision',
        modal: {
            name: 'ModalTextarea',
            title: 'Create a revision request',
            inputLabel: 'Revision Request Details',
            success_message: 'Revision request has been created'
        },
        icon: 'mdi-calendar-edit'
    },
    {
        name: 'Fee',
        text: 'Fee',
        modal: {
            name: 'ModalFee',
            success_message: 'Fee is updated'
        },
        icon: 'mdi-cash'
    },
    // {
    //     name: 'Pay',
    //     text: 'pay',
    //     modal: {
    //         name: 'ModalAlert',
    //         title: 'Payment batch',
    //         description: 'Prepare a payment batch?'
    //     },
    //     icon: 'mdi-credit-card-outline'
    // },
    // {
    //     name: 'upload_files',
    //     text: 'Upload',
    //     modal: {
    //         name: 'ModalUpload',
    //         description: 'Supported only .doc, .docx, .xls, .xlsx, .rtf, .txt, .csv, .ppt, .pptx, .pdf, extensions. You can upload files up to 30 MB in size.',
    //         success_message: 'File(s) has been uploaded',
    //         filesKey: 'file[]',
    //         typeKey: 'status'
    //     },
    //     icon: 'mdi-upload-outline'
    // },
    {
        name: 'deadline',
        text: 'Deadline',
        modal: {
            name: 'ModalDeadline',
            success_message: 'Deadline is updated'
        },
        icon: 'mdi-calendar-alert'
    },
    {
        name: 'drop',
        text: 'Drop the writer',
        modal: {
            name: 'ModalDropWriter',
            title: 'Drop the writer',
            description: 'Are you sure to drop the writer?',
            success_message: 'Writer has been dropped'
        },
        icon: 'mdi-close'
    },
    {
        name: 'change_status',
        text: 'Cancel',
        modal: {
            name: 'ModalAlert',
            title: 'Cancel order',
            description: "Are you sure you want to cancel the order and refund the customer's store credit?",
            success_message: 'Order has been canceled'
        },
        icon: 'mdi-cash-refund'
    }
]

export const MASS_TOOLBARS = [
    {
        name: 'mass_assign',
        text: 'Assign',
        modal: {
            name: 'ModalAssign',
            success_message: 'The writers was assigned'
        },
        icon: 'mdi-account-arrow-left-outline'
    },
    {
        name: 'bookmark_mass',
        text: 'Bookmark',
        modal: {
            name: 'ModalTextarea',
            title: 'Bookmark',
            description: 'Bookmark an item. Any important order or ticket can be bookmarked for easy access in the future.',
            inputLabel: 'Add a description',
            success_message: 'The order(s) is successfully added to bookmarks.'
        },
        icon: 'mdi-star-outline'
    },
    {
        name: 'rate_complete',
        text: 'Notify complete order',
        modal: {
            name: 'ModalAlert',
            title: 'Notification complete order',
            description: 'Send a notification to client so that they can rate a completed project (submit a testimonial)',
            success_message: 'Notification was sent'
        },
        icon: 'mdi-email-check-outline'
    },
    {
        name: 'notify_unpaid',
        text: 'Notify unpaid order',
        modal: {
            name: 'ModalAlert',
            title: 'Notification unpaid order',
            description: 'Send a notification to client that they have an unpaid order',
            success_message: 'Notification was sent'
        },
        icon: 'mdi-email-remove-outline'
    }
    // {
    //     name: 'client',
    //     text: 'Client',
    //     modal: {
    //         name: 'ModalTextarea',
    //         title: 'Contact client',
    //         description: 'This tool is used to contact the client. A support ticket will be generated and the client will get an e-mail notification.',
    //         inputLabel: 'Client Ticket Details'
    //     },
    //     icon: 'mdi-account-circle-outline'
    // },
    // {
    //     name: 'comments',
    //     text: 'Comments',
    //     modal: {
    //         name: 'ModalTextarea',
    //         title: 'Comments',
    //         description: 'This tool is used to post comments to the communication log for the writer(s) to see. The function also creates a writer ticket and posts the same message there for further communication with the writer currently assigned.',
    //         inputLabel: 'Client Comments'
    //     },
    //     icon: 'mdi-comment-text-outline'
    // },
    // {
    //     name: 'message',
    //     text: 'Message',
    //     modal: {
    //         name: 'ModalTextarea',
    //         title: 'Contact Writer',
    //         description: 'This tool is used to contact the writer. A support ticket will be generated and the writer will get an e-mail notification.',
    //         inputLabel: 'Details'
    //     },
    //     icon: 'mdi-comment-plus-outline'
    // },
    // {
    //     name: 'Fee',
    //     text: 'fee',
    //     modal: {
    //         name: 'ModalFee'
    //     },
    //     icon: 'mdi-cash'
    // },
    // {
    //     name: 'Pay',
    //     text: 'pay',
    //     modal: {
    //         name: 'ModalAlert',
    //         title: 'Payment batch',
    //         description: 'Prepare a payment batch?'
    //     },
    //     icon: 'mdi-credit-card-outline'
    // },
    // {
    //     name: 'dots',
    //     icon: 'mdi-dots-horizontal',
    //     subMenu: [
    //         {
    //             name: 'feedback',
    //             text: 'Feedback',
    //             modal: {
    //                 name: 'ModalAlert',
    //                 title: 'Request feedback',
    //                 description: 'Send feedback request to client'
    //             },
    //             icon: 'mdi-comment-check-outline'
    //         },
    //         {
    //             name: 'password',
    //             text: 'Password',
    //             modal: {
    //                 name: 'ModalAlert',
    //                 title: 'Password',
    //                 description: 'Resend password'
    //             },
    //             icon: 'mdi-lock-outline'
    //         },
    //         {
    //             name: 'fraud',
    //             text: 'Fraud',
    //             modal: {
    //                 name: 'ModalAlert',
    //                 title: 'Flag order as fradulent',
    //                 description: 'Mark the order as fraudulent and contact the buyer. The function will create a client ticket, a writer ticket and a forum ticket'
    //             },
    //             icon: 'mdi-alert-octagon-outline'
    //         },
    //         {
    //             name: 'divider',
    //             id: 2
    //         },
    //         {
    //             name: 'revision',
    //             text: 'Revision',
    //             modal: {
    //                 name: 'ModalTextarea',
    //                 title: 'Revision request',
    //                 inputLabel: 'Revision Request Details'
    //             },
    //             icon: 'mdi-calendar-edit'
    //         },
    //         {
    //             name: 'deadline',
    //             text: 'Deadline',
    //             modal: {
    //                 name: 'ModalDeadline'
    //             },
    //             icon: 'mdi-calendar-alert'
    //         },
    //         {
    //             name: 'refund',
    //             text: 'Refund',
    //             modal: {
    //                 name: 'ModalTextarea',
    //                 title: 'To refund',
    //                 inputLabel: 'Reason (include Ticket ID or CHAT reference)'
    //             },
    //             icon: 'mdi-cancel'
    //         },
    //         {
    //             name: 'divider',
    //             id: 1
    //         },
    //         {
    //             name: 'note',
    //             text: 'Note',
    //             modal: {
    //                 name: 'ModalTextarea',
    //                 title: 'Editor’s Note',
    //                 description: 'This tool is to post administrative notes (editor\'s reviews/comments, etc.)',
    //                 inputLabel: 'Editor\'s/Operator\'s Comments'
    //             },
    //             icon: 'mdi-note-outline'
    //         },
    //         {
    //             name: 'transfer',
    //             text: 'Transfer',
    //             modal: {
    //                 name: 'ModalTransfer'
    //             },
    //             icon: 'mdi-subdirectory-arrow-right'
    //         },
    //         {
    //             name: 'upload',
    //             text: 'Upload',
    //             modal: {
    //                 name: 'ModalUpload'
    //             },
    //             icon: 'mdi-upload-outline'
    //         }
    //     ]
    // }
]
