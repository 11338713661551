import Api from '@/helpers/api/index.js'

export const getCommunicationLogs = async ({ orderid }, cache) => {
    const request = {
        orderid
    }
    const { data } = await Api.get('/api/order-message/list', request, {}, cache)
    return data
}

export const createCommunicationLog = async (payload) => {
    const response = await Api.post('/api/order-message/create-message', { ...payload })
    return response
}

export const getOrderFeedbacks = async (orderid) => {
    const { data } = await Api.get('/api/testimonial/list-by-params', { orderid }, { dialog: true }, false)
    return data
}

export const editOrderFeedback = async ({ payload, id }) => {
    const { data } = await Api.put('/api/testimonial/update', { id, data: payload })
    return data
}

export const setOrderFeedbackStatus = async ({ payload, id }) => {
    const { data } = await Api.put('/api/testimonials/set-status', { id, status: payload.status })
    return data
}

export const getOrderAdditionalFiles = async (payload) => {
    const { data } = await Api.get('/api/file/additional/list-files', payload)
    return data
}

export const deleteOrderAdditionalFiles = async (payload) => {
    const { data } = await Api.delete('/api/file/additional/delete', payload)
    return data
}

export const getOrderFiles = async (payload) => {
    const { data } = await Api.get('/api/file/main/list-files', payload)
    return data
}

export const deleteOrderMainFiles = async (payload) => {
    const { data } = await Api.delete('/api/file/main/delete', payload)
    return data
}

export const uploadOrderAdditionalFiles = async (payload, callback) => {
    const { data } = await Api.post('/api/file/additional/upload', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: callback
    })
    return data
}

export const uploadOrderMainFiles = async (payload, callback) => {
    const { data } = await Api.post('/api/file/main/upload', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: callback
    })
    return data
}
