<template>
    <modal
        :loading="loading"
        :show-modal="showModal"
        :is-active-confirm="!error"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <p>
                {{ description }}
            </p>
            <v-text-field
                v-model="new_limit"
                outlined
                :label="inputLabel"
                :rules="rules(new_limit)"
                type="number"
                ladel=""
                @update:error="onError"
            />
            <v-select
                v-model="status"
                :items="statusItems"
                color="accent"
                outlined
                hide-details
                label="Status"
            />
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        inputLabel: {
            type: String,
            required: false,
            default: ''
        },
        writerLimit: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            showModal: true,
            new_limit: 0,
            error: false,
            status: ''
        }
    },
    computed: {
        statusItems() {
            return ['NEW', 'TRUSTED']
        }
    },
    created() {
        if (this.writerLimit) {
            this.new_limit = this.writerLimit
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                projects_limit: this.new_limit,
                trust_status: this.status
            })
        },
        rules() {
            return [
                (v) => !!v || 'This field is required',
                (v) => /^[-{1}\d]+$/.test(v) || 'This field only accept integer',
                (v) => v >= 0 || 'This field must be greater than zero'
            ]
        },
        onError(payload) {
            if (payload) {
                this.error = true
                return
            }
            this.error = false
        }
    }
}
</script>

<style>

</style>
