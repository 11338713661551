import Api from '@/helpers/api/index.js'

export const uploadWriterFiles = async (payload, callback) => {
    const { data } = await Api.post('/api/file/applicant/upload', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: callback
    })
    return data
}

export const getWriterFiles = async (payload) => {
    const { data } = await Api.get('/api/file/applicant/list-files', payload)
    return data
}

export const getFileTypes = async () => {
    const { data } = await Api.get('/api/file/applicant/list-types')
    const fileTypes = data.map((element) => ({
        text: element.description,
        value: element.id
    }))
    return fileTypes
}
