import { render, staticRenderFns } from "./GeneralExtras.vue?vue&type=template&id=a7151a84&"
import script from "./GeneralExtras.vue?vue&type=script&lang=js&"
export * from "./GeneralExtras.vue?vue&type=script&lang=js&"
import style0 from "./GeneralExtras.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAvatar,VExpandTransition,VIcon})
