<template>
    <v-container
        fluid
    >
        <v-row class="pa-0 mb-0">
            <v-col cols="4">
                <v-select
                    v-model="type"
                    :items="fileTypesInSelect"
                    :error="!isSelectedType"
                    label="Type"
                    outlined
                    hide-details
                    first-select
                />
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-0">
            <v-col cols="12">
                <div class="">
                    <FileInput
                        ref="inputComponent"
                        :upload-progress="uploadProgress"
                        :allowed-types="allowedFileTypes"
                        :start-upload="startUpload"
                        @uploadFiles="onUploadFiles"
                    />
                </div>
            </v-col>
        </v-row>

        <v-row class="pa-0">
            <v-col cols="12">
                <the-files-table
                    :files="files"
                    :headers="tableHeaders"
                    @deleteFile="alertDelete"
                />
            </v-col>
        </v-row>

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import TheFilesTable from '@/components/shared/Tables/TheFilesTable'
import FileInput from '@/components/shared/FileInput'

import { WRITERS_FILES_TABLE_HEAD, ALLOWED_FILE_TYPES } from '@/constants/Writers'
import { ACTION_DELETE_WRITER_FILE } from '@/store/modules/writers/action-types'
import { uploadWriterFiles, getWriterFiles, getFileTypes } from '@/services/writerService'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('writers');

export default {
    name: 'WritersFiles',
    components: {
        TheFilesTable,
        ModalAlert,
        FileInput
    },
    data() {
        return {
            files: [],
            modal: {},
            file_id: '',
            sort_options: {
                sort_by: 'created_at',
                sort_order: 'desc'
            },
            uploadProgress: 0,
            startUpload: false,
            isSelectedType: true,
            fileTypesInSelect: [],
            type: 1
        }
    },
    computed: {
        tableHeaders() {
            return WRITERS_FILES_TABLE_HEAD
        },
        modalComponent() {
            return this.modal.name || ''
        },
        allowedFileTypes() {
            return ALLOWED_FILE_TYPES
        }
    },
    async activated() {
        await Promise.all([
            this.getFiles(),
            this.getFilesTypes()
        ])
    },
    methods: {
        ...mapAdminActions({
            ACTION_DELETE_WRITER_FILE
        }),
        onCloseModal() {
            this.modal = {}
            this.file_id = ''
        },
        alertDelete(id) {
            this.modal = {
                name: 'ModalAlert',
                title: 'Delete file',
                description: 'Do you want to delete this file?'
            }
            this.file_id = id
        },
        onConfirmModal() {
            this.onDeleteFile(this.file_id)
        },
        async getFiles() {
            const { id } = this.$route.params
            this.files = await getWriterFiles({ id, ...this.sort_options }, {}, { cache: false })
        },
        async getFilesTypes() {
            const data = await getFileTypes()
            this.fileTypesInSelect = data
        },
        async onDeleteFile(payload) {
            try {
                await this[ACTION_DELETE_WRITER_FILE]({ id: payload })
                this.files = this.files.filter((it) => it.file_id !== payload)
                this.$emit('update:counters', { type: 'files', counter: -1 })
                eventBus.$emit('showSnackBar', 'File deleted', 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        validate() {
            if (this.fileTypes) {
                if (this.type.length) {
                    this.isSelectedType = true
                    return true
                }
                this.isSelectedType = false
                return false
            }
            return true
        },
        async onUploadFiles(payload) {
            if (this.validate()) {
                try {
                    this.startUpload = true
                    let resp = null
                    const formData = new FormData()
                    formData.append('uploaded[]', payload, payload.name)
                    formData.append('type', this.type)
                    formData.append('writer_id', this.$route.params.id)
                    resp = await uploadWriterFiles(formData, (e) => { this.uploadProgress = e.loaded })
                    if (resp.file) {
                        this.files.unshift(resp.file[0])
                        return
                    }
                    this.$emit('update:counters', { type: 'files', counter: 1 })
                    this.files.unshift(resp[0])
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                } finally {
                    this.startUpload = false
                }
            }
        }
    }
}
</script>

<style>

</style>
