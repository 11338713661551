var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-body-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])]}}])},[_c('span',[_vm._v("Pages")])]),_vm._v(" "+_vm._s(_vm.getLabel("page", _vm.item.pages))+" ")],1)]),(_vm.item.slides)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-outline ")])]}}],null,false,1260113144)},[_c('span',[_vm._v("Slides")])]),_vm._v(" "+_vm._s(_vm.getLabel("slide", _vm.item.slides))+" ")],1)]):_vm._e(),(_vm.item.charts)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-chart-box-outline ")])]}}],null,false,1047328523)},[_c('span',[_vm._v("Charts")])]),_vm._v(" "+_vm._s(_vm.getLabel("chart", _vm.item.charts))+" ")],1)]):_vm._e(),(_vm.item.problems)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-chart-box-outline ")])]}}],null,false,1047328523)},[_c('span',[_vm._v("Problems")])]),_vm._v(" "+_vm._s(_vm.getLabel("problem", _vm.item.problems))+" ")],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }