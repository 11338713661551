<template>
    <div>
        <p class="text-body-2 mb-0">
            {{ $date.relativeTimeFromNow(data.last_ts) }}
            <br>
            {{ $date.humanizeDate(data.last_ts) }}
        </p>
        <div class="d-flex align-baseline grey--text text-body-2">
            {{ data.last_ip }}
            <span :class="'ml-2 flag-icon flag-input-icon flag-icon-' + data.country.toLowerCase()" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
</style>
