export const FILES_TABLE_HEAD = [
    {
        text: 'Name',
        value: 'filename',
        sortable: true
    },
    {
        text: 'Owner',
        value: 'owner_type',
        sortable: true
    },
    {
        text: 'Date',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Size',
        value: 'filesize',
        sortable: true
    },
    {
        text: 'Actions',
        value: 'actions',
        sortable: false
    }
]

export const ALLOWED_TYPES = [
    'application/pdf',
    'application/msword',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.rtf',
    '.txt',
    '.csv',
    '.ppt',
    '.pptx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'text/csv',
    'text/plain',
    'application/rtf'
]
