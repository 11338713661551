<template>
    <div
        class="fb-card"
    >
        <div class="d-flex align-start">
            <new-feedback-card
                v-if="feedback.parsed_testimonial_new"
                :feedback="feedback"
            />
            <old-feedback-card
                v-if="feedback.parsed_testimonial_old"
                :feedback="feedback"
            />
            <div
                v-if="withActions"
                class="text-center"
            >
                <v-menu
                    class="z-99"
                    bottom
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            class="font-weight-bold"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list
                        dark
                        dense
                    >
                        <v-list-item-group
                            color="primary"
                            active-class=""
                        >
                            <v-list-item
                                v-for="toolbar in toolbars"
                                :key="toolbar.name"
                                @click="actionRow(feedback, {modal: toolbar.modal, actionName: toolbar.name})"
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="toolbar.icon" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="toolbar.text" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <v-divider
            class="mt-10"
        />
        <component
            :is="modalComponent"
            :feedback="currentFeedback"
            :title="modal.title"
            :feedback-statuses="statuses"
            :current-status="feedback.status"
            :description="modal.description"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>
<script>
import { FEEDBACKS_TOOLBAR, STATUSES } from '@/constants/Feedbacks'

import ModalFeedbackEdit from '@/components/shared/Modals/ModalFeedbackEdit'
import ModalFeedbackCreateTicket from '@/components/shared/Modals/ModalFeedbackCreateTicket'
import ModalFeedbackStatus from '@/components/shared/Modals/ModalFeedbackStatus'
import MarksChip from '@/components/Feedback/FeedbackDetails/MarksChip.vue'
import NewFeedbackCard from '@/components/Feedback/FeedbackCardTemplates/NewFeedbackCard'
import OldFeedbackCard from '@/components/Feedback/FeedbackCardTemplates/OldFeedbackCard'

export default {
    components: {
        ModalFeedbackEdit,
        ModalFeedbackStatus,
        ModalFeedbackCreateTicket,
        MarksChip,
        NewFeedbackCard,
        OldFeedbackCard
    },
    props: {
        feedback: {
            type: Object,
            required: true
        },
        withActions: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            modal: {},
            currentFeedback: {},
            actionName: ''
        }
    },
    computed: {
        toolbars() {
            return FEEDBACKS_TOOLBAR
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        statuses() {
            return STATUSES
        },
        statusColor() {
            switch (this.feedback.status) {
            case 'OPEN': {
                return {
                    text: 'primary',
                    color: '#1976D21A'
                }
            }
            case 'ACTIVE': {
                return {
                    text: 'success',
                    color: 'rgba(39, 217, 31, 0.1)'
                }
            }
            case 'CLOSED': {
                return {
                    text: 'error',
                    color: '#FF00001A'
                }
            }
            default: {
                return {}
            }
            }
        }
    },
    methods: {
        actionRow(feedback, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentFeedback = feedback
        },
        onCloseModal() {
            this.modal = {}
            this.currentFeedback = {}
            this.actionName = ''
        },
        onConfirmModal(payload) {
            this.$emit('confirmModal', {
                actionName: this.actionName, modal: this.modal, payload, id: this.actionName === 'set_status' ? [this.currentFeedback.id] : this.currentFeedback.id
            })
        },
        rateBgColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'rgba(240, 125, 0, 0.1)'
            } if (rate >= 9) {
                return 'rgba(39, 217, 31, 0.1)'
            }
            return 'rgba(255, 0, 0, 0.1)'
        },
        rateFontColor(rate) {
            if (rate >= 5 && rate < 9) {
                return 'accent--text'
            } if (rate >= 9) {
                return 'success--text'
            }
            return 'error--text'
        }
    }

}
</script>
<style lang="scss">
.fb-review{
    background: #F4F8FD;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
}
.fb-card+.fb-card{
    margin-top: 40px;
}
.truncate-text{
    display: inline-block;
    width:max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fb-row{
    display: flex;
    // margin: 10px 0;
    padding: 10px;
    background: #FAFAFA;
    @media all and (max-width: 1700px) {
        flex-wrap: wrap;
    }
    &_col{
        min-width: 150px;
        // margin: 0 10px;
        &--auto {
            width: 50% !important;
            max-width: 50% !important;
        }
    }
}
</style>
