<template>
    <v-container
        fluid
        class="white"
    >
        <v-row class="pt-0 mt-0">
            <v-col
                cols="12"
                class="pt-0 mt-0"
            >
                <feedback-table
                    :search-options="searchOptions"
                    @menuActivated="onMenuActivated"
                    @updateOptions="updateOptions"
                />
                <!-- <template
                    v-for="(feedback, index) in getterFeedbacksList"
                >
                    <feedback-card
                        :ref="`card-${feedback.id}`"
                        :key="index"
                        :feedback="feedback"
                        @confirmModal="onConfirmModal"
                    />
                </template> -->
                <pagination-with-perpage
                    :list-lenght="getterFeedbacksList.length"
                    :last-page="getterLastPage"
                    :search-options="searchOptions"
                    :total-visible="7"
                    @updatePage="paginationPageEvent"
                    @updatePerpage="onUpdatePerpage"
                />
                <!-- <toolbar-menu
                    v-if="selected.length"
                    :toolbars="multiToolbars"
                    @toolbarActivated="onToolbarActivated"
                /> -->

                <component
                    :is="modalComponent"
                    :feedback="currentFeedback"
                    :title="modal.title"
                    :feedback-statuses="statuses"
                    :current-status="currentFeedback.status"
                    :description="modal.description"
                    @closeModal="onCloseModal"
                    @confirmModal="onConfirmModal"
                />
                <div
                    v-if="!getterFeedbacksList.length"
                    class="d-flex flex-column align-center w-full mt-5 mb-5"
                >
                    <div class="">
                        <img
                            src="@images/svg/no-data.svg"
                            alt=""
                        >
                    </div>
                    <p class="text-h6">
                        No data found
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_CREATE_TICKET_FEEDBACK,
    ACTION_EDIT_FEEDBACK,
    ACTION_GET_FEEDBACKS_LIST,
    ACTION_GET_FEEDBACKS_TOTALS,
    ACTION_SET_STATUS_FEEDBACK
} from '@/store/modules/feedbacks/action-types';
import {
    FEEDBACKS_MULTI_TOOLBAR, FEEDBACKS_TABLE_HEADER, FEEDBACKS_TABLE_HEADER_TOTAL, FEEDBACKS_TOOLBAR, STATUSES
} from '@/constants/Feedbacks'
// import FeedbackCard from '@/components/shared/Cards/FeedbackCard';
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage'
// import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'
import FeedbackTable from '@/components/Feedback/FeedbackTable/FeedbackTable';
import ModalFeedbackEdit from '@/components/shared/Modals/ModalFeedbackEdit'
import ModalFeedbackCreateTicket from '@/components/shared/Modals/ModalFeedbackCreateTicket'
import ModalFeedbackStatus from '@/components/shared/Modals/ModalFeedbackStatus'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('feedbacks');

export default {
    components: {
        // FeedbackCard,
        PaginationWithPerpage,
        FeedbackTable,
        // ToolbarMenu,
        ModalFeedbackEdit,
        ModalFeedbackCreateTicket,
        ModalFeedbackStatus
    },
    data() {
        return {
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: 'sw_id',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            modal: {},
            openedRow: [],
            options: {},
            currentFeedback: {},
            isFirstLoad: true
        }
    },
    computed: {
        ...mapGetters('feedbacks', [
            'getterFeedbacksList',
            'getterLastPage',
            'getterTotal',
            'getterPresets',
            'getterTotalsLoading',
            'getterListLoading'
        ]),
        tableHeader() {
            return FEEDBACKS_TABLE_HEADER
        },
        tableHeaderTotal() {
            return FEEDBACKS_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return FEEDBACKS_TABLE_HEADER.length + 1
        },
        itemToolbars() {
            return FEEDBACKS_TOOLBAR
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        statuses() {
            return STATUSES
        },
        multiToolbars() {
            return FEEDBACKS_MULTI_TOOLBAR
        }
    },
    async activated() {
        await this.getFeedbacks()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_FEEDBACKS_LIST,
            ACTION_EDIT_FEEDBACK,
            ACTION_CREATE_TICKET_FEEDBACK,
            ACTION_SET_STATUS_FEEDBACK,
            ACTION_GET_FEEDBACKS_TOTALS
        }),
        onMenuActivated(feedback, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentFeedback = feedback
        },
        async paginationPageEvent(payload) {
            this.searchOptions.page = payload
            await this.getFeedbacks()
        },
        async onUpdatePerpage(payload) {
            this.searchOptions.page = payload.page
            this.searchOptions.per_page = payload.per_page
            await this.getFeedbacks()
        },
        rowClasses(item) {
            return {
                'table-row--opened': this.openedRow.includes(item.testimonialid),
                // 'table-row--selected': this.selected.some((select) => select?.testimonialid === item.testimonialid),
                'table-row--selected-once': this.currentFeedback?.testimonialid === item.testimonialid
            }
        },
        onCloseModal() {
            this.modal = {}
            this.currentFeedback = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'edit_feedback':
                    await this.editFeedback({ payload, id: this.currentFeedback.testimonialid })
                    break;
                case 'set_status':
                    // await this.setStatus({ payload, id: this.getSelectedFeedbackID() })
                    break;
                case 'contact_buyer':
                    await this.contactBuyer(payload)
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async editFeedback({ payload, id }) {
            await this[ACTION_EDIT_FEEDBACK]({ payload, id })
        },
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        // getSelectedFeedbackID() {
        //     if (this.selected.length && !this.currentFeedback.testimonialid) {
        //         return this.selected.map((element) => element.testimonialid)
        //     }
        //     return [this.currentFeedback.testimonialid]
        // },
        async getFeedbacks() {
            try {
                const { id } = this.$route.params
                await Promise.all([
                    this[ACTION_GET_FEEDBACKS_LIST]({
                        sw_id: id, ...this.searchOptions, mode: 'list'
                    }),
                    this[ACTION_GET_FEEDBACKS_TOTALS]({
                        sw_id: id, ...this.searchOptions, mode: 'count'
                    })
                ])
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        async updateOptions(e) {
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort
                }
                await this.getFeedbacks()
            }
        },
        async setStatus({ payload, id }) {
            await this[ACTION_SET_STATUS_FEEDBACK]({ payload, id })
        },
        async contactBuyer(payload) {
            await this[ACTION_CREATE_TICKET_FEEDBACK](payload)
        }
    }
}
</script>
