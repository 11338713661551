<template>
    <v-container fluid>
        <div class="py-5">
            <v-card
                elevation="2"
                class="px-0 py-0 mt-10 d-flex"
                style="overflow-x: auto;"
            >
                <!-- Dates -->
                <div class="extras-row flex-column">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-calendar-week
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Dates
                            </span>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Applied:
                            </p>
                            <p
                                v-if="writer.created_at"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                {{ $date.dateFromNow(writer.created_at) }}
                                <information-tooltip>
                                    <div class="mb-1">
                                        <p class="mb-0 text-body-6">
                                            Last session:
                                        </p>
                                        <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                            {{ $date.humanizeDate(writer.created_at) }}
                                        </p>
                                    </div>
                                </information-tooltip>
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Last login:
                            </p>
                            <p
                                v-if="writer.last_session_at"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                {{ $date.dateFromNow(writer.last_session_at) }}
                                <information-tooltip>
                                    <div class="mb-1">
                                        <p class="mb-0 text-body-6">
                                            Last session:
                                        </p>
                                        <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                            {{ $date.humanizeDate(writer.last_session_at) }}
                                        </p>
                                    </div>
                                </information-tooltip>
                            </p>
                            <p v-else>
                                -
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Dates -->
                <!-- Orders -->
                <div
                    v-if="getOrderCount"
                    class="extras-row flex-column"
                >
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-calendar-week
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Orders:
                            </span>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Total:
                            </p>
                            <p
                                v-if="getOrderCount.orders_total !==0"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                <router-link
                                    :to="{
                                        name: 'orders',
                                        query: {
                                            sw_status: '',
                                            writer_id: writer.sw_id,
                                        }
                                    }"
                                >
                                    <v-avatar
                                        :color="statusBgColor('all')"
                                        size="25"
                                    >
                                        <span
                                            class="font-weight-regular text-body-2"
                                            :style="`color: ${statusTextColor('all')}`"
                                        >
                                            {{ getOrderCount.orders_total }}
                                        </span>
                                    </v-avatar>
                                </router-link>
                            </p>
                            <p
                                v-else
                                class="mb-0 d-flex align-center"
                            >
                                —
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                In progress:
                            </p>
                            <p
                                v-if="getOrderCount.orders_pending !==0"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                <router-link
                                    :to="{
                                        name: 'orders',
                                        query: {
                                            sw_status: 'PENDING',
                                            writer_id: writer.sw_id,
                                        }
                                    }"
                                >
                                    <v-avatar
                                        :color="statusBgColor('pending')"
                                        size="25"
                                    >
                                        <span
                                            class="font-weight-regular text-body-2"
                                            :style="`color: ${statusTextColor('pending')}`"
                                        >
                                            {{ getOrderCount.orders_pending }}
                                        </span>
                                    </v-avatar>
                                </router-link>
                            </p>
                            <p
                                v-else
                                class="mb-0 d-flex align-center"
                            >
                                —
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Completed:
                            </p>
                            <p
                                v-if="getOrderCount.orders_completed !== 0"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                <router-link
                                    :to="{
                                        name: 'orders',
                                        query: {
                                            sw_status: 'COMPLETE',
                                            writer_id: writer.sw_id,
                                        }
                                    }"
                                >
                                    <v-avatar
                                        :color="statusBgColor('complete')"
                                        size="25"
                                    >
                                        <span
                                            class="font-weight-regular text-body-2"
                                            :style="`color: ${statusTextColor('complete')}`"
                                        >
                                            {{ getOrderCount.orders_completed }}
                                        </span>
                                    </v-avatar>
                                </router-link>
                            </p>
                            <p
                                v-else
                                class="mb-0 d-flex align-center"
                            >
                                —
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                In revision:
                            </p>
                            <p
                                v-if="getOrderCount.orders_revision !==0"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                <router-link
                                    :to="{
                                        name: 'orders',
                                        query: {
                                            sw_status: 'REVISION',
                                            writer_id: writer.sw_id,
                                        }
                                    }"
                                >
                                    <v-avatar
                                        :color="statusBgColor('REVISION')"
                                        size="25"
                                    >
                                        <span
                                            class="font-weight-regular text-body-2"
                                            :style="`color: ${statusTextColor('REVISION')}`"
                                        >
                                            {{ getOrderCount.orders_revision }}
                                        </span>
                                    </v-avatar>
                                </router-link>
                            </p>
                            <p
                                v-else
                                class="mb-0 d-flex align-center"
                            >
                                —
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Refund:
                            </p>
                            <p
                                v-if="getOrderCount.orders_refunded !==0"
                                class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"
                            >
                                <router-link
                                    :to="{
                                        name: 'orders',
                                        query: {
                                            sw_status: 'REFUND',
                                            writer_id: writer.sw_id,
                                        }
                                    }"
                                >
                                    <v-avatar
                                        :color="statusBgColor('TO_REFUND')"
                                        size="25"
                                    >
                                        <span
                                            class="font-weight-regular text-body-2"
                                            :style="`color: ${statusTextColor('TO_REFUND')}`"
                                        >
                                            {{ getOrderCount.orders_refunded }}
                                        </span>
                                    </v-avatar>
                                </router-link>
                            </p>
                            <p
                                v-else
                                class="mb-0 d-flex align-center"
                            >
                                —
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Orders -->
                <!-- Writer info -->
                <div class="extras-row flex-column">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-account-tie
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                Writer info
                            </span>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Writer ID:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                <copy-to-click-board
                                    :copy-string="String(writer.id) || ''"
                                />
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="writer.phone"
                        class="extras-row_col"
                    >
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Phone:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                (+{{ writer.phone_code }}) {{ writer.phone }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Email:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                {{ writer.email || '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Country:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                {{ writer.country_nice_name }}
                                <span
                                    v-if="writer.country"
                                    :class="'ml-1 flag-icon flag-input-icon flag-icon-' + writer.country.toLowerCase()"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                City:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                {{ writer.city || '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Address:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                {{ writer.address || '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div>
                            <p class="grey--text mb-0 text-body-2">
                                Zip:
                            </p>
                            <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                {{ writer.zip || '-' }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Writer info -->
                <!-- Financial Details: -->
                <div class="extras-row flex-column">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-credit-card-check
                            </v-icon>
                            <span
                                class="font-weight-bold text-uppercase text-subtitle-2"
                                style="white-space: nowrap"
                            >
                                Financial Details
                            </span>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div class="extras-row_col py-3">
                            <template
                                v-if="writer.paymethods.length"
                            >
                                <v-row
                                    v-for="(item) in writer.paymethods"
                                    :key="item.updated_at"
                                >
                                    <div
                                        cols="2"
                                        class="subtitle-2"
                                    >
                                        {{ item.method_description }} | {{ item.method }}:
                                    </div>
                                    <div class="my-4">
                                        <v-row
                                            v-for="(detail, index) in getPayMethodDetails(item.data)"
                                            :key="index"
                                        >
                                            <v-col
                                                :key="index"
                                                cols="12"
                                                class="text-capitalize"
                                                style="padding: 6px 12px"
                                            >
                                                {{ detail }}
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-row>
                            </template>
                            <span v-else> No connected </span>
                        </div>
                    </div>
                </div>
                <!-- Financial Details: -->
                <!-- General -->
                <div class="extras-row flex-column">
                    <!-- row title -->
                    <div class="d-flex align-center extras-row_col extras-row_col-title">
                        <div class="d-flex align-center">
                            <v-icon class="mr-4">
                                mdi-information-outline
                            </v-icon>
                            <span class="font-weight-bold text-uppercase text-subtitle-2">
                                General
                            </span>
                        </div>
                    </div>
                    <div class="extras-row_col">
                        <div style="height: 500px; overflow-y: scroll;">
                            <template v-if="writer.application && writer.application.length > 0">
                                <div
                                    v-for="item in writer.application"
                                    :key="item.key"
                                >
                                    <v-col
                                        cols="2"
                                        class="subtitle-2"
                                    >
                                        {{ item.title }}:
                                    </v-col>
                                    <v-col cols="10">
                                        {{ item.value || '-' }}
                                    </v-col>
                                </div>
                            </template>
                            <span v-else>Not Filled</span>
                        </div>
                    </div>
                </div>
                <!-- General -->
            </v-card>
        </div>
        <prefered-blocked-viewer
            v-if="showWriters"
            :items="getterWritersShortList"
            :title="writersTitle"
            @closeWriters="closeWriters"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import PreferedBlockedViewer from '@/components/SidePanels/PreferedBlockedViewer';
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'
import { statusBgColor, statusTextColor } from '@/helpers/order/statusHelper'
import { getPayMethodDetails } from '@/helpers/payMethodsParses'

import {
    ACTION_GET_WRITER_SHORT_LIST
} from '@/store/modules/writers/action-types'
import InformationTooltip from '@/components/shared/InformationTooltip.vue';

const {
    mapActions: mapWritersActions
} = createNamespacedHelpers('writers');
export default {
    components: {
        InformationTooltip,
        PreferedBlockedViewer,
        CopyToClickBoard
    },
    props: {
        writer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showWriters: false,
            writersTitle: ''
        }
    },
    computed: {
        ...mapGetters('writers', [
            'getterWritersShortList'
        ]),
        getOrderCount() {
            return this.writer.statistics
        }
    },
    methods: {
        ...mapWritersActions({
            ACTION_GET_WRITER_SHORT_LIST
        }),
        statusBgColor,
        statusTextColor,
        getPayMethodDetails,
        openSidebar(type) {
            let writers = []
            if (type === 'preferred') {
                writers = this.info.preferred_writers
                this.writersTitle = 'Preffered writers'
            }
            if (type === 'blocked') {
                writers = this.info.blocked_writers
                this.writersTitle = 'Blocked writers'
            }
            if (writers.length === 0) {
                return
            }
            this.showWriters = true
            this[ACTION_GET_WRITER_SHORT_LIST]({ writers })
        },
        closeWriters() {
            this.showWriters = false
        }
    }
}
</script>

<style>

</style>
